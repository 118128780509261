const zhHantDictionary = {
 "commons": {},
 "customer_report": {
  "customer": "客戶名稱",
  "customer_report_date_range": "本報表可查詢%{days} 天內資料(e.g. 2022/07/01-2022/07/30),並查詢起始日期應小於等於終止日期",
  "report": "報表列表",
  "report_exclusive": "報表選項",
  "country": "進口國家選項",
  "tooltip_import_date": "進口日期",
  "option_label_import_date": "進口日期",
  "error_exceed_row_limit": "Over %{row_limit} Records, please input any milestone to limit the data scope.",
  "error_invalid_filter": "You have specified filters that this report is not supported. Please try to remove them."
 },
 "utilities_mgmt": {
  "long_polling_status_success": "成功!",
  "long_polling_status_failed": "獲取資源失敗，請稍後再試一次",
  "long_polling_status_running": "獲取資源中，請稍候...",
  "no_match_data": "沒有資料",
  "long_live_task_status_success": "成功!",
  "long_live_task_status_failed": "獲取資源失敗，請稍後再試一次",
  "long_live_task_status_running": "獲取資源中，請稍候...",
  "long_live_task_upload_process_result_title": "Upload Process Result",
  "long_live_task_upload_process_result_total": "Total",
  "long_live_task_upload_process_result_complete": "Complete",
  "long_live_task_upload_process_result_in_complete": "In-complete",
  "long_live_task_upload_process_result_error": "error",
  "undefined": ""
 },
 "cfs": {
  "acting_station": "操作印表機站點",
  "actual_pickup": "實際提貨日",
  "actual_pickup_at": "實際提貨時間",
  "add_one_row": "新增一行",
  "add_route": "新增航段",
  "additional_scan": "要在物件上掃描什麼",
  "adjust_column": "Adjust Column",
  "agent": "出貨代理",
  "airline_name": "航空公司名稱",
  "alert_confirm_leave": "更新的資訊將不會被儲存，你確定要離開？",
  "alert_save_confirm": "你確定要儲存？",
  "alert_save_success": "Successfully updated.",
  "already_checked": "此物件已經檢查完畢！",
  "back": "返回",
  "before_a_week": "過去1週之前",
  "before_today": "今天以前",
  "booking_at": "HAWB建立時間",
  "browse": "預覽",
  "call_printer": "呼叫印表機",
  "call_printer_success": "成功呼叫印表機",
  "Can not export over 300 row": "Can not export over 300 row",
  "can_not_export_over_x_row": "無法輸出%{number}以上行數",
  "Cannot Export": "無法輸出資料",
  "cannot_be_blank": "不可空白",
  "cannot_export": "無法輸出",
  "caution": "注意!",
  "check_failed_hint": "比對失敗！請重新掃描！",
  "check_ok_hint": "比對成功！請掃描下一個！",
  "check_result": "檢查結果",
  "choose_input_mode": "選擇輸入方式",
  "column_adjustment": "欄位調整",
  "column_with_error": "發生錯誤的欄位",
  "compress_img_before_upload": "上傳前壓縮相片",
  "consignee_name": "收貨方",
  "create_date": "生成日期",
  "csv_upload": "CSV檔上傳",
  "customer_name": "客戶名稱",
  "data_in": "EDI Info",
  "data_input": "資料輸入",
  "data_uploaded_failed": "注意！档案资料中发现错误，请修正资料并重新上传",
  "data_uploaded_successfully": "資料上傳成功 ",
  "delete_confirm": "你確定要刪除？",
  "delete_success": "刪除成功",
  "delivery_package_number": "Pallet ID",
  "details": "細節",
  "digits": "位數",
  "dn": "DN",
  "download_template": "模板下載",
  "download_the_csv_template": "下載CSV模板",
  "edi_recieve_at": "EDI Receive Time",
  "equals": "完全相等",
  "error_message_is_already_exist": "%{column} 已存在",
  "estimated_pickup": "Estimated Pickup Date",
  "estimated_pickup_at": "預計提貨時間",
  "eta": "ETA",
  "etd": "ETD",
  "export": "匯出",
  "extra_route": "額外航段",
  "failed_check": "檢核失敗",
  "fast_label_check_finish": "結束",
  "fast_label_check_hold_scan": "請繼續掃碼",
  "fast_label_check_wrong_label": "這票貨掃碼尚未結束！",
  "fill_in_the_house_airway_bill_no": "填入HAWB號碼",
  "fill_in_the_master_airway_bill_no": "填入MAWB號碼",
  "fill_in_the_pieces_of_hawb": "填入HAWB的件數",
  "fill_in_the_pieces_of_mawb": "填入MAWB的總件數",
  "fill_in_the_short_message_for_note": "填入要額外注意的資訊",
  "fill_in_the_station_code": "填入站點代碼，例如TPE、HKG、PVG等",
  "finish_time": "最後掃碼時間",
  "flight_number": "航班",
  "generate_pdf": "生成PDF",
  "group_box": "Group Box",
  "group_id": "Group ID",
  "hawb": "HAWB",
  "hawb_create_date": "HAWB資料建立日期",
  "hawb_dest": "HAWB進口站",
  "hawb_details": "HAWB細節",
  "hawb_done_by_fast_checking": "貨品為多箱盒類型的HAWB",
  "hawb_not_completed": "尚未確認完成的HAWB",
  "hawb_orig": "HAWB出口站",
  "hawb_pallet_count_loose_carton_hint": "通常HAWB件數會等於棧板數加上散箱數。請再確認，若有必要則修改HAWB件數與包裝資訊",
  "hawb_pcs": "HAWB件數",
  "hawb_gw": "HAWB GW",
  "hawb_validation_error_message": "HAWB格式錯誤",
  "hold_on_scan_ref_no_on_package": "請掃描物件上的 %{ref_no_name}！",
  "how_to_fill_in_the_template": "如何填寫模板",
  "include_in_the_list": "在清單內",
  "incorrect_qr_code": "QR Code格式錯誤",
  "information_input": "資料輸入",
  "information_search": "資料搜尋",
  "input": "輸入",
  "input_hint": "輸入提示",
  "inv_no": "Invoice No",
  "INVOICE_NUMBER": "Invoice No",
  "is_additional_scan": "",
  "is_additional_scan_alert": "注意：這裡面有些標籤得要額外掃條碼比對",
  "it_is_ok_to_leave_it_blank": "可以空著不填",
  "Label print record is over 5000,please contact your account manager!": "Label print record is over 5000,please contact your account manager!",
  "Label Quantity per Pallet": "每棧板標籤印列張數",
  "label_check_cannnot_scan_hint": "*注意：如果你無法掃描條碼，請手動輸入%{ref_no_name}的%{place}%{number}碼",
  "label_check_rule": "標籤核對規則",
  "label_check_scan_barcode_hint": "請掃描物件上的%{ref_no_name} 條碼",
  "label_check_scan_hint": "請掃描鴻霖標籤上的QR Code",
  "label_quantity_per_pallet": "每棧板標籤印列張數",
  "label_size": "標籤尺寸",
  "last_three_days": "過去3天",
  "last_three_days_to_a_week": "過去3天至1週",
  "last_update": "最後一次更新：%{time}",
  "latest_update_by": "最近更新人員",
  "less_than_3_days_before_etd": "距離ETD只有不到三天",
  "location_setting": "站點位置設定",
  "loose_carton": "散箱",
  "loose_cartons": "散箱數",
  "mapping_info_not_in_database": "%{column}: 資料庫無此紀錄",
  "mapping_info_upload_failed": "上傳失敗",
  "mapping_information_upload": "比對用資料上傳",
  "mapping_method": "比對方法",
  "mapping_target": "要與標籤QR Code的什麼對應",
  "master_number": "MAWB",
  "master_pallet": "MAWB棧板數",
  "master_pieces": "MAWB件數",
  "mawb_dest": "MAWB進口站",
  "mawb_orig": "MAWB出口站",
  "move_to_urgent_confirm": "你確定要在緊急輸入頁面修改？",
  "necessary_to_fill_in": "必填欄位？",
  "not_same_label_size": "標籤非相同尺寸",
  "not_scanned": "未掃描",
  "not_the_same_shipment": "Fast check must to be same shipment",
  "number": "No",
  "ok": "OK!",
  "option_milstone_actual_pickup": "Actual Pickup Date",
  "option_milstone_estimated_pickup": "Estimated Pickup Date",
  "option_milstone_eta": "ETA",
  "option_milstone_etd": "ETD",
  "ordinary_label_print": "一般標籤印列",
  "other": "其他",
  "overwrite": "覆寫",
  "package_checked": "已確認完成的物件數",
  "package_checked_successfully": "物件檢核完成",
  "package_detail": "HAWB包裝細節",
  "package_failed_checked": "比對失敗的物件數",
  "package_failed_checking": "比對失敗的物件",
  "package": "Package",
  "package_id": "Package ID",
  "package_information": "包裝資訊",
  "package_not_checked_in_this_hawb": "未檢核的物件",
  "package_not_checked_ok": "尚未確認完成的物件數",
  "package_not_checked_ok_yet": "尚未確認完成的物件",
  "package_qty": "貨物件數",
  "pallets": "棧板",
  "pallet_counts": "棧板數",
  "pallet_is_not_in_task": "這張標籤不在最近檢核範圍內",
  "pallet_number": "棧板數",
  "part_no": "Part No",
  "PART_NUMBER": "Part No",
  "Please check row first": "Please check row first",
  "please_refresh_task_first": "Please click Refresh button first",
  "please_set_hawb_pcs": "要設定HAWB件數",
  "POSTFIX": "後",
  "PREFIX": "前",
  "print_at": "印列時間",
  "print_status": "列印狀態",
  "print_status_not_printed": "未列印",
  "print_status_printed": "已列印",
  "REF": "參照編碼",
  "ref_cartons": "Ref#內箱數",
  "ref_no": "參照編碼",
  "ref_no_name": "參照編碼名稱",
  "ref_number": "Ref#",
  "refresh_task": "更新資料",
  "refresh_task_done": "資料更新完成",
  "refresh_task_failed": "資料更新失敗",
  "refreshing": "資料更新中...",
  "remark": "備註",
  "reprint_label": "重印標籤",
  "result": "結果",
  "routes": "航段",
  "routing": "包含的航段",
  "routing_leg": "航段",
  "routing_all_legs": "所有航段",
  "routing_first_leg": "第一段",
  "routing_others": "第二段以及其他",
  "routing_number": "航段數",
  "row_no": "行數",
  "rule_book": "規則手冊",
  "rules": "規則",
  "scan": "掃碼",
  "scenario": "情境",
  "search": "查詢",
  "shipment_number": "HAWB",
  "shipper_name": "發貨方",
  "single_input": "單筆輸入",
  "skipped_pcs": "未掃描件數",
  "sscc_code": "SSCC編碼",
  "SSCC_CODE": "SSCC編碼",
  "staff_belonged_station": "人員所屬站點",
  "step": "步驟",
  "summary": "摘要",
  "task_summary": "任務摘要",
  "The Export Send Your Email!": "The Export Send Your Email!",
  "today": "今天",
  "todo_item_hawb_details": "今日待辦事項 - HAWB細節",
  "todo_item_summary": "今日待辦事項 - 總覽",
  "total_cartons": "總箱數",
  "total_for_today": "今天所有的",
  "trigger_condition": "觸發條件",
  "trigger_condition_package_id": "客戶 + Package ID",
  "trigger_condition_place_first": "最初",
  "trigger_condition_place_last": "最後",
  "trigger_condition_vip_name": "客戶",
  "trigger_condition_word_is": "字是",
  "trigger_condition_word_isnot": "字不是",
  "turnaround_station": "中轉站",
  "type": "型態",
  "update": "更新",
  "update_hawb_pkg_data": "更新HAWB包裝細節",
  "update_user_station_success": "使用者所在站點已成功更新",
  "upload_date": "上傳日期",
  "upload_failed": "上傳失敗",
  "upload_step_by_step": "逐步完成上傳",
  "upload_task_count": "上傳任務數：%{number}",
  "upload_the_csv_file": "上傳CSV檔案",
  "urgent_input": "緊急輸入",
  "urgent_input_error_msg_airline_not_found": "%{column}: 找不到此航空公司名",
  "urgent_input_error_msg_cannot_be_zero": "%{column}: 總件數不能為零",
  "urgent_input_error_msg_customer_not_found": "%{column}: 找不到此客戶資料",
  "urgent_input_error_msg_incorrect_hawb": "%{column}: HAWB格式錯誤",
  "urgent_input_error_msg_incorrect_scenario": "%{column}: 錯誤的情境值",
  "urgent_input_error_msg_incorrect_station": "%{column}: 站點名稱錯誤",
  "urgent_input_error_msg_negative": "%{column}: 不是正整數",
  "urgent_input_error_msg_no_data": "%{column}: 沒有資料",
  "urgent_input_error_msg_incorrect_shipment_type": "%{column}: 錯誤的貨物類型",
  "validation_failed_message": "%{type} : %{msg}",
  "vip_name": "客戶",
  "watch_out": "注意!",
  "we_have": "我們有",
  "worksheet": "工作清單",
  "you_can_only_fill_u_e_or_a": "只能填入U、E或A",
  "no_label_print": "No Label Print",
  "no_mapping_info": "No Mapping info",
  "create_failed": "Create Failed",
  "no_presigned_url": "No presigned url",
  "upload_s3_failure": "Upload S3 Failure",
  "no_download_url": "No Download url",
  "site": "地點",
  "series_type": "序列號類型",
  "series_number": "序列號碼",
  "reserve": "保留",
  "storage_location": "儲位",
  "reference_number": "參照號碼",
  "damaged": "受損",
  "record_cargo_information": "紀錄貨物資訊",
  "temporary_series_number": "臨時序列號碼",
  "receive": "收貨",
  "reject": "退回",
  "received_quantity": "實際收貨數量",
  "last_received_date": "最後收貨日期",
  "confirm_receiving": "確認收貨？",
  "confirm_receiving_message": "要現在結束這筆HAWB的收貨過程嗎？ (HAWB件數會被已收到貨物件數替換)",
  "click_here_to_print_the_storage_remark": "點擊此處印列暫存單",
  "confirm_delete": "確定刪除？",
  "do_you_want_to_remove_selected_photos": "確定移除選擇的照片？",
  "over_max_file_count %{max_length}": "超過檔案數量上限：${max_length}",
  "call_printer_success_task": "成功呼叫印表機",
  "call_printer_in_progess": "印列中請稍候",
  "selected_label": "已選標籤份數",
  "completed": "處理完成份數",
  "create_temporary_series": "建立暫存系列",
  "more_information": "更多資訊",
  "whether_to_show_on_the_label": "是否要在標籤上顯示",
  "display_mawb_pcs": "顯示MAWB件數",
  "display_hawb_pcs": "顯示HAWB件數",
  "do_you_want_to_save_the_changed_information  ": "你要儲存已更動的資訊嗎？",
  "actually_shown_on_the_label": "實際顯示於標籤：",
  "security_declaration": "保安聲明書",
  "what_to_print_out": "哪些要印列",
  "label": "標籤",
  "shipping_remark": "嘜頭",
  "display_mawb": "Display MAWB",
  "display_hawb": "Display HAWB",
  "shipment_type_hint": "只能填入字母H或D",
  "shipment_type": "出貨類型",
  "revise_the_major_information_on_the_label": "修改標籤上的主要資訊",
  "revise_the_destination_airport": "修改目的地機場",
  "revise_reference_information_for_this_shipment": "修改此貨件的參考信息",
  "revise_the_package_details_for_reference": "修改包裹詳情（供參考）",
  "revise_the_remark_information_on_the_label": "修改標籤上的備註資訊",
  "directly_find_the_package_id_to_revise_the_remark": "直接找到 Package ID 修改備註",
  "select_the_package_id_above_and_input_the_revised_remark_information_then_click_overwrite_button": "選擇上面的 Package ID，輸入修改後的備註資訊。 然後點擊覆蓋按鈕",
  "it_is_required_to_revise_in_urgent_input_tab_if_needed_to_change_the_package_pieces_of_apple_gar_or_garmin_or_ask_customer_to_resending_the_edi_continue": "Apple GAR或Garmin的貨物若要調整件數，須在緊急輸入功能下修改，否則請客戶重傳EDI。 \\n是否繼續處理？",
  "whe_cannot_you_find_the_labels": "為什麼您找不到標籤？",
  "URGENT_USE": "未收到EDI",
  "paste_the_label_information": "貼上標籤的資訊",
  "multiple_input": "多筆輸入",
  "key_points": "要點",
  "only_mawb_pcs_customer_and_remark_are_not_required_columns": "只有 MAWB Pcs、客戶和備註是不要求填寫的",
  "customer_is_required_to_fill_in_while_the_scenario_is_urgent_use": "當情境是 Urgent Use 時，客戶是要求要填寫的",
  "not_necessary_to_input_capitalized_letters": "字首不需要是大寫",
  "options_for_scenario_and_label_size_are_fixed": "情境和標籤大小的選項是固定的",
  "system_will_inspect_whether_you_input_the_information_correctly_or_not": "系統會檢查您輸入的資訊是否是正確的",
  "information_will_not_be_saved_until_all_columns_are_correct": "直到所有的欄位都正確，否則資訊都不會儲存",
  "urgent_use": "未收到EDI",
  "click_here_to_update_package_id": "點擊此處更新Package ID",
  "notes_of_revising_hawb_pcs": "*注意：如果你有修改HAWB件數，要記得點擊%{logo}圖案",
  "urgent_input_instruction_1": "只有MAWB Pcs、客戶名、備註不是必填欄位。",
  "urgent_input_instruction_2": "系統預設情境是\"未收到EDI\"，如果你要選擇其他情境，請將此格內容清空。",
  "urgent_input_instruction_3": "在\"未收到EDI\"情境下，標籤尺寸固定是4x6。",
  "urgent_input_instruction_4": "如果情境不是\"未收到EDI\"，你可以自行選擇標籤尺寸。",
  "urgent_input_instruction_5": "標籤尺寸只能輸入4x6或4x3。",
  "urgent_input_instruction_6": "在貨物類型這欄，輸入H代表Hub，輸入D則代表Direct Ship。",
  "urgent_input_instruction_7": "如果客戶是Apple GAR，則貨物類型會變成必填欄位。",
  "urgent_input_instruction_8": "英文字母輸入後會自動轉變為大寫。",
  "urgent_input_instruction_9": "系統會檢查你輸入的資料是否正確。如果任何一欄存在錯誤，系統就不會儲存輸入的資料。",
  "multiple_sets_tooltip": "您可以輸入多組字段，系統將根據各字段內容進行搜尋",
  "type_in_keyword_tooltip": "輸入關鍵字並選擇一個系統提示的選項",
  "select_the_scenario": "請選擇適用情境",
  "input_the_conditions": "輸入搜尋條件",
  "option_milstone_created_at_utc": "生成日期",
  "necessary_to_input": "必填的",
  "the_text_will_be_shown_on_all_of_the_labels": "這段文字會在所有的標籤上顯示",
  "necessary_to_input_only_if_scenario_is_urgent_use": "只有在情境是\"未收到EDI\"，才是必填的",
  "supplement_label": "補充標籤",
  "saved_items": "已儲存項目",
  "select_all": "全部選擇",
  "cancel": "取消",
  "cancel_all": "全部取消",
  "printing_quantity": "列印數量",
  "why_cannot_you_find_the_labels": "為什麼找不到標籤？",
  "error_code_request_item_is_invalid": "%{item}是無效輸入",
  "error_code_search_not_found": "找不到資料",
  "error_code_print_size_not_same": "不能夠同時生成不同尺寸的標籤檔案",
  "main_page": "主頁面",
  "package_level": "包裝層級",
  "delivery_level": "DN層級",
  "photo_management": "圖片管理",
  "select_the_milestone_to_check": "選擇Milestone",
  "enter_the_hawb_or_package_id": "輸入 HAWB 或 Package ID",
  "enter_hawb_or_dn_or_package_id": "輸入 HAWB，DN 或 Package ID",
  "enter": "進入",
  "export_receiving": "出口 - 收貨",
  "fuzzy_tooltip": "模糊搜尋：只須輸入部分文字",
  "supplement_column_tooltip": "上傳圖片來生成 4x3 大小的標籤",
  "choose_the_scenario": "選擇情境",
  "fill_in_the_label_information": "填入標籤的資訊",
  "display_airline_logo": "顯示航空公司標誌",
  "hawb_postfix": "在HAWB後加上後綴",
  "urgent_input_error_msg_incorrect_label_size": "%{column}: 尺寸設定錯誤",
  "urgent_input_error_msg_incorrect_mawb": "%{column}: MAWB格式錯誤",
  "urgent_input_error_msg_cannot_type_in_numbers": "%{column}: 不能是數字",
  "urgent_input_error_msg_exceed_4_characters": "%{column}: 字元數最多4個",
  "hawb_suffix": "HAWB 後綴",
  "add_suffix_in_hawb": "HAWB 加上後綴字符",
  "notice_multiple_shipment_selected": "注意！已經選擇多筆出貨資料",
  "photo": "照片",
  "text": "文字",
  "mnl_hongkong_airline_name_cargo": "修改航空公司名稱",
  "mawb": "MAWB",
  "simple_mode": "簡易模式",
  "here_you_can_only_search_the_data_that_booking_is_made_within_30_days": "這裡你只能搜尋到30天內建立的資料",
  "select_the_information_level": "請選擇資料層級",
  "photo_taking": "拍照上傳",
  "take_photo_now": "開始拍照",
  "check_all_photo": "確認照片",
  "pouch_label": "小袋標籤",
  "please_input_valid_shipment_number_or_package_number": "Please input valid shipment number or package ID",
  "select_the_photos_you_surely_want_to_upload": "請選擇您確定要上傳的圖片",
  "check": "確認",
  "error_label_size_not_set": "尚未設定這一尺寸的標籤模板",
  "error_source_folder_not_set": "印表機路徑(資料夾)尚未建立",
  "change_label_size": "自選標籤尺寸",
  "choose_what_to_upload": "Choose what to upload",
  "you_cannot_select_both_hawb_and_package_id": "You cannot select both HAWB and Package ID",
  "partial_receive": "Partial Receive",
  "handled_today": "Handled Today",
  "label_quantity": "Label Quantity",
  "print_label": "Print Label",
  "you_should_select_at_least_one_hawb": "You should select at least one HAWB",
  "receive_by_package": "Receive By Package",
  "receiving": "Receiving",
  "please_scan_the_qrcode": "Please scan the QR Code",
  "just_scanned_in": "Just Scanned In",
  "status": "Status",
  "customer": "Customer",
  "package_to_receive": "Package To Receive",
  "repeat_scan": "Repeat Scan",
  "received": "Received",
  "error_shipment_can_not_split_combine": "Shipment can not split & combine",
  "carton_qty": "Carton Qty",
  "document_type": "文件類別",
  "printer_name": "印表機名稱",
  "now_you_are_at": "現在你在",
  "choose_a_printer": "選擇印表機",
  "select_the_default_printer": "選擇預設印表機",
  "fix_the_printer_setting": "固定印表機設定",
  "please_select_printer_for_each_label_size": "請為每個標籤尺寸選擇印表機",
  "photo_upload": "前往拍照",
  "pallet_split_and_combine": "Pallet Split & Combine",
  "scan_the_qrcode_on_the_label": "Scan the QRcode on the label",
  "pcs": "件數",
  "urgent": "Urgent ",
  "dn_to_reallocate": "DN's to re-allocate",
  "dn_no": "DN No.",
  "print_out": "Print Out",
  "reallocate_the_dn": "Re-allocate the DN's",
  "qty": "Qty",
  "edit": "Edit",
  "save": "Save",
  "selected_qty": "Selected Qty",
  "combine_with": "Combine With",
  "splitted_packages": "調整後的物件",
  "choose_a_pallet_to_put_these_cartons": "Choose a pallet to put these cartons",
  "consignee": "Consignee",
  "brief_address": "Brief Address",
  "type_in_the_carton_qty_and_click_the_button_below": "輸入箱盒數，然後點擊下方按鈕",
  "split_out": "Split out",
  "please_type_in_qty": "這邊必須輸入數字",
  "please_input_correct_number": "Please input correct number",
  "exceed_carton_qty": "Exceed Carton Qty",
  "please_allocate_all_delivery": "Please allocate all delivery",
  "already_pick_up_ofd": "Aleady Pickup (OFD)",
  "not_received": "沒有Receive掃碼",
  "already_put_away": "Already Put Away",
  "not_put_away_yet": "Not Put Away Yet",
  "no": "No.",
  "to_do_list": "To-do List",
  "group_put_away": "Group Put-away",
  "refresh_data": "Refresh Data",
  "open_pcs": "Open Pcs",
  "scanned": "Scanned",
  "do_you_want_to_clear_all_shipments": "Do you want to clear all shipments",
  "put_away": "Put Away",
  "error_code_cannot_find_the_location": "資料庫中找不到此一儲位號碼",
  "error_code_resource_not_found": "%{resource} is not found",
  "error_delivery_package_can_not_put_away": "%{delivery_package_number} can not put away",
  "stored_package_qty": "Stored Package Qty",
  "delivery_no": "Delivery No.",
  "package_checker": "Package Checker",
  "consignee_address": "Consignee Address",
  "current_milestone": "Current Milestone",
  "next_milestone": "Next Milestone",
  "action_required": "Action Required",
  "latest_updated_by": "Latest Updated By",
  "latest_updated_at": "最後更新時間",
  "put_away_by": "Put away by",
  "put_away_time": "Put away time",
  "put_away_combo_mode_data_dialog_message": "為確保資料完整性，我們會在切換模式時清除暫存的已掃描資訊。你確定仍要切換模式？",
  "carton_sorting": "箱盒分類",
  "result_summary": "成果總結",
  "sorting_info_upload": "整理資訊上傳",
  "start": "開始",
  "stop": "停止",
  "scan_the_barcode": "掃描條碼",
  "repeated_scan": "重複掃描",
  "wrong_barcode": "條碼錯誤",
  "scan_result": "掃描結果",
  "package_number": "包裹號碼",
  "get_report": "取得報告",
  "linked_with_hawb": "與 HAWB 連結",
  "sorting_input_date": "整理鍵入日期",
  "start_date": "開始日期",
  "end_date": "結束日期",
  "date_range_should_be_within_n_days": "日期範圍應該在 %{days} 日內",
  "start_date_should_be_before_or_eqaul_to_end_date": "開始日期應該早於或等於結束日期",
  "date": "日期",
  "packslip_number": "裝箱單號",
  "import_receive": "Import Receive",
  "remember_to_get_pod_sign_off": "Remember to get POD sign-off",
  "check_if_you_get_the_pod_copy": "Check if you get the POD copy",
  "package_checker_milestone_import_cfs_not_arrived": "Not arrived yet",
  "package_checker_milestone_truck_arrived": "Truck arrived",
  "package_checker_milestone_import_cfs_received": "Received",
  "package_checker_milestone_import_cfs_reweigh": "Reweigh & Take photos",
  "package_checker_milestone_import_cfs_sorting": "Sorting",
  "package_checker_milestone_available_for_delivery": "Put Away",
  "package_checker_milestone_import_cfs_pickup": "Picking",
  "package_checker_milestone_ofd": "Out for delivery",
  "package_checker_milestone_actual_pod": "POD",
  "cannot_find_this_delivery_package": "資料庫中找不到此號碼",
  "latest_updated_at_utc": "Latest Updated at (UTC)",
  "field_is_required": "%{field} is required",
  "field_is_duplicate": "%{field} is duplicate",
  "error_delivery_package_can_not_pick_up": "%{delivery_package_number} can not pick up",
  "picking_entry": "Picking-Entry",
  "scan_the_qrcode_on_the_picking_order": "Scan the QRcode on the Dispatch Manifest",
  "dispatch_no": "Dispatch No",
  "truck_pickup_time": "Truck Pickup Time",
  "bol_no": "BOL No",
  "pallet_is_not_in_dispatch": "這棧板不在此Dispatch內",
  "package_ref_info": "包裝參考資訊",
  "flight_status": "航班狀態",
  "on_way": "在途中",
  "landed": "已降落",
  "is_checked": "已掃碼檢查",
  "shipment_information": "出貨資訊",
  "lot_no": "Lot No.",
  "bag_id": "Bag ID",
  "quantity": "數量",
  "scanned_quantity": "已掃描數量",
  "Bag Checking": "包裹檢查",
  "input_the_mawb": "輸入MAWB單號",
  "scan_the_qr_code_on_the_package": "掃描貨件上的QR Code",
  "there_is_still_information_not_uploaded": "尚有資訊未上傳",
  "confirm_upload": "是否確定要上傳？",
  "bag_id_is_not_belong_to_master_number": "%{bag_id} 不屬於 %{master_number}",
  "weight": "重量",
  "get_weight": "取得重量",
  "reweigh": "重新秤重",
  "record_the_weight_result": "Record the weight result",
  "reweigh_summary": "Reweigh Summary",
  "select": "選取",
  "link_the_scale": "連接磅秤儀器",
  "reweigh_pcs": "已重新秤重的件數",
  "reweigh_sum": "重新秤重數字加總",
  "package_to_be_reweighted": "尚待重新秤重的物件",
  "are_you_sure_to_submit_the_scanned_information": "確定要提交掃描的資訊?",
  "destination": "目的地",
  "error_has_already_received": "%{number} 已收到",
  "error_has_been_already_ofd": "已經被派送出去",
  "error_has_not_received_yet": "%{number} 尚未收到",
  "error_has_not_received_all_shipment_packages_yet": "尚未收到所有棧板",
  "error_must_reweigh_and_take_pictures": "%{number} 還未對所有棧板秤重與拍照存證",
  "error_remember_reweigh_and_take_pictures": "記得要重新秤重與拍照！",
  "error_no_need_to_split_combine": "%{number} 不可拆分",
  "error_has_already_pick_up": "已掃碼提貨",
  "error_has_not_put_away_yet": "此棧板尚未掃碼入庫",
  "error_has_not_split_and_combine_yet": "還沒完成棧板拆分！",
  "error_freight_is_damaged": "%{number} 貨物有受損！",
  "error_delivery_can_not_recreate": "DN已經被綁定到其他單據上",
  "field_must_be_positive_integer": "%{field} 必須是正整數",
  "error_cant_upload_after_bag_checking": "掃描後無法上傳貨物清單",
  "bag_checking": "Bag Checking",
  "error_cannot_update_import_received_date": "Cannot Update Import Receivee Date",
  "sheet_status": "單據狀態",
  "sheet_number": "單據號碼",
  "destination_mec_station": "目的地(鴻霖站點)",
  "delivery_number": "DN",
  "estimated_delivery_date_sla": "SLA",
  "origin_station": "出口站",
  "destination_station": "進口站",
  "target_mec_station": "目的地(鴻霖站點)",
  "estimated_truck_pickup_at": "預計提貨日期",
  "estimated_truck_pickup_at_time": "預計提貨時間",
  "actual_truck_pickup_at": "實際提貨日期",
  "estimated_delivery_at": "預計送達日期",
  "estimated_delivery_at_time": "預計送達時間",
  "delivery_created_at_utc": "DN創建日期(UTC)",
  "sheet_created_at": "單據生成時間",
  "is_delivery_put_on_bol": "DN是否已放上BOL？",
  "is_delivery_readiness": "DN派送準備狀態",
  "split": "從單據中拆分出去",
  "created_at_utc": "創建時間(UTC)",
  "ready_for_delivery": "已準備就緒",
  "not_ready": "尚未完備",
  "at_least_one_row": "請至少勾選一個項目",
  "at_least_two_row": "請至少勾選二個項目",
  "at_least_one_transfer_manifest": "請至少勾選一個調轉清單",
  "at_least_one_need_transfer_delivery": "請至少勾選一個DN",
  "cannot_combine_transfer_manifests": "無法將多張調轉清單組合起來",
  "cannot_split_transfer_manifest": "無法拆分這張調轉清單",
  "only_can_print_transfer_manifests": "沒在調轉清單上的項目無法印列",
  "cannot_create_more_transfer_manifest": "一個DN僅能用來建立一張調轉清單",
  "cannot_revise_picked_data": "此單據已提貨，無法異動",
  "sheet_information_cannot_be_revised": "單據資料已鎖定，無法異動",
  "are_you_sure_to_create_the_sheet": "你確定要生成新的%{type}？",
  "are_you_sure_to_update_the_sheet": "你確定要更新單據資訊？",
  "are_you_sure_to_cancel_the_sheet": "你確定要刪除%{type}？",
  "are_you_sure_to_combine_the_sheet": "你確定要將DN併入此張%{type}？",
  "are_you_sure_to_split_the_sheet": "你確定要將DN從現有單據拆分出？",
  "carrier_code": "承運人",
  "is_package_damaged": "貨物受損",
  "select_ble_device": "請選取藍芽裝置",
  "disconnect": "與現有裝置斷開連結",
  "parse_message_failed": "重量數據接收失敗",
  "sync_message_failed": "與裝置連線失敗",
  "additional_milestone": "--額外新增--",
  "latest_printed_label_qty": "最後一次印列張數",
  "hawb_prefix": "HAWB前綴字元",
  "label_shipment_number_postfix": "後綴",
  "reweigh_is_number": "必須要在這裡輸入數字",
  "import_receive_multibox_step1": "第一步",
  "import_receive_multibox_step1_text": "請將貨物放置整齊，然後開始清點箱盒的件數",
  "import_receive_multibox_step2": "第二步",
  "import_receive_multibox_step2_text": "請輸入這些箱盒的實際件數",
  "import_receive_multibox_step3": "第三步",
  "import_receive_multibox_step3_text": "這些箱盒被放置在多少棧板上？",
  "import_receive_multibox_confirm_text": "注意：從現在開始，這筆貨會以下列型態處理",
  "import_receive_multibox_confirm_warning": "在這之後你就不能夠改變貨物的包裝型態了",
  "import_receive_multibox_confirm_print": "現在要列印新標籤嗎？",
  "import_receive_multibox_check_text": "根據系統中登記的貨物件數與包裝單位，這筆貨不應該改變其包裝型態",
  "import_receive_multibox_check_warning": "你仍然要繼續下去？",
  "import_receive_multibox_leave_confirm": "你確定要離開目前頁面的操作？",
  "import_receive_multibox_leave_info": "繼續掃碼確認",
  "photo_of_the_scale": "磅秤讀數照片",
  "cargo_outlook": "貨物外觀",
  "error_received_when_have_inventory_manifest": "請直接進行貨物入庫掃碼",
  "is_damaged": "Is Damaged",
  "missing_dispatch_info": "派車單資料不存在",
  "you_should_scan_delivery_packages_first": "請先掃描標籤上的QR code",
  "error_package_type_transformation": "棧板類型貨物不能夠轉變包裝類型",
  "combo_mode_name": "Combo Mode",
  "ship_to_name": "收貨者名稱",
  "ship_to_city": "目的地城市",
  "error_has_been_already_ofd_by_tms": "%{number} - 掃碼完成 (已經在TMS做完OFD)",
  "please_select_at_least_one_photo": "你至少要選擇一張照片才行",
  "handover": "Handover",
  "mawb_arrived": "%{mawb} 抵達",
  "mawb_incorrect": "主單號錯誤",
  "sheet_number_incorrect": "系統中不存在此單號",
  "check_mawb": "檢查MAWB",
  "new_package_id": "新的Package ID",
  "old_package_id": "原有Package ID",
  "the_pallet_is_not_for_this_truck": "這個棧板不在派送清單內",
  "location": "儲位",
  "urgent_label": "緊急輸入的標籤？",
  "picking": "Picking",
  "label_print": "標籤是否印列？",
  "label_type": "標籤類型",
  "ofd": "OFD",
  "error_received_must_split_combine": "務必進行 Pallet Split and Combine 流程",
  "select_source_of_photo": "Choose how to get the photos",
  "photo_source_camera": "前往拍照",
  "photo_source_device": "從裝置選取",
  "station": "所在站點",
  "inventory_management_notice": "這裡我們只看還未被派送出去的貨物",
  "photo_selection_info": "相片: %{number} / %{total}",
  "photo_move": "移動相片",
  "photo_move_title": "移動相片到:",
  "photo_move_target_information_level": "資料層級",
  "photo_move_target_milestone": "Milestone",
  "photo_move_target_target_number": "Target Number",
  "no_printer_set_up_in_this_site": "系統中尚未建立此站點的印表機資訊",
  "must_split_combine_modal_message": "注意：在做Put Away之前，要先Pallet Split & Combine功能使用生成新標籤",
  "timestamp": "顯示詳細時間",
  "handling_record": "貨物流轉紀錄",
  "delivery_id": "Delivery ID",
  "partial_receive_confirmation": "Are you sure to partially receive current freights?",
  "error_sync_from_prolink_failed": "資料同步失敗",
  "sync_from_prolink": "正在進行資料同步",
  "error_need_reprint_label": "由於客戶代碼設定錯誤，你須要重印標籤",
  "error_vip_name_need_checked_and_revised": "客戶代碼被改成錯誤的類型，請通知出口站修改booking資料",
  "split_and_combine": "Split & Combine",
  "single_package": "單一貨件",
  "scan_qrcode_or_enter_hawb_below": "掃描QR Code或是在下方輸入HAWB號碼",
  "break_pallet": "要拆分棧板",
  "record": "時間紀錄",
  "unfold": "展開",
  "pod": "POD",
  "truck_arrives": "卡車到場",
  "ata": "ATA",
  "footprints": "時間軌跡",
  "double_click_to_select_the_package_id": "連續點擊兩次以選擇Package ID",
  "fold": "收束",
  "delivery_number_more": "...其他",
  "cannot_create_different_delivery_method": "不同遞送方式的貨物，不能同時選擇！",
  "cannot_combine_different_delivery_method": "你選擇的項目裡面有包含不同遞送方式的貨物",
  "sheet_type": "表單類型",
  "service_type": "服務類型",
  "transfer_manifest": "調轉清單",
  "customer_pickup": "客戶自提單",
  "inventory_adjustment_create_error": "不同遞送方式的貨物，不能同時選擇！",
  "inventory_adjustment_combine_error": "你選擇的項目裡面有包含不同遞送方式的貨物",
  "error_cannot_delete_no945_shipment": "用\"未收到EDI\"情境生成的資料不能被刪除",
  "pickup_some_not_picked_up": "Please keep scanning the rest of packages",
  "pickup_all_picked_up": "Remember to sign up Customer Pickup Form and take pictures on it",
  "warning_cargo_need_to_break_down": "此棧板須要拆分並根據DN重新包裝",
  "error_shipment_not_exists": "",
  "warning_delivery_package_already_rebuild": "有幾筆HAWB的資料以前曾被重建過<br/>確定要繼續？<br/><br/>%{shipments}",
  "pallets_to_break_down": "待拆解的棧板",
  "choose_the_method_to_reallocate_the_dn": "請選擇重新分配DN的方式",
  "manual_way": "手動分配",
  "automatic_way": "自動分配",
  "system_suggestion": "系統提供的建議",
  "update_pcs": "更新後的件數",
  "save_and_reprint_labels": "儲存並重印標籤",
  "split_qty": "拆分出的箱盒數",
  "please_select_one_package_to_combine": "請選擇一個物件作為合併標的",
  "need_to_reprint_labels": "你須要重印標籤！",
  "you_have_not_reprinted_the_labels": "你還沒重印標籤！",
  "do_you_want_to_reprint_labels_now": "你現在要重印標籤嗎？",
  "just_leave_anyway": "不用，先離開此功能",
  "let_me_do_it_now": "是的，現在進行",
  "last_received_time": "最後收貨時間",
  "inbound_checking": "Inbound Checking",
  "error_pick_up_cancelled_dispatch": "這份派車單已經被取消",
  "error_pick_up_dispatch_with_all_splittable_packages": "請先操作Pallet Split & Combine",
  "error_pick_up_dispatch_with_all_urgent_created_packages": "這些都是緊急生成的標籤，請先完成Receiving與Sorting",
  "info_not_show_goods_on_table": "*This table does not show %{brand} freights.",
  "input_location_code": "Input the location code",
  "data_rebuild": "資料重建",
  "data_rebuild_input_hawb": "輸入HAWB",
  "data_rebuild_button_hint": "點此按鈕，開始重建資料",
  "data_rebuild_rebuild_button": "重建物件資料",
  "data_rebuild_result_success": "重建成功<br/><br/>%{shipments}",
  "data_rebuild_result_some_failed": "重建成功<br/>%{successes}<br/><br/>但有幾筆重建失敗<br/>%{failures}",
  "data_rebuild_result_error": "重建失敗<br/><br/>%{shipments}",
  "urgent_multiple_input_instruction_1": "只有MAWB Pcs、備註不是必填欄位。",
  "urgent_multiple_input_instruction_2": "標籤尺寸只能輸入4x6、4x5或4x3。",
  "urgent_multiple_input_instruction_3": "英文字母輸入後會自動轉變為大寫。",
  "urgent_multiple_input_instruction_4": "系統會檢查你輸入的資料是否正確。如果任何一欄存在錯誤，系統就不會儲存輸入的資料。",
  "label_configuration": "標籤佈局",
  "click_to_partially_print_the_label": "點擊此處以印列部分標籤",
  "missing_record_alert": "注意：你所要搜尋的標籤資訊並非全部都能找到",
  "check_details": "查看細節",
  "data_availability": "標籤資料完善狀況一覽",
  "available_or_not": "資料是否完善？",
  "get_label_information": "獲取標籤資訊",
  "get_label_information_notice": "*如果客戶名稱是APPLE_GAR，系統會生成緊急輸入類型的標籤供使用",
  "advanced_search": "進階搜尋",
  "fuzzy_search": "模糊搜尋",
  "edi945_in": "收到EDI945",
  "latest_print_time": "最後一次印列時間",
  "first_print_time": "第一次印列時間",
  "edi_receive_time": "EDI收到時間",
  "revise_airport_code": "修改機場代碼",
  "notes_of_revising_package_details": "*注意：通常HAWB件數應該是棧板數量與散箱數量的加總。若有必要則在此修改HAWB件數",
  "revise_the_package_information_on_the_label": "修改標籤上顯示的包裝資訊",
  "reset_with_edi945": "用EDI945資料重置",
  "cannot_input_mawb_and_hawb_at_the_same_time": "你不能同時輸入MAWB與HAWB進行搜尋",
  "notice_of_revising_package_of_apple_gar_or_garmin": "Apple GAR或Garmin的貨物若要調整件數，須在緊急輸入功能下修改，否則請客戶重傳EDI。",
  "sort_date": "分類日期",
  "update_date": "更新日期",
  "must_larger_than_hawb_pcs": "主號件數不能小於小號件數",
  "package_count": "物件數量",
  "atd": "ATD",
  "need_breakdown": "須要拆分棧板",
  "missing_milestone": "缺少Milestone資訊",
  "no_truck_arrival_time": "沒有卡車抵達時間",
  "not_broken_down": "沒有棧板拆分紀錄",
  "not_picked": "沒有貨物被提取的紀錄",
  "carton_sorting_incorrect_field": "錯誤的%{field}",
  "check_consignee_for_packslip": "請確認下列Packslip No.的收件人名稱",
  "scan_record": "掃描紀錄：",
  "type_in": "輸入",
  "type_in_the_packslip_no": "請輸入Packslip No.",
  "manual_input": "手動輸入",
  "time": "時間",
  "adjust_quantity": "數量調整",
  "total_quantity": "總數量",
  "carton_sorting_error_msg_incorrect_shipper": "發貨方名稱錯誤",
  "carton_sorting_error_msg_incorrect_consignee": "收貨方名稱錯誤",
  "identify": "辨識",
  "re_select_photo": "請重新選取相片範圍",
  "airline_code": "Airline Code",
  "airline_code_max_length": "Code max length is %{length}",
  "airline_modal_create_title": "Create Airline",
  "airline_modal_edit_title": "Edit Airline",
  "error_code_others": "未知因素所導致，請洽IT工程師",
  "cant_sync_received_shipment": "進口站收到貨物後，就不能在此更新資訊",
  "direct_upload": "直接上傳",
  "label_printing": "標籤列印",
  "specific_list": "特殊清單",
  "default_setting_for_the_station": "站點的初始設定",
  "add_site": "新增地點",
  "1_printers": "1. 印表機",
  "2_labels": "2. 標籤",
  "delete_station": "刪除站點",
  "template_name": "模板名稱",
  "list_name": "清單名稱",
  "location_code": "儲位代碼",
  "tpe_cs_list": "TPE客服人員清單",
  "employee_id": "員工編號",
  "chinese_name": "中文姓名",
  "phone_number": "電話號碼",
  "close": "關閉",
  "only_handle_ptt": "這裡我們只處理PTT檔案",
  "directly_put_away_with_ia": "請直接將這些貨物上架存放",
  "notify_to_do_inbound_check": "請使用Inbound Checking功能",
  "can_not_less_than_scanned_qty": "不可小於Scanned qty",
  "abnormal_receive": "在此記錄異常情況",
  "1_type_in_hawb": "1. 輸入HAWB",
  "2_select_issue_on_label": "2. 選擇標籤發生的問題類型",
  "3_reprint_labels": "3. 重印標籤",
  "4_record_situation": "4. 記錄實際情況",
  "import_receive_issue_bad_printing_quality": "印列品質不佳",
  "import_receive_issue_damaged_label": "標籤破損",
  "import_receive_issue_different_package_ids_on_pallet": "不同的Package ID被貼在同一件貨上",
  "import_receive_issue_others": "其他",
  "volume_download": "批量下載",
  "notify_the_result_of_uploading": "提示上傳結果",
  "you_have_to_select_photos_first": "你須要先選擇照片",
  "undefined": ""
 },
 "billing": {
  "charge_amount": "金額",
  "allocation_edit_header_new": "創建訂單編號：",
  "allocation_edit_header_edit": "編輯訂單編號：",
  "allocation_edit_header_view": "檢視訂單編號：",
  "allocation_sum": "加總檢查",
  "credit_to_id": "廠商",
  "issued_by_station": "站點",
  "payment_method": "付款方式",
  "origin_currency": "幣別",
  "origin_amount": "金額",
  "station": "站點",
  "department": "部門",
  "charge_code": "收費代碼",
  "quantity": "數量",
  "origin_charge_rate": "費率",
  "unit": "單位",
  "calculate_by": "計算方式",
  "allocation_method": "分配方式",
  "confirm": "確認",
  "allocation_history_header": "歷史紀錄",
  "po_request_id": "採購訂單編號",
  "created_date": "建立日期",
  "prolink_id": "Prolink 編號",
  "view": "檢視",
  "edit": "編輯",
  "sum_error": "總和不符!",
  "add_new_allocation": "新增",
  "calculate": "計算",
  "allocation_new_dispatch_must_has_allocation": "派車單必須要有相應的費用分攤",
  "allocation_sum_check_not_match": "加總金額不符",
  "ttl_amount": "總額",
  "ref_sharp": "參考號碼",
  "dept": "部門",
  "reference_number": "參考號碼",
  "vip_name": "客戶",
  "bill_to_id": "借方 / 供應商",
  "category": "類別",
  "billing_type": "帳單類型",
  "invoice_type": "發票類型",
  "invoice_voucher_number": "發票 / 憑證號碼",
  "shipment_number": "貨件號碼",
  "print_at_from": "列印起始日",
  "print_at_to": "列印結束日",
  "void": "作廢",
  "client_id": "Client ID",
  "sales_number": "Sales Code",
  "ap_status": "AP 狀態",
  "customer": "客戶",
  "printed": "已列印",
  "request": "Request",
  "delete": "刪除",
  "amount": "金額",
  "currency": "幣別",
  "bcr_category_id": "BCR 類別",
  "bcr_reason_code": "BCR 原因",
  "voucher_history_header": "PO 資訊 (來自 Prolink/ CW1)",
  "void_confirm_signature": "已跟 Accounting 團隊確認可以作廢此筆發票 / 憑證",
  "invouce_voucher_number": "Invouce/ Voucher No",
  "reference_no": "參考號碼",
  "billing_correction_request_bcr": "帳單更正請求 - BCR",
  "trade_type": "Transaction",
  "transaction": "Transaction",
  "tips": "提示",
  "mass_upload_tips_detail": "若選擇\"General\"，1筆上傳資料會建立1筆PO或是Invoice。 \n若選擇\"3rd Party Station\", 1筆上傳資料會建立1筆PO和兩筆Invoices。 ",
  "mass_upload_tips_detail_step2": "所列出的結果會被傳送到Prolink/ CW1並執行列印",
  "created_by": "創建者",
  "charge_category": "費用類別",
  "project_id": "Project ID",
  "fd_no": "FD No",
  "pr_no": "PR No",
  "request_type": "Request種類",
  "request_status": "Request狀態",
  "is_late": "Late Billing",
  "mawb_mbl": "MAWB/ MBL",
  "create_date_utc": "Created Date (UTC)",
  "print_at": "列印日期",
  "voided_date": "Void日期",
  "voucher_type_invoice": "Invoice",
  "voucher_type_voucher": "Voucher",
  "trade_type_trade": "Trade",
  "trade_type_non_trade": "Non Trade",
  "ap_status_c": "C",
  "request_type_displayed_create": "Create",
  "request_type_displayed_void": "Void",
  "request_status_in_progress": "In Progress",
  "request_status_failure": "Failure",
  "billing_tab_posted_tab": "Printed/ Voided",
  "billing_tab_requested_tab": "Request",
  "bill_credit_to_id": "Debit To/ Vendor",
  "bill_credit_to": "Debit To/ Vendor",
  "charge_line_origin_currency": "Rate幣別",
  "charge_line_origin_amount_in_usd": "金額(USD)",
  "allocated_origin_allocation_amount": "Allocated金額",
  "allocated_origin_allocation_amount_in_usd": "Allocated金額(USD)",
  "request_serial_number": "Request ID",
  "charge_line_description": "費用描述",
  "invoice_amount": "發票金額",
  "invoice_amount_in_usd": "發票金額(USD)",
  "invoice_currency": "發票幣別",
  "nvat_amount": "稅前金額",
  "vat_amount": "VAT金額",
  "ap_amount": "已處理AP金額",
  "cant_void_void_request_is_processing": "作廢請求正在處理中，無法再次作廢。",
  "amount_usd": "金額(USD)",
  "allocated_amount_usd": "Allocated金額(USD)",
  "failure_reason": "失敗原因",
  "type": "種類",
  "billing_type_main": "MAIN",
  "billing_type_credit_note": "CREDIT",
  "billing_type_debit_note": "DEBIT",
  "ap_status_complete": "完成",
  "ap_status_delete": "刪除",
  "mass_upload": "批次上傳",
  "billing_mass_upload_type_general": "General",
  "billing_mass_upload_type_third_station": "3rd Party Station",
  "mass_upload_step1_title_general_upload": "步驟1: 一般上傳",
  "mass_upload_step2_title_check_result": "步驟2: 檢查結果",
  "mass_upload_step2_no_data_info": "請先確認資料正確性，並且點選 \"檢查並傳送到下表\" ",
  "mass_upload_column_key_check_result": "檢查結果",
  "mass_upload_column_key_upload_type": "上傳類別",
  "mass_upload_column_key_station": "站點",
  "mass_upload_column_key_dept": "部門",
  "mass_upload_column_key_customer": "客戶",
  "mass_upload_column_key_debit_to_vendor": "Debit to/ Vendor",
  "mass_upload_column_key_payment_term": "Payment Term",
  "mass_upload_column_key_sales_code": "Sales Code",
  "mass_upload_column_key_reference_no": "Reference No",
  "mass_upload_column_key_charge_code": "Charge Code",
  "mass_upload_column_key_qty": "數量",
  "mass_upload_column_key_unit": "單位",
  "mass_upload_column_key_rate": "Rate",
  "mass_upload_column_key_rate_currency": "Rate幣別",
  "mass_upload_column_key_rate_amount": "Rate金額",
  "mass_upload_column_key_charge_description": "費用描述",
  "mass_upload_column_key_3rd_station": "3rd 站點",
  "mass_upload_column_key_3rd_dept": "3rd 部門",
  "mass_upload_column_key_3rd_sales_code": "3rd Sales Code",
  "mass_upload_column_error_key_value_can_not_be_blank": "不能為空白",
  "mass_upload_column_error_key_invalid_upload_type_value": "無效的上傳類別",
  "mass_upload_column_error_key_invalid_station_value": "無效的站點",
  "mass_upload_column_error_key_invalid_dept_value": "無效的部門",
  "mass_upload_column_error_key_invalid_customer_value": "無效ㄒ客戶",
  "mass_upload_column_error_key_invalid_debit_to_vendor_value": "無效的Debit to/ Vendor",
  "mass_upload_column_error_key_invalid_payment_term_value": "無效的Payment Term",
  "mass_upload_column_error_key_invalid_sales_code_value": "無效的Sales Code",
  "mass_upload_column_error_key_invalid_reference_no_value": "無效的Reference No",
  "mass_upload_column_error_key_invalid_charge_code_value": "無效的Charge Code",
  "mass_upload_column_error_key_invalid_unit_value": "無效的數量",
  "mass_upload_column_error_key_invalid_rate_currency_value": "無效的Rate幣別",
  "mass_upload_column_error_key_invalid_rate_amount_value": "無效的Rate金額",
  "mass_upload_column_error_key_invalid_3rd_sales_code_value": "無效的3rd Party Sales Code",
  "download_template": "下載模板",
  "upload_file": "上傳檔案",
  "or_paste_data": "或於表格中貼上資料",
  "warning_data_over_write_after_upload": "(如果選擇新檔案，所有已輸入的資料將會被覆蓋)",
  "file": "檔案",
  "total_records": "所有紀錄",
  "total_amount": "總金額",
  "error": "錯誤",
  "checked": "已檢查",
  "check_and_transfer": "檢查並傳送到下表",
  "mass_upload_post_all_to_prolink": "POST到Prolink",
  "mass_upload_post_all_to_prolink_success": "已成功執行，需要跳轉至列表頁面嗎?",
  "check_first_memo": "請先執行\"檢查\"",
  "warning_change_type_clear_data": "改變\"種類\"會清除所有資料，確定嗎?",
  "warning_would_you_like_to_send_to_prolink": "確定要傳送到Prolink嗎?",
  "check_confirm_to_send_to_prolink": "確認",
  "voucher_no": "憑單號碼",
  "invoice_voucher_date": "Invoice/ Voucher Date",
  "acting_station": "操作印表機站點",
  "warning_code_billing_mass_upload_duplicate_row_in_input_data": "與第 %{row_id} 行的 Debit to/vendor, Reference No. 相同，請確認是否重複上傳",
  "warning_code_billing_mass_upload_duplicate_row_in_db": "系統中有相同的 Debit to/ Vendor, Reference No.的帳單 (提單號:%{reference_no}) ，請確認是否重複上傳",
  "value_can_not_be_blank": "不能為空白",
  "invalid_upload_type_value": "無效的上傳類別",
  "invalid_station_value": "無效的站點",
  "invalid_dept_value": "無效的部門",
  "invalid_customer_value": "無效ㄒ客戶",
  "invalid_debit_to_vendor_value": "無效的Debit to/ Vendor",
  "invalid_payment_term_value": "無效的Payment Term",
  "invalid_sales_code_value": "無效的Sales Code",
  "invalid_reference_no_value": "無效的Reference No",
  "invalid_charge_code_value": "無效的Charge Code",
  "invalid_unit_value": "無效的數量",
  "invalid_rate_currency_value": "無效的Rate幣別",
  "invalid_rate_amount_value": "無效的Rate金額",
  "invalid_3rd_sales_code_value": "無效的3rd Party Sales Code",
  "cant_void_voided_voucher": "憑證已作廢，無法再次作廢。",
  "cant_void_voided_invoice": "發票已作廢，無法再次作廢。",
  "cant_void_ap_completed_voucher": "不能作廢 AP 已完成的憑證",
  "cant_void_ap_completed_invoice": "不能作廢 AP 已完成的發票",
  "cant_void_voiding_voucher": "憑證作廢正在處理中，無法再次作廢。",
  "cant_void_voiding_invoice": "發票作廢正在處理中，無法再次作廢。",
  "ap_inv_no": "CW1 AP 發票號碼",
  "cw1_chg_code": "CW1 費用代碼",
  "voucher_source": "來源",
  "source_tx_pk": "CW1 參考編號",
  "source_tx_creator": "創建者",
  "package": "包裹數",
  "rate_per_package": "每包單價",
  "rate_per_kg": "每公斤單價",
  "missing_required": "缺少必填欄位",
  "status": "狀態",
  "action": "操作",
  "allocated_amount": "分攤金額",
  "undefined": ""
 },
 "tms": {
  "alert_at_least_bol": "至少需加一張送貨單(BOL)到派車單(Dispatch)。",
  "alert_at_least_one_bol": "至少選擇一筆送貨單(BOL)。",
  "alert_at_least_one_shipment": "至少需加一筆貨件到送貨單(BOL)。",
  "alert_at_least_one_shipment_per_bol": "至少需加一筆貨件到送貨單(BOL)。",
  "alert_bol_and_dispatch_is_different_carrier_code": "送貨單（BOL/DN）的承運人和派車單（Dispatch）的承運人不同，請重新確認。",
  "alert_cancel_edit_dispatch": "放棄所做的修改嗎 ?",
  "alert_confirm_cancel": "確認要取消嗎?",
  "alert_confirm_delete_dispatch": "確認要刪除派車單(Dispatch)嗎?",
  "alert_confirm_edit_dispatch": "確認要編輯派車單(Dispatch)嗎?",
  "alert_confirm_save_dispatch": "您確認要保存派車單(Dispatch)嗎？",
  "alert_different_carrier_code": "所選貨物的預指派承運人不同。",
  "alert_different_consignee_address": "所選貨物的收貨人地址不同。",
  "alert_different_consignee_id": "所選貨物的收貨人代碼不同。",
  "alert_different_delivery_method": "所選貨件的遞送方式不同。",
  "alert_different_payer": "所選貨物的遞送費用支付方不同。",
  "alert_different_payer_account": "所選貨物的遞送費用支付帳號不同。",
  "alert_different_payer_name": "所選貨物的遞送費用支付方不同。",
  "alert_different_quotation_level": "所選貨件的遞送費用報價層級不同。",
  "alert_have_different_bol_carrier_confirm_go_on": "送貨單(BOL)的承運人不同，是否繼續？",
  "alert_no_dispatch": "沒有派車單(Dispatch)!",
  "alert_no_dispath_bols": "沒有送貨單(BOL)！",
  "alert_required_quotation_fields": "資料未填寫完整，請檢查確認。",
  "accept_quotation_tooltip": "接受報價，報價狀態將更新為\"已接受\"。",
  "actual_carrier": "實際承運人",
  "actual_driver_arrival_time": "實際司機到達時間",
  "actual_out_for_delivery_date": "外出遞送日期",
  "actual_out_for_delivery_time": "外出遞送時間",
  "add_quotation": "新增報價",
  "add_quotation_tooltip": "新增一筆報價",
  "advanced_search": "進階搜尋",
  "agent_role": "H Agent",
  "alert_accept_quotation_fail_do_retry_or_manual_or_return": "系統連線問題無法線上完成接受報價，請選擇\"重試\"、\"手動預訂\"、或者\"返回\"。",
  "alert_accept_quotation_manual": "Morrison系統已完成報價，接下來請記得線外跟承運人下Booking。",
  "alert_are_you_sure_to_cancel_quotation": "您是否要取消報價?",
  "alert_are_you_sure_to_submit_quotation": "您是否要送出報價請求?",
  "alert_assigned_to_driver": "派車狀態已修改為 “15 - 已指派司機”。",
  "alert_cancel_quotaion_confirm": "您是否要取消報價?",
  "alert_cancel_quotation_fail_do_retry_or_manual_or_return": "系統連線問題無法線上完成取消報價，請選擇\"重試\"、\"手動取消報價\"或者\"返回\"?",
  "alert_cancel_quotation_manual": "Morrison系統已取消報價，接下來請記得線外跟承運人取消Booking。",
  "alert_confirm_leave": "未保存的資料將會遺失，請確認。",
  "alert_confirm_not_mobile_save": "輸入的司機號碼不是手機號碼，確認要保存嗎？",
  "alert_confirm_send_sms": "確認發送簡訊到手機 %{number} ?",
  "alert_create_bol_fail": "新增 BOL/ DN 失敗，請重試一次。",
  "alert_custom_value_have_multiple_currency_and_go_on": "貨件的幣別不同，無法加總Custom Value。",
  "alert_document_ready_is_not_ready": "\"出貨文件\"尚未就緒，請確認出貨文件。",
  "alert_fast_create_remove_bol_in_bol_step_warning": "確認要移除送貨單(BOL/ DN)嗎？ 確認後就無法撤銷。",
  "alert_have_different_bol_carrier": "您選擇的貨件，送貨單(BOL/ DN)的\"承運人\"不同，無法加入到同一個派車單(Dispatch)。",
  "alert_have_different_bid_required": "您選擇的貨件，送貨單(BOL/ DN)的\"比價標記\"不同，無法加入到同一個派車單(Dispatch)。",
  "alert_have_different_bol_payment_to_carrier_by_and_go_on": "您選擇的貨件，\"遞送費用預設方\"不同，確認要加入同一個派車單(Dispatch)嗎?",
  "alert_have_different_bol_station": "您選擇的貨件，\"送貨單(BOL/ DN)站點\"不同，無法加入到同一個派車單(Dispatch)。",
  "alert_have_different_delivery_method": "您選擇的貨件，送貨單(BOL/ DN)的\"遞送方式\"不同，無法加入到同一個派車單(Dispatch)。",
  "alert_have_different_ship_mode": "您選擇的貨件，\"運輸模式\"不同。無法加入到同一個派車單(Dispatch)。",
  "alert_no_quotation": "送貨單(BOL/ DN)報價尚未就緒，若要繼續請先完成報價。",
  "alert_out_for_delivery": "派車單(Dispatch) %{dispatchNumber} 狀態為“20 - 外出遞送”。",
  "alert_planned_out_for_delivery_at": "預計外出遞送時間",
  "alert_print_label_confirm": "是否列印標籤?",
  "alert_required_all_fields": "欄位資料有誤，請檢查後再送出。",
  "alert_save_success": "更新成功",
  "alert_selected_shipments_need_add_to_bol": "尚有貨件未被加入到任何送貨單(BOL/ DN)中，請先完成加入。",
  "alert_send_sms": "簡訊發送成功!",
  "alert_submit_quotation_fail_do_retry_or_manual_or_return": "是否繼續重試，返回，手動預訂？",
  "alert_submit_quotation_manual": "請記得跟承運人完成預訂。",
  "alert_are_you_sure_to_accept_quotation": "您是否要接受報價？",
  "already_has_quotation_cannot_edit_address": "貨件已送達或者送貨單(BOL/ DN)已有報價，無法編輯地址。",
  "already_has_quotation_cannot_edit_shipment": "貨件已送達或者送貨單(BOL/ DN)已有報價，無法新增或移除貨物。",
  "actual_appointment_date": "Actual Appt Date",
  "appointment_confirmed_date": "Appt Confirm Date",
  "appointment_made_date": "Appt Made Date",
  "are_you_sure_to_change_bid_required": "所有送貨單(BOL/ DN)的比價標記都將被修改，如已接受承運人報價，將一併取消，送貨單(BOL/ DN)和派車單(Dispatch)需要重新確認，確認要修改嗎?",
  "are_you_sure_to_change_delivery_method": "所有送貨單(BOL/ DN)的遞送方式都將被修改，如已接受承運人報價，將一併取消，送貨單(BOL/ DN)和派車單(Dispatch)需要重新確認，確認要修改嗎？",
  "are_you_sure_to_change_carrier_code": "所有送貨單(BOL/ DN)的承運人都將被修改，如已接受承運人報價，將一併取消，送貨單(BOL/ DN)和派車單(Dispatch)需要重新確認，確認要修改嗎？",
  "assistant_driver_1_name": "隨車人員一",
  "assistant_driver_2_name": "隨車人員二",
  "bid_required": "比價",
  "bid_required_tooltip": "出運前進行比價",
  "bol": "送貨單(BOL)詳情",
  "bol_booking_status_is_completed_please_cancel_booking": "此送貨單(BOL)已完成報價，請在刪除送貨單之前先取消跟承運人的報價。",
  "bol_carrier": "承運人",
  "bol_delivery_method": "遞送方式",
  "bol_details": "送貨單(BOL)詳情",
  "bol_dn_carrier": "承運人",
  "bol_dn_delivery_date": "到貨日期",
  "bol_dn_delivery_from": "預期遞送時間",
  "bol_dn_form": "送貨單 (BOL)",
  "bol_dn_no": "送貨單 (BOL) 號碼",
  "bol_dn_pod_from": "POD時間(起)",
  "bol_dn_shipment_mode": "運輸方式",
  "bol_dn_shipto_state": "州/省",
  "bol_dn_station": "站點",
  "bol_dn_type": "送貨單(BOL)類型",
  "bol_edit": "送貨單(BOL)編輯",
  "bol_grouping_rule": "貨物合併到送貨單(BOL)規則",
  "bol_indicator": "送貨單(BOL)指標",
  "bol_mgmt": "送貨單(BOL)管理",
  "bol_new": "新建送貨單(BOL)",
  "bol_no": "送貨單(BOL)號碼",
  "bol_status": "送貨單(BOL)狀態",
  "bol_status_update": "送貨單(BOL)狀態更新",
  "booking_date": "預訂",
  "booking_status": "報價狀態",
  "cancel_dispatch": "將貨件從派車單移除",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "送貨單(BOL) %{bol_number} 若已接受報價，刪除送貨單(BOL/ DN)將同時取消報價，確認要刪除送貨單(BOL/ DN)嗎？",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "派車單(Dispatch)%{dispatchnumber} 若已接受報價，刪除派車單(Dispatch)將同時取消報價，確認要刪除送貨單（BOL）嗎？",
  "cancel_quotation_tooltip": "取消已經接受的報價",
  "carrier": "承運人",
  "carrier_service_level": "承運人服務",
  "column_adjustment": "欄位調整",
  "comment_category": "註釋類別",
  "complete_time": "完成時間",
  "confirm_complete": "確認已完成",
  "cannot_be_empty": "不可為空",
  "consol_closed_at": "Console Close",
  "creation_date": "建立日期",
  "delivery_date": "POD 時間",
  "delivery_method": "遞送方式",
  "delivery_method_tooltip": "Express 以依送貨單報價，LTL/FTL 以派車單報價。",
  "delivery_no": "Delivery No",
  "destination_doc_status": "目的站檔案狀態",
  "dispatch_delivery_method": "派車單遞送方式",
  "dispatch_header": "派車單(Dispatch)表頭",
  "dispatch_no": "派車單(Dispatch)號碼",
  "dispatch_station": "站點",
  "dispatch_type": "派車單類型",
  "document_ready_tooltip": "出運前請確認所需文件已經就緒",
  "error_you_have_different_dispatch_no": "所選的多個貨件有超過一個以上的派車單(Dispatch)，請重新選擇。",
  "exception": "例外",
  "exception_message": "例外訊息",
  "exception_number": "例外訊息筆數",
  "fcr": "FCR",
  "fcr_c": "子單",
  "fcr_n": "一般提單",
  "fcr_y": "母單",
  "forwarder_cargo_receipt": "貨運承攬收據",
  "grouping_rule_a": "收貨人地址相同的貨件合併到一個送貨單",
  "grouping_rule_b": "收貨人代碼相同和收貨人地址相同的貨件合併到一個送貨單",
  "grouping_rule_c": "發貨人代碼相同和收貨人代碼相同的貨物合併到一個送貨單",
  "grouping_rule_d": "發貨人、收貨人和收貨人地址相同的貨件合併到一個送貨單",
  "grouping_rule_e": "一個貨件一個送貨單",
  "gw": "毛重",
  "hawb_no": "HAWB / HBL",
  "input_error_code": "請輸入錯誤代碼",
  "instruction": "特殊指示",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "logno": "LOG No",
  "manual": "手動",
  "master_agent_role": "Consol Agent",
  "misc_docs": "其他檔案",
  "ofd_time": "外出遞送時間",
  "off_load_at": "Offload",
  "open_delivery_status_update": "更新遞送狀態",
  "open_handover_edit": "交接貨件",
  "out_for_delivery_date": "外出遞送",
  "pallet_status": "進口棧板狀態",
  "payment_method": "付款方式",
  "pickup_date_over_arrival_date": "預計司機抵達倉庫時間必須早於送貨單(BOL)的要求送達時間",
  "pkg_qty": "包裹數",
  "pkg_unit": "包裝類型",
  "planned_driver_arrival_time": "預計司機到達倉庫時間",
  "planned_driver_time_tooltip": "預計承運人到倉庫的時間",
  "planned_out_for_delivery_time": "預計外出遞送時間",
  "pod": "POD 文件",
  "pod_checked": "POD 已檢核",
  "pod_date": "POD 日期",
  "pod_file": "POD 文件",
  "preassigned_carrier_updated_at": "指派承運人時間",
  "preassigned_carrier_updated_by": "指派人",
  "primary_driver_license_no": "駕駛證號",
  "primary_driver_license_number": "駕駛證號",
  "primary_driver_name": "司機",
  "primary_driver_phone_no": "司機手機",
  "primary_driver_phone_number": "司機手機",
  "print": "列印",
  "print_bol": "列印送貨單(BOL)",
  "print_label_tooltip": "接受線上報價後可列印標籤",
  "print_manifest": "列印派車單(Dispatch)",
  "quotation": "報價編號",
  "quotation_is_accepted": "報價已接受",
  "quotation_is_submitted": "報價已提交",
  "quotation_status": "報價狀態",
  "reference_files": "參考檔案",
  "retry": "重試",
  "rule_a": "Rule A",
  "rule_b": "Rule B",
  "rule_c": "Rule C",
  "rule_d": "Rule D",
  "rule_e": "Rule E",
  "sharp_bols": "送貨單(BOL)數",
  "sharp_deliveries": "Delivery數",
  "sharp_gross_weight": "毛重",
  "sharp_gw": "毛重",
  "sharp_loading_meter": "#LDM",
  "sharp_packages": "包裹數",
  "sharp_pkgs": "包裹數",
  "sharp_shipments": "貨件數",
  "sharp_shpts": "貨件數",
  "sharp_chargeable_weight": "付費重量",
  "ship_from": "起運",
  "ship_to_city": "收貨城市",
  "ship_to_country": "收貨國家/地區",
  "ship_to_name": "收貨方",
  "ship_to_state": "收貨州/省",
  "shipment_customer": "客戶",
  "shipment_mode": "運輸方式",
  "shipment_no": "HAWB / HBL",
  "special_instruction_max_length_tooltip": "最多可輸入150個字元",
  "station": "站點",
  "status": "狀態",
  "submit_quotation_tooltip": "送出報價請求",
  "tag_api_manual": "線下",
  "tag_api_manual_tooltip": "線下報價",
  "tag_quotation_accepted": "就緒",
  "tag_quotation_accepted_tooltip": "報價已就緒",
  "tag_quotation_not_accepted": "未就緒",
  "tag_quotation_not_accepted_tooltip": "報價未就緒",
  "to_address": "收貨地址",
  "to_city": "收貨城市",
  "to_country": "收貨國家/地區",
  "to_name": "收貨人",
  "to_state": "收貨州/省",
  "tracking_device": "追蹤器號碼",
  "tracking_device_no": "追蹤器號碼",
  "tracking_device_number": "追蹤器號碼",
  "tracking_no": "追蹤號碼",
  "trailer_load_by": "拖車裝載",
  "trailer_no": "拖車號碼",
  "truck": "卡車",
  "truck_no": "車牌",
  "truck_type": "車型",
  "type": "類型",
  "unlink_bol": "你確定要把貨件從派車單移出嗎？",
  "urgent_delivery": "急件",
  "urgent_delivery_tooltip": "急件送貨",
  "vip_name": "客戶",
  "verification_code": "驗證碼",
  "alert_check_customer_pickup_shipments": "“你選擇了包括 %{shipmentCounts} 個具有“To-Poor”服務類型的貨件。（ATA 或 DTA）\n包括 HAWB：%{shipmentNumbers}。\n請在進入派單創建之前將服務類型更改為“To-Door”類型（ATD 或 DTD）。”",
  "alert_check_customer_pickup_before_check_in_counter": "“你選擇了包括 %{shipmentCounts} 個具有“To-Port”服務類型（ATA 或 DTA 或 PTP 或 DTP 或 ATP 或 PTA）的貨件。\n包括 HAWB：%{shipmentNumbers}。\n請在指定承運人之前將服務類型更改為“To-Door”類型（ATD 或 DTD 或 PTD）。”",
  "alert_invalide_customer_shipments": "Apple GAR、Garmin、Gap 貨件不能與其他客戶貨件一起派車",
  "warning_has_dispatch_shipments": "“警告：\n\n你選擇了包括 %{shipmentCounts} 個貨件已經有 OFD/POD。\n系統將會透過 TMS 和 ePOD 更新這些貨件的 OFD 和 POD 時間戳和 POD 文件。\n包括 HAWB：%{shipmentNumbers}。\n\n您要繼續創建送貨單(BOL)和派車單(Dispatch)嗎？”",
  "warning": "警告",
  "warning_unable_to_change_appt": "無法更改以下 Delivery 的 Actual Appt Date 和/或 EDD。 請點擊前往 TRCK 更改 Actual Appt Date 和/或 EDD。",
  "service_type_change_to": "服務類型更改為",
  "delivery_method_change_to": "遞送方式更改為",
  "change_service_type": "更改服務類型",
  "original_service_type": "原有的服務類型",
  "new_service_type": "新的服務類型",
  "original_delivery_method": "原有的遞送方式",
  "new_delivery_method": "新的遞送方式",
  "error_add_fast_create_dispatch": "你不能選擇多個派車單",
  "allocation_sum_check_not_match": "請按計算按鈕或手動把金額調整至與加總金額一致",
  "allocation_not_save": "分攤結果未保存",
  "allocation_has_posted_allocation": "已在 Prolink/CW1 中創建了 PO，加入新的貨件前請先請取消已經創建的 PO。",
  "allocation_cant_remove_delivery_with_posted_allocation": "已在 Prolink/CW1 中創建了 PO，移出貨件前請先請取消已經創建的 PO。",
  "change_delivery_package_data": "修改遞送包裹數據",
  "third_party": "第三方",
  "new_delivery_need_new_allocation": "添加新貨件時 PO Request 需要重新分攤成本",
  "confirm_is_delete_delivery_allocation": "所有 PO Request 將以 GW 重新計算。 如有需要，你可以修改 PO Request 分攤明細。",
  "cancel_allocation_failed": "取消 採購訂單分攤失敗",
  "reference_number": "Ref",
  "estimated_delivery_date": "EDD",
  "customs_cleared_at": "出口清關時間",
  "split_delivery_tooltip": "分拆 Delivery",
  "split_delivery_title": "分拆: HAWB#:%{shipment_number} Delivery#:%{delivery_number}",
  "selected_packages": "選中的包裹",
  "estimated_gw": "估計的毛重",
  "estimated_cw": "估計的付費重量",
  "selected_pallets_packages_will_be_split_to_a_new_delivery": "選擇的 pallets/packages 將被分拆為新的 Delivery#.",
  "remaining_packages": "剩餘的包裹數",
  "remaining_gw": "剩餘的毛重",
  "remaining_cw": "剩餘的付費重量",
  "split_pkg_qty": "分拆的包裹數量",
  "split_pkg_unit": "分拆的包裹類型",
  "split_gw": "分拆的毛重",
  "split_cw": "分拆的付費重量",
  "selected_package_unit_and_weight_will_be_split_to_a_new_delivery": "你選擇的包裹數量、包裹類型和重量將被分拆為新的 Delivery#。",
  "location": "位置",
  "pallet_id_package_id": "Pallet ID/Package ID",
  "hawb_allocated_amount": "HAWB 被分配的金額",
  "hawb_allocated_chargeable_weight_rate": "HAWB 被分配的付費重量費率",
  "upload_proof_of_handover": "上傳交接文件",
  "deliveries_have_been_assigned_to_BOL": "%{assignedDeliveries}/%{totalDeliveries} Deliveries 已分配給送貨單（BOL）",
  "deliveries_have_not_been_assigned": "%{notAssignedDeliveries} Deliveries 尚未分配給送貨單（BOL）",
  "different_consignee_address": "%{deliveryNumber} 的收貨地址不同.",
  "alert_update_pod_time_and_pod_file": "請更新 POD 時間並在 Delivery Status Update 中為新添加的 BOL 上傳 POD 文件。",
  "managed_in_cfs_tooltip": "在 CFS 中管理",
  "appointment_confirmed_number": "Appt Confirm No",
  "original_actual_appointment_date": "原始 Actual Appt",
  "new_actual_appointment_date": "新的 Actual Appt",
  "original_estimated_delivery_date": "原始 EDD",
  "new_estimated_delivery_date": "新的 EDD",
  "estimated_delivery_date_sla": "SLA",
  "print_pallet_label": "列印收貨人托盤標籤",
  "print_pallet_label_failed": "列印收貨人托盤標籤失敗",
  "department_of_transportation_number": "US DOT No",
  "motor_carrier_number": "US MC No",
  "go_to_trck": "去 TRCK",
  "billing_country_code": "國家代碼",
  "billing_postal_code": "郵遞區號",
  "bol_and_manifest": "送貨單和派車單",
  "cnee_pallet_label": "收貨人托盤標籤",
  "should_print_pallet_label": "貨件 %{shipmentList} 需要列印收貨人托盤標籤，請在貨物交接給司機前列印收貨人托盤標籤。",
  "notify_id": "通知方代碼",
  "notify_name": "通知方",
  "notify_address": "通知方地址",
  "notify_city_name": "通知方城市",
  "notify_state": "通知方州/省",
  "notify_country": "通知方國家/地區",
  "tracking_status_p44_inactive": "P44即將追蹤",
  "tracking_status_p44_active": "P44追蹤中",
  "tracking_status_p44_completed": "P44追蹤結束",
  "tracking_status_p44_invalid_data": "P44追蹤無效",
  "tracking_status_p44_request_processing": "P44正在處理追蹤請求",
  "tooltip_tracking_status_p44_inactive": "追蹤即將開始",
  "tooltip_tracking_status_p44_active": "追蹤正在進行",
  "tooltip_tracking_status_p44_completed": "追蹤已結束",
  "tooltip_tracking_status_p44_invalid_data": "追蹤無效",
  "tooltip_tl_type": "當一批貨物重量或容積裝滿一輛貨車時，稱整車貨運(truckload, TL)。當一批貨物的重量或容積不滿一輛貨車時，可與其他幾批甚至上百批貨物共用一輛貨車裝運時，稱零擔貨運(less-than- truckload, LTL)",
  "tooltip_license_plate_no": "車牌號碼，官方交通單位對道路車輛所發出的唯一編碼，掛在車輛的前後",
  "tooltip_fleet_vehicle_id": "車隊號碼，由商用車隊公司對旗下車輛配給唯一資產編號，一般顯示在(拖車)車頭的車門",
  "truck_info": "卡車資訊",
  "license_plate_no": "車牌號碼",
  "tl_type": "TL Type",
  "tracking_status": "追蹤狀態",
  "dispatch_status": "派車單狀態",
  "fleet_vehicle_no": "車隊號碼",
  "alert_fleet_vehicle_required": "This is a P44-enabled truck vendor, but there is no Fleet Vehicle No provided. Do you want to proceed?",
  "alert_address_not_valid": "某些送貨地址可能無效，您要繼續嗎？",
  "bol_status_passed": "通過檢查！",
  "bol_status_address_error": "地址驗證失敗 - %{errorReason} 如果需要，請檢查並修改“地址”、“城市”、“州省”和“郵政編碼”。",
  "truck_tracking_processing_reason": "P44 Tracking establishment is still in progress, please refresh again later.",
  "truck_tracking_success": "P44 Tracking established successfully, P44 Tracking Status is Active",
  "modal_truck_tracking_error_reason": "P44 Tracking is still %{status} - %{reason}. Please check and revise data.",
  "truck_tracking_error_reason": "追蹤錯誤原因",
  "truck_tracking_error_suggestion": "如果您想在沒有 P44 跟踪的情況下繼續列印 PDF，請聯繫超級用戶。",
  "truck_tracking_reminder_message": "Please make sure Tracking Device is activated and placed on a pallet.",
  "tracking_p44_dispatch_required_message": "Either SCAC, DOT No or MC No field is required.",
  "error_required_fleet_vehicle_number": "請與車隊核對並輸入用於 P44 追蹤的車隊號碼",
  "tracking_p44_bol_delivery_date_required": "檢查失敗：要求送達時間必填",
  "tracking_p44_bol_delivery_date_not_match_planned": "檢查失敗：無效的要求送達時間，應晚於司機預計到達時間",
  "tracking_p44_bol_pdat_must_earlier_than_tdt": "檢查失敗: 司機預計抵達倉庫時間必須早於要求送達時間",
  "tracking_p44_bol_delivery_date_not_from_future": "檢查失敗：無效的要求送達時間，應為未來時間",
  "tracking_p44_bol_delivery_date_passed": "檢查通過：有效的要求送達時間",
  "tracking_p44_bol_delivery_all_passed": "檢查通過",
  "tracking_p44_bol_delivery_date_invalid_range": "檢查失敗：無效的要求送達時間區間",
  "tracking_p44_bol_delivery_time_earlier_than_current": "要求送達時間早於當前時間，請仔細檢查後錄入",
  "alert_bol_has_error": "送貨單（BOL）資料有問題，請返回修改。",
  "alert_bol_has_warning": "送貨單（BOL）資料有問題，您還要繼續嗎？",
  "address_floor": "樓層/房間/單元",
  "p44_permission_confirm": "我確認跳過建立 P44 追蹤，先產生派車文件。",
  "fleet_vehicle_number_placeholder": "輸入車隊號碼用於 P44 追蹤...",
  "part_number_list": "零件號",
  "product_description_list": "產品描述",
  "freight_charge_terms": "運費條款",
  "freight_charges_collect": "到付",
  "freight_charges_prepaid": "預付",
  "freight_charges_third_party": "第三方",
  "freight_counted_by": "貨物計數",
  "freight_counted_driver_pallets": "Driver/pallets said to contain",
  "freight_counted_driver_pieces": "Driver/Pieces",
  "freight_counted_shipper": "託運人",
  "freight_term": "付款條款",
  "from": "起運",
  "ftl": "FTL",
  "preassigned_carrier": "預先指定承運人",
  "date_range_error": "日期區間有誤",
  "must_be_before_the_end_time": "需早於結束時間",
  "must_be_after_the_start_time": "需晚於開始時間",
  "planned_driver_arrival_time_is_earlier_than_current": "早於當前時間，請仔細檢查",
  "delivery_date_range_error": "指定到貨日期應晚於司機預計到達時間",
  "fast_dispatch_bol_close_error_message": "欄位資料有誤，修正後即可折疊卡片。",
  "recalculate_po_request_with_allocation_method": "執行當前操作後，該派車單已經建立的PO Request(s) 將會以先前的分攤方式重新計算 -",
  "recalculate_po_request_with_no_allocation_method": "執行當前操作後，該派車單已經建立的PO Request(s) 將會以毛重的分攤方式重新計算。",
  "are_you_sure_to_change_vendor_code": "所選的廠商代碼與此派車單的承運人不符，你確定要繼續嗎 ?",
  "please_double_confirm": "請在動作完成後再次檢查PO Request(s)的金額分攤",
  "recalculate_po_request_with_allocation_method_period": "。",
  "new_delivery_has_zero_value": "分攤金額 = 0，您確認要保存嗎 ?",
  "new_delivery_has_zero_value_tooltip_text": "請確認分攤金額是否確實為 0",
  "po_request_status_pending": "待處理",
  "po_request_status_processing": "處理中",
  "po_request_status_error": "錯誤",
  "po_request_status_request_to_pl_po_ok": "在 Prolink 建立 PO",
  "po_request_status_request_to_cw1_po_ok": "在 CW1 建立 PO",
  "po_request_status_cw1_ap_completed": "PO 已在 CW1 轉為 AP",
  "po_request_status_pending_explaination": "一旦派車單完成遞送（30-已送達），PO Request 將發送到 Prolink/CW1。",
  "po_request_status_processing_explaination": "PO Request 已發送到 Prolink/CW1，應在 Dispatch 完成遞送後 2小時內完成同步，否則請聯絡 mec_onehelp@morrisonexpress.com 處理",
  "po_request_status_error_explaination": "PO Request 有錯誤，請聯絡 mec_onehelp@morrisonexpress.com 處理",
  "po_request_status_request_to_pl_po_ok_explaination": "已在 Prolink 建立 PO",
  "po_request_status_request_to_cw1_po_ok_explaination": "已在 CW1 建立 PO",
  "po_request_status_cw1_ap_completed_explaination": "PO 已在 CW1 轉為 AP",
  "delivery_allocated_amount_tips": "TMS 卡車送貨費用（Delivery 合計）請求，任何在 Prolink 或者 CW1 的修改不會反映在此。",
  "delivery_allocated_amount": "Delivery 分攤費用",
  "actual_pickup_at": "提貨時間",
  "eta": "預計到達時間",
  "etd": "預計出發時間",
  "atd": "實際出發時間",
  "ata": "實際到達時間",
  "doc_released_at": "放單時間",
  "customs_released_at": "進口清關時間",
  "arrived_cfs_at": "抵達目的地 CFS 時間",
  "actual_delivery_date": "POD 時間",
  "country_code_exceed_limitation": "2碼國家代碼",
  "invalid_carrier_code": "由於該承運人代碼無效，ePOD 登入簡訊將不會發送到司機的手機。請司機透過掃描派車文件上的 QRcode 來登入 ePOD",
  "carrier_sms_disabled": "由於該承運人的簡訊功能暫時關閉，ePOD 登入簡訊將不會發送到司機的手機。請司機透過掃描派車文件上的 QRcode 來登入 ePOD",
  "refresh_data": "更新資料",
  "sync_from_prolink": "正在進行資料同步",
  "generate_dn": "Generate DN",
  "generate_dn_max_error": "Can not exceed %{max} item to generate.",
  "generate_dn_warning": "Selected HAWB is Taobao shipment. Do you want to create DN by Taobao consignee?",
  "generate_dn_leave_message": "尚有正在編輯的DN，確定要離開？",
  "generate_dn_upload_modal_title": "Upload Bag ID",
  "generate_dn_upload_modal_tips": "Please copy excel data and paste on sheet below.",
  "generate_dn_upload_modal_consignee": "Consignee",
  "generate_dn_upload_modal_bag_id": "Bag ID",
  "generate_dn_upload_success": "Upload successfully",
  "generate_dn_upload_fail": "上傳失敗",
  "import_pallet_status": "Import Pallet Status",
  "planned_driver_arrival_date": "Planned Driver Arrival Date",
  "delivery_not_found": "Delivery 資料缺失",
  "vip_name_is_not_top_partner_express": "勾選項目包含無效Customer: TOP_PARTNER_EXPRESS only",
  "delivery_status_is_not_unassigned": "勾選項目包含無效狀態: 00-Initial only",
  "exceed_the_number_of_deliveries_limit": "無法勾選超過10個項目",
  "please_select_at_least_one_consignee": "Please select at least one consignee",
  "available_for_delivery": "Available For Delivery",
  "please_update_ofd_above_before_updating_pod_time": "在更新簽收時間之前，請先在上方更新外出遞送時間",
  "please_go_to_handover_to_input_the_drivers_infomation_before_updating_ofd": "在更新外出遞送時間(OFD Time)之前，請先前往 Handover 輸入司機資訊",
  "error_pod_datetime_not_future_time": "簽收時間晚於當前時間，請仔細檢查後再錄入。",
  "error_pod_datetime_earlier_than_ofd_time": "早於外出遞送時間，請仔細檢查後再錄入。",
  "error_ofd_datetime_not_future_time": "晚於當前時間，請仔細檢查後再錄入。",
  "handover_submit_error_ofd_earlier_than_adat": "外出遞送時間早於司機實際到達時間，請仔細檢查後再錄入",
  "handover_submit_error_ofd_later_than_current": "晚於當前時間，請仔細檢查後再錄入",
  "handover_submit_error_ofd_later_than_pod": "簽收時間早於外出遞送時間，請仔細檢查後再錄入",
  "handover_submit_error_pod_later_than_current": "簽收時間晚於當前時間，請仔細檢查後再錄入",
  "undefined": ""
 },
 "bk": {
  "query_failed": "請嘗試重試或者刷新頁面。若仍然無法查詢，請聯絡 mec_onehelp@morrisonexpress.com。",
  "create_csv_failed": "匯出失敗，請刷新頁面並重試。若仍然無法匯出，請聯絡 mec_onehelp@morrisonexpress.com。",
  "data_not_found": "未預期的錯誤 (查無資料)。請聯絡 mec_onehelp@morrisonexpress.com。",
  "company_id_error": "使用者公司代碼錯誤",
  "stage_error": "操作階段錯誤",
  "cancel_reason_error": "刪除原因錯誤",
  "query_parameter_error": "請求參數錯誤",
  "ods_api_failed": "call ods 出錯",
  "field_verify_error": "發貨人資訊尚未確認，請點選地址區段小鉛筆圖示，將發貨人資訊中的\"將在稍後提供\"取消勾選。",
  "pre_assign_hawb_num_can_not_be_used": "您輸入的預分配 HAWB/HBL 已被使用過，請選擇一個新的號碼",
  "booking_request_submit_error": "訂艙委託單發送失敗，請刷新頁面並重試。若仍然無法發送，請聯絡 mec_onehelp@morrisonexpress.com。",
  "cs_not_found": "我們將盡快為您選擇的路線提供線上訂艙服務，在此之前請選擇其他起運港或聯絡您的業務人員。",
  "hash_error": "hash code 不符合",
  "booking_request_can_not_find_customer_code": "此訂艙委託單找不到對應的客戶代碼，請聯絡 mec_onehelp@morrisonexpress.com。",
  "status_is_completed": "此訂艙委託單已完成，您無法進行此操作。",
  "status_is_canceled": "此訂艙委託單已被取消，您無法進行此操作。",
  "task_api_failed": "未預期的錯誤 (呼叫 Task API)。請聯絡 mec_onehelp@morrisonexpress.com。",
  "common_api_failed": "未預期的錯誤 (呼叫 Common API )。請聯絡 mec_onehelp@morrisonexpress.com。",
  "not_allowed_to_modify_after_submission": "出口站與服務類型在送出訂艙單後無法修改，請取消此訂艙單後重新建立。",
  "sec_agent_not_existed": "Secure agent 不存在，請聯絡業務確認報價單內容是否正確",
  "secure_station_not_mapped": "Secure station 不存在，請聯絡業務確認報價單內容是否正確",
  "duplicate_reference_value": "Shipment 參考值重複，請檢察您所輸入的參考值",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未儲存的資料將遺失，您確定要離開嗎?",
  "draft_save_success": "訂艙委託單草稿儲存成功",
  "booking_request_submit_success": "訂艙委託單已送出",
  "booking_request_confirm_success": "訂艙單建立成功，您可在 20 分鐘後至 Prolink/Cargo Wise 繼續操作此筆訂艙單",
  "booking_request_cancel_success": "訂艙委託單取消成功",
  "address": "地址資訊",
  "approved": "已核可",
  "billing_info": "帳單資訊",
  "bill_of_lading_type": "House 提單種類",
  "booking": "訂艙單",
  "booking_number_name": "訂艙單號碼",
  "booking_request_no": "訂艙委託單號碼",
  "booking_request_number": "訂艙委託單號碼",
  "booking_status_canceled": "已取消",
  "booking_status_completed": "已完成",
  "booking_status_draft": "草稿",
  "booking_status_in_progress": "訂艙完成",
  "booking_status_submitted": "已送出",
  "booking_status_booking_in_progress": "訂艙中",
  "brokerag_and_cargo": "附加服務",
  "calculated_by": "計算方式",
  "calculated_by_total": "依總裝運量計算",
  "calculated_by_unit": "依個別貨物加總",
  "cancel_at_utc": "取消訂艙日期 (UTC)",
  "cancel_by": "取消訂艙人",
  "cancel_check": "是否確定要取消 %{booking_id}?",
  "cancel_notify_check": "Morrison 正在處理您的訂單 %{booking_id}，若取消可能會有部分費用發生，我們將盡速確認費用後與您聯繫，在此之前您的訂單狀態仍會維持在處理狀態，是否確定取消?",
  "cancel_notify_cs": "我們已通知負責的人員，會在最短時間內聯絡您",
  "cargo_insurance": "貨物運輸保險",
  "cargo_ready_date": "貨好時間",
  "city": "城市",
  "client_id": "客戶代碼",
  "client_name": "客戶名稱",
  "clone": "複製",
  "code": "代碼",
  "commercial_invoice": "商業發票",
  "comments": "留言",
  "commodity": "貨物類別",
  "commodity_code": "貨物代碼",
  "commodity_desc": "貨物描述",
  "confirm_at_utc": "Morrison 確認日期 (UTC)",
  "confirm_by": "Morrison 確認者",
  "consignee": "收貨方",
  "consignee_code": "收貨方代碼",
  "consignee_info": "收貨方地址",
  "consignee_name": "收貨方",
  "contact_name": "聯絡人",
  "container_type": "貨櫃類型",
  "country": "國家/地區",
  "create_booking": "建立訂艙單",
  "created_at_utc": "創建日期 (UTC)",
  "created_by": "訂艙委託單創建者",
  "custom_brokerage": "報關服務",
  "customer_code": "客戶代碼",
  "customer_code_name": "客戶代碼/名稱",
  "customer_expected_pickup_at": "預計可提貨時間",
  "customer_expected_at": "期望送達時間",
  "customer_name": "客戶名稱",
  "dangerous_goods_declaration": "物質安全資料表",
  "delivery_port": "交貨港",
  "department": "部門",
  "dest": "進口站",
  "destination": "進口站",
  "destination_port_country": "進口站國家/地區",
  "destination_port_full": "進口站",
  "details": "詳情",
  "diff_dest_area_notify": "你選擇的收貨人國家與進口站的國家不同",
  "diff_origin_area_notify": "你選擇的託運人國家與出口站的國家不同",
  "direct_shipment": "直單",
  "direct_shipment_desc": "若您選擇直走單，Tracking Number (提單號碼) 會透過 Booking confirmation 回覆您，在此之前您可用 Booking 號碼追蹤處理進度",
  "discharge": "卸貨港",
  "documents": "文件",
  "effective_from": "有效日(起)",
  "effective_to": "有效日(迄)",
  "expected_eta": "期望送達時間",
  "expected_eta_note": "Morrison 會盡力達到您的期望，但不保證一定可滿足",
  "expected_pickup_date": "預計可提貨時間",
  "expected_pickup_date_note": "期望提貨日期必須等於貨晚於貨好時間",
  "fcl": "整櫃 (FCL)",
  "fcr_shipment": "FCR 貨件",
  "filed": "已歸檔",
  "freight_payment_term": "運費付款條款",
  "freight_term": "付款條款",
  "general": "普通貨品",
  "general_shipment_desc": "貨物描述",
  "goods_description": "貨物描述",
  "goods_info": "貨物資訊",
  "goods_type": "貨物種類",
  "gross_weight": "毛重",
  "gross_weight_total": "總毛重",
  "incoterm": "Incoterm",
  "insurance_value": "貨物價值",
  "insurance_value_currency": "貨物價值幣別",
  "is_custom_service": "由 Morrison 報關",
  "dangerous_goods": "危險貨品",
  "is_doc_ready": "文件就緒",
  "is_effective": "報價單是否有效",
  "is_insurance": "由 Morrison 保險",
  "is_origin_cert": "產地證明",
  "lcl": "併櫃貨(LCL)",
  "load": "裝貨港",
  "loads": "貨型",
  "loose_goods": "散貨",
  "method": "裝運方式",
  "mode": "運輸方式",
  "name": "名稱",
  "need_carge": "是否需要貨物運輸保險?",
  "need_carge_no": "否 - 我已購買保險/我對任何丟失或損壞的貨物承擔全部責任",
  "need_carge_yes": "是 - 我希望 Morrison 協助購買貨物運輸保險，我的貨物價值:",
  "not_sure_right_now": "將在稍後提供",
  "notify": "通知資訊",
  "notify_info": "通知方地址",
  "notify_name": "通知方",
  "origin": "出口站",
  "origin_cert_memo": "產地證明備註",
  "origin_port": "出口站",
  "origin_port_country": "出口站國家/地區",
  "other_reference_file": "其他參考文件",
  "others_payment_term": "其他費用付款條款",
  "others_term": "其他費用付款條款",
  "overwrite_confirm_title": "變更所選的發貨方/收貨方代碼?",
  "overwrite_confirm_content": "更改發貨方/收货方代碼，報價單將重新查詢，請確認報價單號碼與內容。",
  "overwrite_contact_code_title": "變更所選報價單?",
  "overwrite_contact_code_content": "更改報價單後，將會以報價單中的發貨方/收貨方代碼覆蓋原本的發貨方/收貨方，請檢查代碼正確性或手動選擇所需代碼。",
  "overwrite_quotation_confirm_title": "變更所選的發貨方/收貨方代碼?",
  "overwrite_quotation_confirm_content": "更改發貨方/收货方代碼，報價單將重新查詢，請確認報價單號碼與內容。",
  "packing_list": "裝箱單",
  "part_description": "部品描述",
  "part_number": "部品編號",
  "parts": "部品",
  "per_height": "高 (CM)",
  "per_length": "長 (CM)",
  "per_weight": "寬 (CM)",
  "pending": "待審核",
  "pickup": "提貨資訊",
  "pickup_info": "提貨地址",
  "pickup_name": "提貨方",
  "po_item_no": "採購單項目號碼",
  "po_number": "採購單號碼",
  "port_of_delivery": "交貨港",
  "port_of_discharge": "卸貨港",
  "port_of_landing": "裝貨港",
  "port_of_receipt": "收貨港",
  "pre_assign": "Morrison 提供之可預先指派的 HAWB/HBL 號碼",
  "pre_assign_number": "預先指派 HAWB/HBL 號碼",
  "pre_assign_desc": "請先向 MEC取得預先分配給您的單號，且不不得重複使用相同號碼",
  "qty_ea": "數量(每件)",
  "qty_of_outers": "數量(外包裝)",
  "quantity": "數量",
  "quotation": "報價單",
  "quotation_number": "報價單號碼",
  "quotation_status": "報價單狀態",
  "quotation_type": "報價單類別",
  "range_in": "範圍 %{start}~%{end}",
  "receipt_port": "收貨港",
  "reference": "參考值",
  "reference_number": "參考值",
  "request_brokerage": "是否需要報關服務?",
  "request_brokerage_no": "否 - 我會自行處裡報關事宜",
  "request_brokerage_yes": "是-我希望 Morrison 協助我進行報關",
  "route": "航段",
  "sales_name": "業務人員",
  "same_as_consignee": "與收貨方資訊相同",
  "same_as_consignee_check_label": "與收貨方資訊相同",
  "same_as_notify": "與收貨方資訊相同",
  "same_as_notify_check_label": "有通知方資訊",
  "same_as_shipper": "與發貨方資訊相同",
  "same_as_shipper_check_label": "與發貨方資訊相同",
  "save_to_address": "儲存到我的地址簿",
  "secure_sales_no": "業務員工號",
  "secure_station": "所屬站點",
  "select_customer_code": "請選擇客戶代碼",
  "select_quotation_number": "請選擇報價單號碼",
  "service": "服務",
  "service_level": "服務等級",
  "service_type": "服務類型",
  "ship_type": "運輸方式",
  "shipment_by_to": "這是哪個客戶的貨?",
  "shipment_mode_air": "空運",
  "shipment_mode_sea": "海運",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "参考值",
  "shipment_require": "House 提單種類",
  "shipment_require_option_1": "併裝單 (HAWB/HBL)",
  "shipment_require_option_2": "直走單 (MAWB/MBL)",
  "shipment_require_option_3": "使用由 Morrison 事先提供給您的提單號碼",
  "shipment_to": "交貨資訊",
  "shipment_to_info": "交貨地址",
  "shipment_to_name": "交貨方",
  "shipper": "發貨方",
  "shipper_code": "發貨方公司代碼",
  "shipper_info": "發貨方地址",
  "shipper_name": "發貨方",
  "shipping_marks": "外箱嘜頭",
  "shipping_mode": "運送方式",
  "system_quotation": "作業用報價單",
  "special_instruction": "裝運須知",
  "state": "州/省",
  "status": "狀態",
  "step_1_Service": "第一步：服務",
  "step_2_Address": "第二步：地址",
  "step_3_Detail": "第三步：細節",
  "step_4_Doc": "第四步：文件",
  "submit_at": "送出日期 (UTC)",
  "submit_by": "送出者",
  "submit_question_title": "確定建立訂艙單?",
  "submit_question_content": "送出後只能在 Prolink/CargoWise 中修改，請確認發貨方/收貨方/報價單號碼是否正確.",
  "submit_question": "確定送出? ",
  "submitted": "已送出",
  "submitted_at": "送出日期 (UTC)",
  "submitted_at_utc": "送出日期 (UTC)",
  "submitted_by": "送出者",
  "target_delivery_date": "期望送抵時間",
  "terms_confirm": "我已確認所有細節都是正確的並接受 Morrison的%{a1}訂艙條款與條件%{a2}",
  "terms_confirm_check": "送出訂艙委託前，請您先確認內容是否正確，並勾選接受 Morrison的訂艙條款與條件",
  "terms_confirm_check_title": "無法送出請求",
  "terms_confirm_check_content": "送出訂艙委託前，請您先確認內容是否正確，並勾選接受 Morrison的訂艙條款與條件",
  "total_of_units": "外包裝總數",
  "transporation": "運輸",
  "transporation_mode": "運輸類型",
  "type": "類型",
  "unit_weight": "毛重(每件)",
  "upload_doc": "裝運文件",
  "value": "值",
  "volume_weight": "體積",
  "volume_weight_total": "總體積",
  "zip_code": "郵遞區號",
  "i_will_provide_later": "將在稍後提供",
  "shipper_instruction_letter": "託運申請書",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Service Level Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port": "目的港",
  "destination_port_code": "目的港代碼",
  "shipment": "貨物細節",
  "rejected": "已拒絕",
  "i_am_not_sure_right_now_tip": "若您不知道發貨方的姓名和地址，請勾選此框並提供托運人的聯系信息，以繼續進行訂艙委託",
  "i_will_provide_later_tip": "請在發貨前提供這類文件，以確保您的貨物按時到達。如果您在預訂時還沒有此類文件，請勾選此框以繼續訂艙。",
  "status_hint_for_doc": "請提供相關文件",
  "status_hint_for_address": "請確認發貨方資訊",
  "notify_section_titile": "通知方資訊",
  "address_checked_hint": "已自動帶入對應的主檔代號",
  "address_warning_hint": "存在多個對應的主檔代號，請從清單中選擇或點選放大鏡圖示檢視細節",
  "address_error_hint": "找不到對應的主檔代號，請向CCC申請新的代號或點選放大鏡圖示修改查詢條件",
  "quotation_warning_hint": "存在多個對應的報價單，請從清單中選擇或點選放大鏡圖示檢視細節",
  "quotation_checked_hint": "已自動帶入對應的報價單",
  "quotation_error_hint": "找不到對應的報價單，請點選放大鏡圖示修改條件查詢。",
  "active": "有效的",
  "draft": "草稿",
  "booking_in_progress": "訂艙中",
  "in_progress": "訂艙完成",
  "completed": "已完成",
  "canceled": "已取消",
  "notify_only_allow_upload_once": "檔案僅能上傳一次，若上傳後需要修改，請於下方留言並提供要修改的檔案。",
  "query_customer_code": "客戶代碼/名稱",
  "reference_type_value_di_values": "DI 參考值",
  "reference_type_value_in_values": "IN 參考值",
  "reference_type_value_po_values": "PO 參考值",
  "reference_type_value_sr_values": "SR 參考值",
  "reference_type_value_other_values": "其他參考值",
  "cargo_ready_date_note": "貨好時間需早於期望送達時間",
  "cargo_ready_date_at": "貨好時間",
  "is_dangerous_goods": "危險貨品",
  "booking_date": "訂艙日期",
  "actual_pickup_at": "實際提貨時間",
  "etd": "預計離港(ETD)",
  "atd": "實際離港(ATD)",
  "eta": "預計到港(ETA)",
  "ata": "實際到港(ATA)",
  "service_type_dtd": "DTD",
  "service_type_dta": "DTA",
  "service_type_dtp": "DTP",
  "service_type_ptp": "PTP",
  "service_type_ptd": "PTD",
  "service_type_ata": "ATA",
  "service_type_atd": "ATD",
  "out_for_delivery_date": "外出運送",
  "actual_delivery_date": "實際運送日",
  "customer_expected_eta_at": "期望離港",
  "only_allow_decimal": "只允許輸入小數%{num}位",
  "debit_to_code_is_inactive": "報價單中的請款對象代號 %{debit_to_id} %{debit_to_name}不可使用，請與業務確認報價單，以建立booking",
  "booking_source_list": "Booking Source",
  "mop": "Created in Morrison One",
  "prolink": "Created in Prolink/CW1",
  "customer_edi": "Customer EDI",
  "summary_search_icon_hint": "To search and select the valid code.",
  "summary_search_quotation_icon_hint": "1. Please select the quotation by search \n2. Please contact Process team if no query result",
  "summary_select_shipper_hint": "Please complete shipper's information.",
  "summary_select_consignee_hint": "Please complete consignee's information.",
  "summary_select_pickup_hint": "Please complete pickup information.",
  "summary_select_delivery_hint": "Please complete delivery’s information.",
  "summary_select_notify_hint": "Please complete notify party’s information.",
  "summary_select_quotation_hint": "Please confirm quotation number.",
  "summary_select_bottom_hint": "Or please leave the comment to creator or submitter when information is uncertain. ",
  "booking_no_incomplete": "The booking request no. %{number} information is incomplete. Please follow the steps to create booking.",
  "control_tower_submit_notify_title": "Online Booking hasn’t been set up PIC in %{origin_port} Station. Please follow the steps below to proceed the booking. ",
  "control_tower_submit_notify": "1. Contact %{origin_port} station and confirm PIC<br>\n2. Provide Booking Request No. link to PIC and instruct to confirm the booking<br>\n3. Provide PIC to Process Team %{email}<br>",
  "control_tower_submit_notify_sub": "You will receive an email contains above instruction."
 },
 "uld": {
  "info_existing_uld_can_append": "已存在一筆有效的ULD資料",
  "warning_mapping_exist": "此筆紀錄已存在",
  "error_master_not_exist": "MAWB不存在",
  "error_uld_has_multiple_airline": "有效的ULD僅會屬於一家航空公司，請確認資料",
  "error_duplicate_mapping_current_upload": "本次上傳有重複資料",
  "error_violate_uld_naming": "ULD No應包括英數字",
  "error_mapping_lack_uld_no": "MAWB Bulid Type為ULD時，ULD No必有值",
  "error_loose_uld_no_number": "散貨不需要ULD No",
  "error_build_type_not_support": "請確認MAWB Build Type是否有誤，僅限ULD或LOOSE",
  "alert_receive_uld_list": "準備接收#%{count}個ULD，詳列如下，請確認",
  "alert_remove_uld_from_request": "%{uld_no}將自此歸還單移出，且ULD狀態回到Dest Received，請確認",
  "alert_download_uld_return_request": "儲存成功，欲下載ULD歸還單?",
  "alert_cancel_return_request": "歸還單內的所有ULD狀態將回到Dest Received，且取消此歸還單，請確認",
  "alert_uld_return_handover": "請確認已將歸還單及ULD交接到卡車司機，Out For Return Date將為目前時間(可修改)",
  "alert_validate_uld_return_request": "所選ULD符合下列情況，請重選或修改資料\n-所選ULD包含多張歸還單\n-所選ULD對應到多家航空公司\n-所選ULD正在歸還或已經歸還",
  "alert_relate_to_return_request": "請自歸還單移出，才可刪除所選ULD",
  "uld_status_loading": "00 未接收",
  "uld_status_dest_received": "10 已接收",
  "uld_status_dest_wait_for_return": "20 安排歸還",
  "uld_status_dest_returning": "30 歸還中",
  "uld_status_dest_returned": "40 已歸還",
  "return_request_status_dest_returning_job_created": "00 建立歸還單",
  "return_request_status_dest_out_for_return": "10 派送歸還中",
  "return_request_statuss_dest_returned": "20 已完成歸還",
  "return_request_status_cancelled": "90 取消歸還單",
  "btn_upload_uld_mawb": "上傳ULD-MAWB對應",
  "btn_uld_receiving": "準備接收",
  "btn_uld_returning": "準備歸還",
  "btn_receive": "接收",
  "tooltip_master_build_type": "限填ULD或LOOSE",
  "tooltip_uld_type": "填入ULD type(非必填)，如：Q5, Q7",
  "undefined": "",
  "alert_carrier_code_not_exist": "",
  "alert_master_not_exist": "MAWB不存在",
  "alert_uld_in_another_return_request": "",
  "alert_airline_conflict": "ULD should share at most one airline",
  "alert_return_request_not_exist": "",
  "alert_return_request_is_cancelled": "Return request is cancelled",
  "alert_undefined_return_request_status": "",
  "alert_uld_conflict": "",
  "alert_cannot_add_uld_to_return_request": "",
  "master_origin_port": "MAWB Orig",
  "master_destination_port": "MAWB Dest",
  "master_number": "MAWB",
  "airline_name": "Airline",
  "uld_number": "ULD No",
  "uld_status": "ULD Status",
  "uld_return_number": "ULD Return No",
  "master_build_type": "MAWB Bulid Type",
  "note": "Note",
  "destination_uld_received_at": "Dest ULD Received Date",
  "is_uld_return_pod": "ULD Return POD",
  "destination_uld_returned_at": "Dest ULD Returned Date",
  "target_uld_returned_at": "Target ULD Returned Date",
  "master_eta_at": "MAWB ETA",
  "master_ata_at": "MAWB ATA",
  "master_atd_at": "MAWB ATD",
  "uld_received_by": "ULD Received By",
  "uld_received_truck_company": "ULD Received Truck Company",
  "uld_returned_truck_company": "ULD Returned Truck Company",
  "uld_remark": "ULD Remark",
  "uld_type": "ULD Type",
  "uld_bulk_upload": "ULD Upload",
  "flight_number": "Flight No",
  "delete_uld": "Do you want to delete this ULD?",
  "status_loading": "00 未接收",
  "status_received": "10 已接收",
  "status_wait_for_return": "20 安排歸還",
  "status_returning": "30 歸還中",
  "status_returned": "40 已歸還",
  "receiving_submission": "Submit Receving Request",
  "received_time": "Received Time",
  "received_carrier_code": "Received Truck Company",
  "returning_card_title": "ULD Returning",
  "returning_number": "ULD Returning No",
  "returning_status": "Status",
  "not_added": "尚未加入",
  "added": "已經加入",
  "returning_delivery_details": "Delivery Details",
  "uld_number_count": "#ULD",
  "out_for_return_at": "Out For Return At",
  "out_for_return": "Out For Return",
  "remark": "Remark",
  "airline_received_by": "Airline Received By",
  "airline_received_datetime": "Airline Received Date",
  "attachments": "Attachments",
  "station": "Station",
  "airline": "Airline"
 },
 "ct": {
  "account_is_not_registered_with_morrison_one": "%{email} account is not registered with Morrison One",
  "actual_delivery_date": "已送達",
  "actual_pickup_at": "提貨日期",
  "amount": "費用",
  "arrived_cfs_at": "抵達目的地集散站",
  "ata": "抵港",
  "atd": "實際離港",
  "attachment": "附件",
  "booking_date": "預定",
  "booking_number": "訂艙單號碼",
  "booking_request_number": "訂艙委託單號碼",
  "booking_request_no": "訂艙委託單號碼",
  "booking_number_name": "訂艙單號碼",
  "brokerag_and_cargo": "附加服務",
  "booking_status_draft": "草稿",
  "booking_status_submitted": "已送出",
  "booking_status_in_progress": "處理中",
  "booking_status_completed": "已完成",
  "booking_status_canceled": "已取消",
  "billing_info": "Billing Information",
  "calculated_by": "計算方式",
  "calculated_by_unit": "個別加總",
  "calculated_by_total": "依總裝運量計算",
  "cancel_at_utc": "取消訂艙日期",
  "cancel_by": "取消訂艙人",
  "cancelled_at": "取消",
  "cancel_check": "是否確定要取消訂艙委託單?",
  "cancel_notify_check": "Morrison 正在處理您的訂單，若取消可能會有部分費用發生，我們將盡速確認費用後與您聯繫，在此之前您的訂單狀態仍會維持在處理狀態，是否確定取消?",
  "cargo_ready_date": "Cargo Ready Date",
  "cargo_insurance": "Cargo Insurance",
  "charge_weight": "收費重量",
  "charge_weight_unit": "收費重量單位",
  "chargeable_weight": "收費重量 (Kg)",
  "client_name": "客戶名稱",
  "column_setting": "欄位設定",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc",
  "commodity": "貨品",
  "comments": "Comments",
  "complete_date": "貨運完成",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "收貨方",
  "consignee_address": "收貨方地址",
  "consignee_city": "收貨方城市",
  "consignee_country": "收貨方國家/地區",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "收貨方",
  "consolidation_document": "Consolidation",
  "container": "貨櫃",
  "container_number": "貨櫃號碼",
  "container_type": "貨櫃類型",
  "count": "統計",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "create_booking": "建立 Booking",
  "cubic_meter": "立方米",
  "currency": "貨幣",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "客戶代碼/名稱",
  "customer_name": "客戶名稱",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customized_setting": "客製訊息欄位",
  "customized_table_header": "客製標題",
  "customs_cleared_at": "清關",
  "customs_released_at": "海關放行",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery_container_number": "Delivery/ Container No",
  "dest": "進口站",
  "destination": "進口站",
  "destination_port": "進口站",
  "destination_port_full": "Destination Port",
  "destination_port_country": "進口站國家/地區",
  "details": "詳情",
  "delivery": "Delivery",
  "delivery_port": "Delivery Port",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "dimension": "尺寸",
  "do_number": "Document No",
  "doc_released_at": "放單",
  "document_slash_image": "文件 / 圖片",
  "documents": "文件",
  "download_qr_code": "下載手機掃碼",
  "booking": "Booking",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "若您選擇直走單，Tracking Number (提單號碼) 會透過 Booking confirmation 回覆您，在此之前您可用 Booking 號碼追蹤處理進度",
  "email": "郵箱",
  "enter_setting_name": "設定名稱",
  "estimated_delivery": "預計送貨時間",
  "estimated_pickup_at": "提貨日期",
  "eta": "預計到港",
  "etd": "預計離港",
  "event_time_local": "事件時間 (當地)",
  "excepton": "異常",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_eta": "期望送達時間",
  "expected_pickup_date": "預計可提貨時間",
  "export": "匯出",
  "fcr_shipment": "FCR Shipment",
  "fcl": "整櫃 (FCL)",
  "fd_number": "FD 號碼",
  "flight": "航班",
  "flight_from_port": "航班起站",
  "flight_number": "航班號",
  "flight_to_port": "航班終站",
  "freight_term": "付款條款",
  "freight_payment_term": "Freight Payment Term",
  "from": "起運",
  "general": "General",
  "goods_type": "Goods Type",
  "goods_info": "Goods information",
  "goods_description": "Goods Description",
  "gross_weight": "毛重",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "毛重單位",
  "group": "Group",
  "group_by": "顯示方式",
  "handling_pieces": "貨件數",
  "hawb_doc": "HAWB Doc",
  "height": "高度",
  "housebill_not_found": "找不到該筆提單",
  "housebill_number": "提單號",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "列表中如果沒有申請的客戶名稱請選'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "發票號碼",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_hazardous": "危險貨品",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "發票日期",
  "last_status": "最新狀態",
  "length": "長度",
  "local": "當地",
  "local_time": "當地時間",
  "logistic_handling": "物流操作",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc",
  "mec_invoice_doc": "MEC Invoice",
  "method": "裝運方式",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "收信僅限Morrison One註冊email",
  "name": "名稱",
  "no_download_url": "No DownloadUrl !",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document!",
  "no_shipment_tracking": "No Shipment Tracking !",
  "notification_name": "Notification Name",
  "notification_settings": "通知設定",
  "notification_settings_hint": "設定啟用之後,會套用到每筆新Shipment 都發通知",
  "notify_group": "Notify Group",
  "notify": "Notify",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "通知方",
  "notify_when_shipment": "發通知當貨態到達",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "實際到達",
  "option_milstone_booking_date": "預定確認",
  "option_milstone_completed_at": "貨運完成",
  "option_milstone_etd": "預計出發",
  "order": "Order",
  "origin": "出口站",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "出口站",
  "origin_port_country": "出口站國家/地區",
  "other_docs": "Other Docs",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "out_for_delivery_date": "實際外出遞送日期",
  "package_quantity": "包裹數",
  "packing_list": "Packing List",
  "parent_id": "Parent Code",
  "parts": "Parts",
  "part_number": "Part No",
  "part_description": "Part Description",
  "pallet": "棧板",
  "pick_up": "提貨",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "收貨地",
  "po_no": "PO No",
  "po_number": "PO No",
  "po_item_no": "PO Item No",
  "pod": "POD 時間",
  "pod_file": "POD 文件",
  "port_of_discharge": "卸貨港",
  "port_of_delivery": "Port of Delivery",
  "port_of_landing": "裝貨港",
  "port_of_receipt": "Port of Receipt",
  "progress": "進度",
  "project": "專案",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "手機掃碼快速查單",
  "quantity": "數量",
  "query": "查詢",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "qty_ea": "Qty(EA)",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "參考",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "requester": "請求者",
  "result": "結果",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_shipper": "與寄件人資訊相同",
  "same_as_consignee": "與收件人資訊相同",
  "same_as_shipper_check_label": "與寄件人資訊相同",
  "same_as_consignee_check_label": "與收件人資訊相同",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "send_mail_notification_to": "送email 通知給誰",
  "send_me_pod_file": "寄發 POD 文件",
  "send_notification_from": "寄送通知",
  "send_sms_notification_to": "送SMS 通知給誰",
  "secure_station": "Secure Station",
  "sequence": "順序",
  "service": "Service",
  "service_level": "Service Level",
  "service_info": "服務資訊",
  "service_remark": "服務備註",
  "service_type": "服務類型",
  "ship_to": "收貨",
  "ship_type": "Ship Type",
  "shipment_delay": "貨物延遲",
  "shipment_details": "貨件詳情",
  "shipment_mode": "運輸",
  "shipment_number": "HAWB / HBL",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipper": "發貨方",
  "shipper_address": "發貨方地址",
  "shipper_city": "發貨方城市",
  "shipper_country": "發貨方國家/地區",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "發貨方",
  "shipping_marks": "Shipping Marks",
  "shipping_instruction": "貨運指示",
  "ship": "",
  "shipping_mode": "裝運方式",
  "shipment_reference_no": "參考值",
  "sign_by": "簽收人",
  "shipment_by_to": "這是哪個客戶的貨?",
  "special_instruction": "裝運須知",
  "status": "狀態",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "shipment_mode_air": "空運",
  "shipment_mode_sea": "海運",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "teu": "Teu",
  "to": "收貨",
  "total_packages": "包裹數",
  "total_pallets": "棧板數",
  "total_pieces": "總件數",
  "total_spend": "總費用",
  "total_weight": "總重量",
  "total_weight_kg": "總重量 (Kg)",
  "trace_other_number": "追蹤其他提單號碼",
  "truck_size": "卡車尺寸",
  "truck_type": "車型",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "type": "類型",
  "uom": "單位",
  "upload_by": "上傳者",
  "upload_date_time": "上傳時間",
  "upload_doc": "裝運文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_details": "詳情",
  "uppercase_documents": "文件",
  "uppercase_in_progress": "進行中",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "追踪",
  "uppercase_tracking": "追踪",
  "unit_weight": "每件毛重",
  "value": "value",
  "vessel": "航班",
  "vessel_name": "船名",
  "vip_name": "客戶",
  "volume_weight": "體積重量",
  "volume_weight_total": "總體積",
  "volumetric_weight": "體積重量",
  "voyage": "航程",
  "weight_kg": "重量 (Kg)",
  "width": "寬",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "貨運配送狀況",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "load": "裝貨港",
  "loads": "Load",
  "lcl": "併櫃貨(LCL)",
  "loose_goods": "散貨",
  "discharge": "卸貨港",
  "total_of_units": "外包裝總數",
  "qty_of_outers": "Uty of Outers",
  "per_length": "長 (CM)",
  "per_weight": "寬 (CM)",
  "per_height": "高 (CM)",
  "pre_assign": "Morrison 提供之可預先指派的 HAWB/HBL 號碼",
  "pre_assign_desc": "請先向 MEC取得預先分配給您的單號，且不不得重複使用相同號碼",
  "pre_assign_fill": "I have Morrison provide.",
  "request_brokerage": "是否需要報關服務?",
  "need_carge": "是否需要貨物運輸保險?",
  "request_brokerage_yes": "是-我希望 Morrison 協助我進行報關",
  "request_brokerage_no": "否 - 我會自行處裡報關事宜",
  "need_carge_yes": "是 - 我希望 Morrison 協助購買貨物運輸保險",
  "need_carge_no": "否 - 我已購買保險/我對任何丟失或損壞的貨物承擔全部責任",
  "select_quotation_number": "請選擇報價單號碼",
  "shipper_code": "寄件人代碼",
  "consignee_code": "收件人代碼",
  "quotation_type": "報價單類別",
  "quotation_status": "報價單狀態",
  "is_effective": "報價單是否有效",
  "submitted_at": "Submit At",
  "commodity_code": "貨物代碼",
  "commodity_desc": "貨物描述",
  "others_term": "Others Payment",
  "effective_from": "有效日(起)",
  "effective_to": "有效日(迄)",
  "secure_sales_no": "業務人員",
  "department": "Department",
  "select_customer_code": "Select Customer Code",
  "address": "Address",
  "city": "City",
  "state": "State",
  "zip_code": "Zip Code",
  "country": "Country/Area",
  "contact_name": "Contact Name",
  "code": "Code",
  "overwrite_confirm": "你想覆蓋寄件人/收件人資訊?",
  "save_to_address": "儲存到我的地址簿",
  "not_sure_right_now": "我現在還不知道",
  "submit_question": "是否送出定艙委託單?",
  "diff_origin_area_notify": "你選擇的託運人國家與出口站的國家不同",
  "diff_dest_area_notify": "你選擇的收貨人國家與進口站的國家不同",
  "pod_doc": "POD檔案",
  "biling_name": "客戶名稱",
  "teu_column_tooltip": "TEU為一預估值，可能因資料缺失導致無法正確計算",
  "shipment_query_limitation_info": "Query data is available for booking shipment after 1st June 2020",
  "milestone_status_prepare_for_depature": "準備出發",
  "milestone_status_departed_from_port": "已出發",
  "milestone_status_arrived_at_port": "已抵達",
  "milestone_status_prepare_for_delivery": "準備遞送",
  "milestone_status_out_for_delivery": "外出遞送",
  "milestone_status_delivered": "已送達",
  "milestone_status": "貨態查詢",
  "shipment_created_at_utc": "創建時間(UTC)",
  "shipment_count": "總貨運數量",
  "shipment_count_descript": "總Shipment 件數",
  "ready_for_departure_count": "準備出發數量",
  "departed_at_port_count": "已出發數量",
  "arrived_at_port_count": "已抵達數量",
  "prepare_for_delivery_count": "準備遞送數量",
  "out_for_delivery_count": "外出遞送數量",
  "delivered_count": "已送達數量",
  "total_chargeable_weight": "總收費重量(KG)",
  "abbr_consignee_name": "收貨方",
  "billing_account_name": "客戶名稱",
  "completed_shipment_count": "已完成貨運總數",
  "completed_shipment_count_sea_tooltip": "已完成的HBL總件數",
  "completed_chargeable_weight": "已完成貨運收費總重(KG)",
  "cancelled_shipment_count": "已取消貨運總數",
  "cancelled_shipment_count_sea_tooltip": "已取消的HBL總件數",
  "cancelled_chargeable_weight": "已取消貨運收費總重(KG)",
  "total_shipment_count": "總貨運數量",
  "total_shipment_count_sea_tooltip": "總HBL件數",
  "total_delivery_count": "總Delivery數",
  "total_delivery_count_sea_tooltip": "總Delivery/貨櫃件數",
  "total_teu": "總TEU數",
  "total_cubic_meter": "總立方米",
  "completed_delivery_count": "已完成Delivery總件數",
  "completed_delivery_count_sea_tooltip": "已完成的Delivery/貨櫃總件數",
  "total_completed_teu": "已完成總TEU數",
  "total_completed_cubic_meter": "已完成總立方米",
  "cancelled_delivery_count": "已取消Delivery總件數",
  "cancelled_delivery_count_sea_tooltip": "已取消的Delivery/貨櫃總件數",
  "total_cancelled_teu": "已取消總TEU數",
  "total_cancelled_cubic_meter": "已取消總立方米",
  "available_for_shipment_within_six_month": "僅提供查詢六個月內貨況",
  "week": "貨運創建週",
  "error_notification_already_exists": "Notification for shipment %{shipment_number} already exists, please refresh the page.",
  "client_id": "客戶編號",
  "advanced_filter": "更多選項",
  "co2_emission_estimation": "碳排放量預估",
  "co2_emission_summary": "預估碳排放量總和",
  "co2_emission_warning": "Carbon emissions values on the Morrison One Platform are provided by EcoTransIT. EcoTransIT is a flexible tool for complex calculations that supports companies to calculate their emissions. The software is accredited by Smart Freight Centre and is in compliance with the GLEC Framework and also meets the requirements of EN 16258 and the GHG protocol (Corporate Standard). Visit https://www.ecotransit.org/ for more details.",
  "co2_emission_not_availabled": "無法使用",
  "co2_emission_transport_status": "運輸類別",
  "co2_emission_estimated_distance": "預估距離 (公里)",
  "co2_emission_estimated_co2_emission": "預估碳排放量 (公斤)",
  "co2_emission_well_to_tank": "油井到油箱 (WTT)",
  "co2_emission_tank_to_wheel": "油箱到車輪  (TTW)",
  "co2_emission_well_to_wheel": "油井到車輪  (WTW)",
  "co2_emission_pick_up_from_shipper": "託運人自取",
  "co2_emission_terminal_to_terminal": "航站至航站",
  "co2_emission_delivery_to_customer": "送貨至客戶",
  "co2_emission_total_summary": "各項加總",
  "quick_track_wrong_format": "格式錯誤",
  "quick_track_submit_btn": "查詢",
  "quick_track_input_placeholder": "輸入提單號追蹤",
  "quick_track_footer": "Morrison One 取得更多資訊,如未有Ｍorrison One帳號請與您的業務窗口申請",
  "quick_track_milestone_history": "里程碑歷史",
  "quick_track_current_milestone_status": "里程碑狀態",
  "quick_track_404_title": "無效的追蹤單號",
  "quick_track_404_text": "請您再次確認輸入資訊或輸入其他追蹤單號",
  "quick_track_housebill_not_found": "找不到該筆提單, 請您再次確認輸入資訊或查詢另一筆提單",
  "milestone_status_cancelled": "已取消",
  "milestone_status_tooltip": "準備出發：預定/提貨/出口清關\n已出發：實際離港\n已抵達：抵港/放單/海關放行\n準備遞送：抵達目的地集散站\n外出遞送：實際外出遞送\n已送達：已送達",
  "port_list_placeholder": "請輸入港口代號 範例: TPE",
  "country_code_list_placeholder": "請輸入國家代號 範例: TW",
  "undefined": ""
 },
 "dashboard": {
  "add_section": "Add Section",
  "add_widget": "Add Widget",
  "add_widget_row": "Add Widget Row",
  "all": "全部",
  "component_option_customer_tracking_shipments_query": "Customer Tracking Shipments Query",
  "custom_day_range": "%{daynumber} 天內",
  "custom_week_range": "%{weeknumber} 週內",
  "in_past_n_weeks": "過去 %{weeknumber} 週內",
  "life_time": "全部",
  "current_week": "本週",
  "last_week": "上週",
  "last_last_week": "上上週",
  "nth_week_of_last_year": "去年第 %{weeknumber} 週",
  "nth_week_of_this_year": "今年第 %{weeknumber} 週",
  "add_nth_week_option": "輸入數字選擇週數",
  "custom-widget-2d-table-column-key": "Column Key",
  "custom-widget-2d-table-column-key-order": "Column Order",
  "custom-widget-2d-table-custom-column-key": "Custom Column",
  "custom-widget-2d-table-has-filter": "Has Filter",
  "custom-widget-2d-table-column-selector-detail": "Column Selector Detail",
  "custom-widget-2d-table-key-agg": "Aggregate",
  "custom-widget-2d-table-row-key": "Row Key",
  "custom-widget-2d-table-row-key-order": "Row Order",
  "custom-widget-2d-table-nongroup-column-key": "NonGroup Column",
  "custom-widget-group-by": "Group By",
  "custom_widget_prepend_text": "前置文本",
  "dashboard": "儀錶板",
  "data_selector": "選擇日期區間",
  "edit_widget": "Edit Widget",
  "error_add_fast_create_dispatch": "You cannot fast create with different dispath no",
  "hello_word": "Hi, %{account}. Welcom to Morrison One Platform.",
  "last_update": "最後更新",
  "select_a_dashboard": "select a dashboard",
  "setting_column_aggregate_avg": "average",
  "setting_column_aggregate_count": "count",
  "setting_column_aggregate_max": "max",
  "setting_column_aggregate_min": "min",
  "setting_column_aggregate_sum": "sum",
  "total": "總和",
  "grand_total": "累計",
  "user_picker": "User Picker",
  "filter_input_placeholder": "使用逗號(,)分隔",
  "undefined": "",
  "component_option_kpi_late_milestone_query": "KPI Weekly Detail Late Milestone",
  "component_option_kpi_summary_query": "KPI Summary Query",
  "default": "Default Dashboard",
  "set_as_default": "Make Default",
  "use_i18n": "使用 i18n",
  "ct_dashbaord_prepend_text": "最新貨況請以Tracking/Shipment資料為準, 本頁貨況資料延遲至少15分鐘,. ",
  "ct_dashboard_prepend_default_range_notice": "本儀表板預設查詢區間為近 3個月內資料, 可使用 \"日期區間\" 功能調整查詢區間最長至近12 個月",
  "ct_dashboard_teu_notice": "TEU為一預估值，可能因資料缺失導致無法正確計算,本資料僅供參考",
  "has_advanced_search_date_range_limit": "進階搜尋日期範圍限制",
  "has_advanced_search": "啟用進階搜尋",
  "prepend_text": "前置文字",
  "prepend_i18n_key": "前置 I18n 鍵",
  "prepend_text_classname": "前置文字 className",
  "quick_guide_privilege_id": "快速指引權限鍵",
  "ct_advanced_search_within_days_next_not_allowed": "不可超過今日日期",
  "ct_advanced_search_within_days_min_error": "最小查詢範圍為1天",
  "ct_advanced_search_within_days_max_error": "最大查詢範圍近180天",
  "ct_advanced_search": "日期區間",
  "use_custom_advanced_search_key": "定義進階搜尋按鈕文字",
  "custom_advanced_search_i18n_key": "進階搜尋 I18n 鍵",
  "ct_advanced_search_within_days_min_variable_error": "最小查詢範圍為%{number}天",
  "ct_advanced_search_within_days_max_variable_error": "最大查詢範圍近%{number}天",
  "ct_advanced_search_between_min_variable_days_error": "僅提供查詢%{number}天內貨況"
 },
 "edi": {
  "undefined": "Updated Date",
  "hawb_number": "HAWB / HBL",
  "customer_code": "客戶代碼",
  "customer": "VIP Name",
  "ediMappingStatusList": "EDI處理狀態",
  "ediProcessStatus": "EDI處理狀態",
  "edi_type": "EDI 類型",
  "created": "Created",
  "batch_send_fail": "Batch Send Fail",
  "batch_send_success": "Batch Send Success",
  "canceled": "Canceled",
  "generate_success": "Generate Success",
  "send_ack_failed": "Send Ack Failed",
  "send_success": "Send Success",
  "send_wait_ack": "Send Wait Ack",
  "send_fail": "Send Fail",
  "validate_fail": "Validate Fail",
  "validate_retry": "Validate Retry",
  "bill_to_party": "Bill-to Party",
  "origin_port": "Orig",
  "destination_port": "Dest",
  "invoice_number": "Inv No",
  "trade_type": "Trade Type",
  "non_trade": "Non-Trade",
  "trade": "Trade",
  "index_properties": "Index Ref",
  "inv_amount": "Inv Amount",
  "inv_currency": "Inv Currency",
  "shipment_first_atd": "HAWB First ATD (Local Time)",
  "edi_sent_at": "EDI Sent Date (UTC) ",
  "process_message": "Message",
  "isa_number": "ISA No",
  "billing_issued_at": "Inv date",
  "print_at": "Print Date",
  "shipment_last_ata": "Last ATA",
  "last_atd_input_date": "Last ATA Input Date(UTC)",
  "shipment_actual_pod": "POD",
  "edi_status_hint": "Edi status ",
  "pod_input_date": "POD Input Date(UTC)"
 },
 "config": {
  "client_id": "客戶ID",
  "vip_name": "Customer",
  "status_type": "Status Type",
  "edi_category": "EDI Category",
  "edi_event_code": "EDI Event Code",
  "edi_status_code": "EDI Status Code",
  "edi_type": "EDI Type",
  "event_description": "Event Description",
  "mode": "Mode",
  "mop_status_code": "Mop Status Code",
  "is_synced": "已經同步",
  "edi_charge_code": "EDI Charge Code",
  "edi_charge_description": "EDI Charge Description",
  "prolink_charge_code": "Prolink Charge Code",
  "prolink_charge_description": "Prolink Charge Description",
  "confirm_delete": "確認刪除",
  "are_you_sure_to_delete_this_system_setting": "確定要刪除此系統設定？",
  "setting_name": "設定名稱",
  "setting_value": "設定值",
  "need_sync": "需要同步",
  "are_you_sure_to_delete_this_mec_station": "確定要刪除此站點設定？",
  "code": "Code",
  "source_db": "Source DB",
  "station": "Station",
  "role": "Role",
  "time_zone": "Time Zone",
  "name_1": "Name 1",
  "name_2": "Name 2",
  "address_1": "Address 1",
  "address_2": "Address 2",
  "address_3": "Address 3",
  "address_4": "Address 4",
  "telephone": "Telephone",
  "fax": "Fax",
  "zip_code": "Zip Code",
  "city_code": "City Code",
  "state_code": "State Code",
  "country_code": "Country Code",
  "edi_city_name": "Edi City Name",
  "contact_name": "Contact Name",
  "report_region": "Report Region",
  "prolink_remark": "Prolink Remark",
  "tms_ship_from_name": "TMS Ship From Name",
  "tms_ship_from_address": "TMS Ship From Address",
  "tms_ship_from_city": "TMS Ship From City",
  "tms_ship_from_country": "TMS Ship From Country",
  "tms_ship_from_contact": "TMS Ship From Contact",
  "tms_ship_from_state": "TMS Ship From State",
  "tms_ship_from_tel": "TMS Ship From Tel",
  "default_pdf_template": "Default PDF Template",
  "edi_code": "EDI Code",
  "edi_sender": "EDI Sender",
  "need_ack": "Need Ack",
  "need_confirm": "Need Confirm",
  "need_mdn": "Need Mdn",
  "shipper_id": "發貨方ID",
  "entity": "層級",
  "entity_field": "層級欄位",
  "entity_for_timezone_reference": "Entity For Timezone Reference",
  "timezone_reference_field": "Timezone Reference Field",
  "timezone_reference_type": "Timezone Reference Type",
  "notify_people": "異動此設定將會通知: ",
  "setting_description": "設定描述: ",
  "table_options": "Table Options"
 },
 "epod": {
  "can_not_upload_because_checked": "POD 檔案已檢核，無法繼續上傳，請聯絡 Morrison OP。",
  "input_error_code": "請輸入例外代碼",
  "company_stamp": "Company Stamp（公司章簽收）",
  "same_as_consignee": "Same As Consignee ( 同收貨人名）",
  "error_ofd_datetime_not_future_time": "晚於當前時間，請仔細檢查後再錄入。",
  "error_invalid_date_format": "無效的日期/時間格式",
  "error_pod_datetime_not_future_time": "簽收時間晚於當前時間，請仔細檢查後再錄入。",
  "error_pod_datetime_earlier_than_ofd_time": "早於外出遞送時間，請仔細檢查後再錄入。"
 },
 "shalog": {
  "truck_plate_no": "卡車車牌號碼",
  "lock_info_due_to_template_setting": "此欄位已鎖定(依據樣板設定)",
  "template": "樣板",
  "error_shipment_pcs_should_not_be_zero": "貨件數需大於0",
  "total_pallets_or_loose_cartons_should_not_be_zero": "貨件數需大於0",
  "all": "全部",
  "shipment_template_id": "貨物模板編號",
  "project": "專案",
  "handing_station": "責任站點",
  "fd_no": "FD 號碼",
  "pr_no": "PR 號碼",
  "sales_owner": "業務代碼",
  "template_remark": "模板註記",
  "customer": "客戶",
  "shipper": "發貨人",
  "consignee": "收貨人",
  "client_id": "客戶代碼",
  "effective_start": "生效開始日期",
  "effective_end": "生效結束日期",
  "handling_station": "責任站點",
  "billing_station": "出帳站點",
  "billing_station_conf": "出帳站點設定",
  "logistic_handling": "物流操作",
  "origin_station": "始發站",
  "destination_station": "目的站",
  "origin_station_conf": "始發站設定",
  "shipment_mode": "運輸方式",
  "shipment_mode_conf": "運輸方式設定",
  "template_number": "模板號碼",
  "template_status": "模板狀態",
  "active": "啟用",
  "inactive": "撤銷",
  "activate": "啟用",
  "deactivate": "撤銷",
  "shipment": "貨物",
  "routing": "路線",
  "milestones_and_document": "事件與文件",
  "voucher_and_invoice": "憑單與發票",
  "shipment_info": "貨物資訊",
  "are_you_sure_you_want_to_activate_the_shipment_template": "您確定要啟用此貨物模板嗎?",
  "are_you_sure_you_want_to_deactivate_the_shipment_template": "您確定要撤銷此貨物模板嗎?",
  "step_one_shipment": "第一步: 貨物",
  "step_two_milestones": "第二步: 事件",
  "step_three_document": "第三步: 文件",
  "step_four_billing": "第四步: 出帳",
  "cargo_details": "貨物詳情",
  "shipment_template_remark": "貨物模板註記",
  "effective_from": "生效開始日期",
  "effective_to": "生效結束日期",
  "origin": "始發",
  "destination": "目的",
  "ship_mode": "運輸方式",
  "description_of_goods": "貨物描述",
  "reference_no": "參考號碼",
  "special_instruction": "特殊指示",
  "dangerous_good": "危險品",
  "package": "包裝",
  "pallet_qty": "棧板數",
  "loose_ctn_qty": "散箱數",
  "shipment_pcs": "貨件數",
  "gw": "毛重",
  "cw": "計費重量",
  "volume": "體積",
  "volumetric_weight": "體積重",
  "delivery": "Delivery",
  "pick_up_name": "提貨名",
  "pick_up_address": "提貨地址",
  "city": "城市",
  "state": "州別",
  "zip_code": "郵遞區號",
  "country": "國家/地區",
  "contact_name": "聯絡人",
  "contact_email": "電子信箱",
  "phone_number": "電話",
  "fd_info": "FD資訊",
  "bpo_no": "BPO號碼",
  "search_fd_info": "搜尋FD資訊",
  "search": "搜尋",
  "submit": "提交",
  "back": "返回",
  "next": "下一步",
  "add": "新增",
  "save": "儲存",
  "fd_manager": "FD主管",
  "choose_bpo": "選擇BPO",
  "apple_dri": "蘋果負責人",
  "change_info": "改變資訊",
  "please_choose_station_and_customer_first": "請先選擇站點及客戶",
  "shipment_template": "貨物模板",
  "billing": "出帳",
  "milestones": "事件",
  "document": "文件",
  "total_bpo_amount": "總BPO金額",
  "bpo_spent_balance_percentage": "BPO花費結餘 (%)",
  "total_internal_invoice_amount": "總內部帳單金額 (A)",
  "total_billed_amount_in_trck": "總Apple TRCK已出帳金額",
  "spent_balance": "花費結餘 (總BPO金額 - A)",
  "total_bpo_billed_balance": "總BPO已出帳結餘",
  "service_type": "服務類型",
  "service_remark": "服務註記",
  "truck_type": "卡車類型",
  "truck_size": "卡車尺寸",
  "flight_number": "航班號碼",
  "from_port": "起始港",
  "to_port": "目的港",
  "add_service": "新增服務",
  "delete_service": "刪除服務",
  "service_info": "服務資訊",
  "routing_info": "路線資訊",
  "from": "起",
  "to": "迄",
  "stop": "停靠點",
  "same_as_shipper": "同發貨人",
  "same_as_consignee": "同收貨人",
  "name": "名字",
  "address": "地址",
  "floor": "Floor/Suit/Unit",
  "port_slash_location": "港 / 地點",
  "stop_remark": "停靠站註記",
  "location_name": "地點名稱",
  "port_code": "港口代碼",
  "add_stop": "新增停靠站",
  "milestone_info": "事件資訊",
  "timezone": "時區",
  "add_milestone": "新增事件",
  "description": "描述",
  "delete_this_stop": "刪除停靠點",
  "milestone": "事件",
  "port": "港口",
  "location": "地點",
  "vendor": "供應商",
  "dept": "部門",
  "payment_method": "付款方式",
  "customer_name": "客戶名稱",
  "charge_code": "收費代碼",
  "charge_item": "收費項目",
  "charge_description": "收費敘述",
  "qty": "數量",
  "required_voucher": "需要的憑單",
  "required_invoice": "需要的帳單",
  "add_voucher": "新增憑單",
  "station": "站點",
  "debit_to": "欠款對象",
  "debit_to_name": "欠款對象名稱",
  "delete": "刪除",
  "edit": "編輯",
  "unit": "單位",
  "rate": "費率",
  "charge_uvat_amount": "收費稅前金額",
  "charge_vat_amount": "收費稅金",
  "charge_amount": "收費金額",
  "rate_currency": "收費幣別",
  "add_invoice": "新增發票",
  "sales_code": "業務代碼",
  "add_item": "新增項目",
  "edit_voucher": "編輯憑單",
  "edit_invoice": "編輯發票",
  "document_type": "文件類型",
  "mandatory": "必要的",
  "document_setting": "文件設定",
  "vendor_name": "供應商名稱",
  "complete": "完成",
  "add_document_type": "新增文件類型",
  "to_be_created": "待創建",
  "printed": "已列印",
  "origin_nvat_amount": "原始稅前金額",
  "origin_vat_amount": "原始稅金",
  "origin_amount": "原始金額",
  "origin_nvat_amount_in_usd": "原始美金稅前金額",
  "origin_vat_amount_in_usd": "原始美金稅金",
  "origin_amount_in_usd": "原始美金金額",
  "origin_currency": "原始幣別",
  "updated_by": "更新者",
  "updated_at_utc": "更新時間(UTC)",
  "void_voucher": "廢除憑單",
  "reqest_voucher_processing": "請求憑單(處理中)",
  "reqest_invoice_processing": "請求發票(處理中)",
  "void": "銷帳",
  "created_invoice": "已創建發票",
  "created_voucher": "已創建憑單",
  "invoice": "發票",
  "voucher": "憑單",
  "show_voided": "顯示廢除",
  "voucher_currency": "憑單幣別",
  "total_sales": "總銷售",
  "total_costs": "總成本",
  "gross_margin": "毛利率",
  "origin_charge_rate": "原始收費費率",
  "voucher_no": "憑單號碼",
  "action": "Request類別",
  "the_field_will_be_not_editable_in_the_shipment_data": "此欄位將 <b>不可被編輯</b> 在貨物資料中",
  "the_field_will_be_editable_in_the_shipment_data": "此欄位將 <b>可被編輯</b> 在貨物資料中",
  "please_select_a_fd_number_for_this_template": "請為此貨物模板選擇一個FD號碼",
  "please_note_that_this_bpo_only_has_spend_balance_budget_left": "請注意此BPO僅剩下 %{spendBalance}% ",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未儲存的資料將遺失，您確定要離開嗎?",
  "tips": "提示",
  "customer_code_name": "客戶代碼/名稱",
  "payment_term": "付款條件",
  "customer_code": "客戶代碼",
  "once_completed_this_template_cannot_be_changed_are_you_sure_to_continue": "一旦完成此模板將無法改變，您確定要繼續嗎?",
  "vip_name": "VIP名稱",
  "shipper_city": "發貨城市",
  "shipper_address": "發貨地址",
  "consignee_city": "收貨城市",
  "consignee_address": "收貨地址",
  "consignee_address_localized": "當地的收貨地址",
  "shipper_address_and_city": "發貨地址 & 城市",
  "consignee_address_and_city": "收貨地址 & 城市",
  "create_from_utc": "創建日期從 (UTC)",
  "create_to_utc": "創建日期到 (UTC)",
  "created_at_utc": "創建日期 (UTC)",
  "dg_y_n": "DG (Y/N)",
  "project_manager": "專案管理人",
  "shipper_id": "發貨人ID",
  "consignee_id": "收貨人ID",
  "to_be_created_voucher": "待創建憑單",
  "processing_voucher": "處理憑單",
  "printed_voucher": "已列印憑單",
  "to_be_created_invoice": "待創建發票",
  "processing_invoice": "處理發票",
  "printed_invoice": "已列印發票",
  "last_updated_utc": "最後更新時間 (UTC)",
  "calculating": "計算中",
  "not_including_mec_internal_billing": "僅計算 SHALOG 且狀態為 PRINTED 的憑單與發票",
  "clone": "複製",
  "can_not_be_zero": "不能為 0",
  "addressbook": "地址簿",
  "clear": "清除",
  "voucher_date": "報價日期",
  "invoice_date": "發票日期",
  "request_id": "請求編號",
  "voucher_processing_and_printed": "報價單(處理中 & 印出)",
  "invoice_processing_and_printed": "發票(處理中 & 印出)",
  "bill_to": "付款對象",
  "cancel": "取消",
  "invoice_no": "發票號碼",
  "undo": "復原",
  "chargeable_weight": "收費重量 (Kg)",
  "gross_weight": "毛重",
  "service_provider": "服務提供者 %{index}",
  "po_ready": "PO 就緒",
  "invoice_ready": "發票就緒",
  "bulk_update": "批量更新",
  "generate_shipper_letter": "產生簽收單",
  "invoice_ready_at_utc": "發票就緒時間(UTC)",
  "po_ready_at_utc": "PO 就緒時間(UTC)",
  "invoice_ready_by": "發票就緒確認者",
  "po_ready_by": "PO 就緒確認者",
  "missing_pod": "缺失 POD 時間",
  "missing_pick_up": "缺失提貨時間",
  "missing_pod_file": "缺失 POD 檔案",
  "is_ready": "Is Ready",
  "total_shipments_selected": "總共選取 %{count} 筆貨物",
  "is_invoice_ready": "發票就緒",
  "is_po_ready": "PO 就緒",
  "is_invoice_ready_true": "Yes",
  "is_invoice_ready_false": "No",
  "is_po_ready_true": "Yes",
  "is_po_ready_false": "No",
  "invoice_no_ready_count": "發票未就緒",
  "po_no_ready_count": "PO 未就緒",
  "invoice_no_ready_percentage": "發票未就緒 (%)",
  "po_no_ready_percentage": "PO 未就緒 (%)",
  "actual_delivery_date (in nth week)": "POD時間",
  "created_at_utc (in nth week)": "建單時間",
  "number_of_clones": "複製數量",
  "number_of_clones_is_required": "必要填寫複製數量",
  "the_maximum_number_of_clone_is_50": "最大複製上限50筆",
  "shipment_query_table_total_revenue": "總銷售額",
  "shipment_query_table_total_cost": "總成本",
  "shipment_query_table_total_gross_profit": "毛利潤",
  "shipment_query_table_total_gross_margin": "毛利率",
  "shipment_query_table_customs_gross_margin": "報關毛利率",
  "shipment_query_table_documentation_gross_margin": "文件服務毛利率",
  "shipment_query_table_domestic_gross_margin": "本地服務毛利率",
  "shipment_query_table_freight_gross_margin": "運費毛利率",
  "shipment_query_table_handling_gross_margin": "操作毛利率",
  "shipment_query_table_trucking_gross_margin": "卡車毛利率",
  "shipment_query_table_warehouse_gross_margin": "倉儲毛利率",
  "shipment_query_table_logistic_gross_margin": "物流毛利率",
  "hawb_hbl": "HAWB / HBL",
  "shipment_edit": "Shipment Edit",
  "fd_number": "FD 號碼",
  "shipment_count": "提單數量",
  "invoice_amount": "發票金額",
  "po_amount": "PO 金額",
  "gross_margin_in_usd": "毛利",
  "gross_margin_percentage": "毛利率",
  "late_creation_count": "晚建單",
  "late_creation": "晚建單",
  "missing_milestone": "缺失事件",
  "missing_invoice": "缺失發票",
  "missing_voucher": "缺失 PO",
  "missing_reference": "缺失 Reference",
  "late_creation_percentage": "晚建單 (%)",
  "missing_pick_up_count": "缺失提貨時間",
  "missing_pod_count": "缺失POD時間",
  "missing_pod_file_count": "缺失POD檔案",
  "missing_pod_percentage": "缺失POD時間 (%)",
  "missing_pick_up_percentage": "缺失提貨時間 (%)",
  "missing_pod_file_percentage": "缺失POD檔案 (%)",
  "missing_milestone_percentage": "缺失里程碑 (%)",
  "missing_invoice_percentage": "缺失發票 (%)",
  "missing_voucher_percentage": "缺失 PO  (%)",
  "please_choose_shipment_template": "請選擇貨運範本",
  "warning_bpo_percentage_too_low": "請注意這筆 BPO 僅剩下 %{number} 可用",
  "confirm_bpo_percentage_too_low": "請注意這筆 BPO 已經小於 0% 可用",
  "logistic_handling_shgdt": "SHGDT",
  "mode_comprehensive": "Comprehensive",
  "table_header_service": "服務 %{index}",
  "table_header_service_type": "服務類型 %{index}",
  "table_header_service_remark": "服務註記 %{index}",
  "table_header_service_handling_station": "服務責任站點 %{index}",
  "table_header_service_truck_type": "服務卡車類型 %{index}",
  "table_header_service_truck_size": "服務卡車尺寸 %{index}",
  "table_header_service_flight": "服務航班 %{index}",
  "table_header_service_flight_to_port": "服務目的港 %{index}",
  "table_header_service_flight_from_port": "服務始發港 %{index}",
  "table_header_milestone": "事件 %{index}",
  "table_header_milestone_sequence": "事件序列 %{index}",
  "table_header_milestone_db_type": "事件資料庫種類 %{index}",
  "table_header_milestone_description": "事件描述 %{index}",
  "table_header_milestone_datetime": "事件日期 %{index}",
  "table_header_milestone_tz": "事件時區 %{index}",
  "table_header_document": "文件 %{index}",
  "table_header_document_type": "文件類型 %{index}",
  "table_header_document_name": "文件名稱 %{index}",
  "table_header_document_link": "文件連結 %{index}",
  "are_you_sure_you_want_to_cancel_the_shipment": "確認刪除貨物?",
  "shipment_number": "貨物號碼",
  "origin_port": "始發站",
  "destination_port": "目的站",
  "draft": "草稿",
  "in_progress": "處理中",
  "completed": "已完成",
  "cancelled": "已取消",
  "DRAFT": "草稿",
  "IN_PROGRESS": "處理中",
  "COMPLETED": "已完成",
  "CANCELLED": "已取消",
  "is_dangerous": "危險品",
  "created_at": "創建時間",
  "bpo_number": "BPO號碼",
  "shipper_name": "發貨人",
  "shipper_state": "發貨州別",
  "shipper_country": "發貨國家/地區",
  "consignee_name": "收貨人",
  "consignee_state": "收貨州別",
  "consignee_country": "收貨國家/地區",
  "pickup_at": "提貨時間",
  "pickup_at_tz": "提貨時間時區",
  "pod_at": "實際送達時間",
  "pod_at_tz": "實際送達時間時區",
  "sla_at": "SLA時間",
  "sla_at_tz": "SLA時間時區",
  "delivery_number": "Delivery No",
  "are_you_sure_you_want_to_clone_the_shipment": "確認複製貨物?",
  "confirm_clone_shipment": "確認複製貨物?",
  "milestone_at": "事件時間",
  "status": "狀態",
  "PACKING_LIST": "箱單",
  "COMMERCIAL_INVOICE": "商業發票",
  "PROOF_OF_DELIVERY": "交貨證明",
  "add_file": "新增檔案",
  "view_file": "檢視檔案",
  "upload_document": "上傳文件",
  "empty": "空",
  "gm_percentage": "毛利率 %",
  "reference_number": "參考號碼",
  "are_you_sure_you_want_to_delete_the_address_book": "您確定要刪除此地址? ID: %{id}",
  "select_address_book": "選擇地址",
  "create_address_book": "創建地址",
  "edit_address_book": "編輯地址",
  "address_id": "地址ID",
  "missing_billing": "缺失帳單",
  "table_header_missing_billing": "缺失發票/PO",
  "larger_than_40_percentage": "大於 40%",
  "between_20_40_percentage": "20% ~ 40%",
  "between_0_20_percentage": "0% ~ 20%",
  "between_minus_20_0_percentage": "-20% ~ 0%",
  "between_minus_20_minus_40_percentage": "-40% ~ -20%",
  "less_than_minus_40_percentage": "小於 -40%",
  "master_number": "MAWB號碼",
  "uom": "UOM",
  "factor": "Factor",
  "length": "長",
  "width": "寬",
  "height": "高",
  "cartons": "箱數",
  "part_number": "料號",
  "part_description": "料件敘述",
  "quantity": "數量",
  "delivery_po_number": "料件PO號碼",
  "only_completed_shipment_can_be_clone": "只能複製已完成貨物",
  "shipment_number_display": "貨物號碼: %{shipmentNumber}",
  "created_by": "創建者",
  "owner": "擁有者",
  "please_enter_correct_shipment_number": "請輸入正確的貨物號碼",
  "delivery_method": "遞送方式",
  "invalid_shipment_status": "無效的貨物狀態: %{status}",
  "pod_signed_by": "POD Signed By",
  "pod_signed_by_copy_from": "從收貨聯絡複製",
  "pickup_date_should_be_earlier_than_pod_date": "實際提貨日期應該早於實際遞送日期",
  "pod_date_should_be_later_than_pickup_date": "實際遞送日期應該晚於實際提貨日期",
  "link_shipment": "已關聯貨物",
  "save_shipment_detail_and_change_milestone_warning": "Your address has been changed. This will also change the routing address and milestone time zone in “Milestone” Page.",
  "foreign_shipment": "外站貨物",
  "save_link_shipment_number_alarm_text": "%{shipmentNumber} 將自動同步資料至 %{newLinkShipmentNo} ",
  "update_link_shipment_number_alarm_text": "關聯已變更，從 %{linkedShipmentNumber} 到 %{newLinkShipmentNo}。%{shipmentNumber} 將自動同步資料至 %{newLinkShipmentNo} ",
  "error_code_bulk_update_cant_save": "批量更新儲存失敗",
  "error_code_fd_status_update_fail": "FD狀態更新失敗",
  "total_pallets": "托盤總數",
  "loose_cartons": "散箱",
  "chargeable_weight_unit": "計費重量單位",
  "gross_weight_unit": "毛重單位",
  "chargable_weight": "計費重量",
  "description_of_good": "貨物描述",
  "fd_status": "FD No Status",
  "reference": "Reference",
  "reference_type": "Reference Type",
  "reference_value": "Reference Value",
  "reference_type_value_di_values": "DI 參考值",
  "reference_type_value_in_values": "IN 參考值",
  "reference_type_value_po_values": "PO 參考值",
  "reference_type_value_sr_values": "SR 參考值",
  "reference_type_value_undefined_values": "未定義參考值",
  "reference_type_value_other_values": "其他參考值",
  "reference_type_value_whse_values": "Warehouse 參考值",
  "reference_type_value_cargo_loc_values": "Cargo Location 參考值",
  "reference_type_value_carrier_values": "Carrier 參考值",
  "reference_type_value_mawb_values": "HAWB / MBL 參考值",
  "reference_type_value_hawb_values": "HAWB / HBL 參考值",
  "reference_type_value_vsl_name_values": "Vessel Name 參考值",
  "reference_type_value_asn_values": "ASN 參考值",
  "reference_type_value_bol_ref_values": "BOL 參考值",
  "reference_type_value_cntr_no_values": "Container No 參考值",
  "reference_type_value_cntr_size_values": "Container Size 參考值",
  "all_data": "所有資料",
  "is_linked": "已關聯?",
  "are_you_sure_you_want_to_activate_this_fd_no": "請確定是否啟用此 FD No?",
  "are_you_sure_you_want_to_deactivate_this_fd_no": "請確定是否撤銷此 FD No?",
  "linked_shipmentment_number": "已關聯的貨物號碼",
  "estimated_pickup_date": "預計提貨",
  "stop_from_template": "站點來自樣板",
  "gross_margin_ready": "GM Ready",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "inactive_fd_no": "註銷的FD號碼: %{fd_number}",
  "copy_from_estimated_date": "從預計到貨日複製",
  "the_shipment_is_invalid": "The shipment is invalid",
  "linked_shipment": "Linked Shipment",
  "late_creation_milestone_days": "Late creation is set to be %{milestone} + %{lateCreationDays} days",
  "you_should_keyin_missing_reference": "You should keyin missing reference, missing reference: [ %{missedReference} ]",
  "shipper_address_code": "發貨地址代碼",
  "consignee_address_code": "收貨地址代碼",
  "link_shipment_bulk_edit": "關聯貨物",
  "link_shipment_no_is_not_existed": "關聯貨物號碼不存在",
  "error_code_shipment_id_is_not_exist": "找不到貨物號碼",
  "error_code_shipment_template_id_is_not_exist": "找不到貨物模板號碼",
  "link_shipment_no_checked_is_duplicate": "%{shipment_number} 已重複",
  "milestone_at_cannot_be_cleared": "事件時間不能被清除",
  "excel_template_file_is_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "沒有留言，將無法取消貨物",
  "cancel_shipment": "取消貨物",
  "bol_number_list": "BOL 號碼",
  "generate_bol_or_sli": "產生 BOL/SLI",
  "you_can_only_select_less_than_100_shipments_at_once": "一次只能選取最多100筆",
  "bol_per_shipment": "BOL per shipment",
  "create_bol_per_shipment": "Create BOL per Shipment",
  "consolidated_bol": "Consolidated BOL",
  "create_consolidated_bol": "Create Consolidated BOL",
  "sli_per_shipment": "SLI per shipment",
  "actual_appointment_date": "Actual Appointment Date",
  "actual_appointment_date_tz": "Actual Appointment Date Timezone",
  "error_code_bol_number_is_not_exist": "BOL號碼找不到",
  "error_code_cancel_bol_number_failed": "取消BOL號碼失敗",
  "choose_the_reference_to_show_on_the_document": "Choose the reference to show on the document",
  "shipment_udpate": "Shipment Udpate",
  "shipment_update": "Shipment Update",
  "delivery_instruction": "運送指示",
  "pickup_instruction": "提貨指示",
  "invalid_service_provider_code": "無效的廠商代碼",
  "invalid_escort_value": "Invalid Escort Value",
  "invalid_security_level": "無效的安全等級",
  "invalid_service_level": "無效的服務等級",
  "invalid_delivery_method_value": "Invalid Delivery Method Value",
  "invalid_cod_amount_fee_terms_value": "Invalid COD Amount Fee Terms Value",
  "invalid_customer_check_acceptable_value": "Invalid Customer Check Acceptable Value",
  "invalid_freight_charge_terms_value": "Invalid Freight Charge Terms Value",
  "invalid_bol_type_value": "Invalid BOL Type Value",
  "required_pickup_date": "提貨時間",
  "required_pickup_date_start": "提貨開始時間",
  "required_pickup_date_end": "提貨截止時間",
  "required_pickup_must_be_a_range": "要求提貨時間須為區間",
  "required_delivery_date": "送貨時間",
  "required_delivery_date_start": "送貨開始時間",
  "required_delivery_date_end": "送貨截止時間",
  "required_delivery_must_be_a_range": "要求送貨時間須為區間",
  "carrier_is_not_approved": "Carrier is not approved",
  "bol_service_provider": "服務提供者",
  "generate_files": "產生檔案(%{file_length})",
  "check_and_generate_files": "檢查及產生檔案(%{file_length})",
  "are_you_sure_you_want_to_cancel_the_bol": "確認刪除%{bol_number}?",
  "error_code_bol_generate_failed": "產生BOL失敗 : %{bol_number}",
  "error_code_bol_validate_failed": "驗證BOL失敗 : %{bol_number}",
  "error_code_consoildated_bol_validate_consignee_id": "不同的收貨人地址無法整合",
  "error_code_consoildated_bol_validate_shipper_id": "不同的發貨人地址無法整合",
  "carrier_pro_number": "Pro No",
  "error_code_bol_no_truck_serivce_type": "無卡車服務可以用來產生BOL.",
  "un_no_for_dg": "UN No for DG",
  "shipment_special_instruction": "特殊指示",
  "per_shipment": "BOL per shipment",
  "address_and_city": "地址 & 城市",
  "actual_delivery_date": "Actual Delivery Date",
  "escort": "Escort",
  "tracking_device_number": "Tracking Device No",
  "change_you_make_here_will_also_apply_to_all_the_shipments_in_this_bol_no": "這裡你的改動將影響此BOL號碼底下所有的貨物",
  "missing_project_manager": "缺失專案管理人",
  "missing_pr_number": "缺失PR號碼",
  "missing_fd_manager": "缺失FD主管",
  "missing_fd_number": "缺失FD號碼",
  "timezone_not_found_please_select_a_timezone": "未找到時區。 請選擇時區",
  "missing_ref": "缺失參考號碼",
  "missing_inv": "缺失發票",
  "dg": "危險品",
  "error_code_bol_shipment_status_already_completed": "下列貨物號碼已經完成遞送，請先將其從當前其BOL號碼中移除或取消整筆BOL號碼",
  "error_code_bol_shipment_already_binded": "下列貨物號碼已經有關聯BOL號碼，請先將其從當前其BOL號碼中移除或取消整筆BOL號碼",
  "dispatch_number_list": "Dispatch 號碼",
  "error_code_create_shipment_invalid_pr": "此PR No.已被專案管理人停用，請選擇另一個 PR No.或聯絡您的專案管理人",
  "cannot_proceed_the_bol_generation_shipment_below_are_still_in_draft_status": "無法執行BOL創建。下方貨物號碼狀態仍然在草稿",
  "error_code_bol_shipment_not_found_or_already_unbinded": "此Shipment 已從此BOL移除，或是已不存在",
  "edit_bol_per_shipment": "Edit BOL per Shipment",
  "edit_consolidated_bol": "Edit Consolidated BOL",
  "add_back": "加回去",
  "shipment_dn_have_not_been_assgined": "",
  "cancel_bol_no": "Cancel Bol No %{bol_number}",
  "error_code_bol_already_unbinded_all_shipment": "此BOL單號已取消。其中無任何貨物關聯，請重整您的頁面。",
  "removed_successfully": "Removed Successfully",
  "shipment_dns_have_not_been_assigned": "%{shipment_length} shipment/DNs have not been assigned",
  "you_are_about_to_remove_this_bol_no_do_you_want_to_proceed": "你即將刪除這張BOL No, 請確定執行",
  "billing_reference_type": "Billing Ref Type",
  "invalid_required_pickup_date_range": "無效的提貨日期區間",
  "invalid_required_delivery_date_range": "無效的送貨日期區間",
  "invalid_date_range": "無效的日期區間",
  "package_qty": "包裝數",
  "bol_type": "BOL Type",
  "this_bol_no_has_delivered_already_do_you_want_to_proceed_the_change": "此BOL號碼已送到，你確定要繼續執行至修改頁面嗎?",
  "bol_shipment_packages": "(%{packages} 包裹數)",
  "bol_ship_from": "Ship From *",
  "bol_ship_to": "Ship To *",
  "handling_pieces": "Package Qty",
  "qty_should_not_be_zero": "貨件數需大於0",
  "rate_should_not_be_zero": "費率需大於0",
  "error_code_bol_voucher_not_void": "下方貨物號碼已存在憑單，請先廢除憑單再繼續執行",
  "error_duplicate_charge_item": "不允許重複的項目",
  "timezone_invalid": "請更正國家和城市欄位",
  "timezone_invalid_title": "地址不正確",
  "product_line": "Product Line",
  "invalid_product_line": "Invalid Product Line",
  "cainiao_download": "Download",
  "cainiao_download_milestone": "Milestone & CCL.",
  "cainiao_download_epam": "e-Pam Customs",
  "cainiao_download_billing": "Billing Report",
  "cainiao_upload": "Upload",
  "cainiao_upload_milestone": "Milestone & CCL.",
  "please_try_again_later": "Please trry again later",
  "customs_clearance_status": "Customs Clear Status",
  "customs_clearance_status_tooltip": "The update will effect all the courier number of this shipment",
  "cainiao_upload_causes_of_incomplete": "causes of incomplete",
  "cainiao_upload_causes_detail_reason_1": "If there is any error in a single record, this recode will not be processed.",
  "cainiao_upload_causes_detail_reason_2": "MAWB No., Bag ID, CN No. is not existed.",
  "cainiao_upload_causes_detail_reason_3": "Invalid date format, should be YYYY/MM/DD hh:mm",
  "cainiao_upload_causes_detail_reason_4": "Wrong date sequence, early → late: customs clear start, customs cleared, OFD, POD",
  "cainiao_upload_error_column_key": "CN#"
 },
 "bk_tmp": {
  "actual_delivery_date": "已送達",
  "actual_pickup_at": "提貨日期",
  "address": "Address",
  "amount": "費用",
  "arrived_cfs_at": "抵達目的地集散站",
  "ata": "抵港",
  "atd": "實際離港",
  "attachment": "附件",
  "billing_info": "Billing Information",
  "booking": "Booking",
  "booking_date": "預定",
  "booking_number": "訂艙單號碼",
  "booking_number_name": "訂艙單號碼",
  "booking_request_no": "訂艙委託單號碼",
  "booking_request_number": "訂艙委託單號碼",
  "booking_status_canceled": "已取消",
  "booking_status_completed": "已完成",
  "booking_status_draft": "草稿",
  "booking_status_in_progress": "處理中",
  "booking_status_submitted": "已送出",
  "brokerag_and_cargo": "附加服務",
  "calculated_by": "計算方式",
  "calculated_by_total": "依總裝運量計算",
  "calculated_by_unit": "個別加總",
  "cancel_at_utc": "取消訂艙日期",
  "cancel_by": "取消訂艙人",
  "cancel_check": "是否確定要取消?",
  "cancel_notify_check": "Morrison 正在處理您的訂單，若取消可能會有部分費用發生，我們將盡速確認費用後與您聯繫，在此之前您的訂單狀態仍會維持在處理狀態，是否確定取消?",
  "cancelled_at": "取消",
  "cargo_insurance": "Cargo Insurance",
  "cargo_ready_date": "Cargo Ready Date",
  "charge_weight": "收費重量",
  "charge_weight_unit": "收費重量單位",
  "chargeable_weight": "收費重量 (Kg)",
  "city": "City",
  "client_name": "Customer Name",
  "code": "Code",
  "column_setting": "欄位設定",
  "comments": "Comments",
  "commercial_invoice": "Commercial Invoice",
  "commercial_invoice_document": "Commercial Invoice Doc",
  "commodity": "貨品",
  "commodity_code": "貨物代碼",
  "commodity_desc": "貨物描述",
  "complete_date": "貨運完成",
  "confirm_at_utc": "Booking Req. Confirm Date",
  "confirm_by": "Booking Req. Confirm by",
  "consignee": "收貨方",
  "consignee_address": "收貨方地址",
  "consignee_city": "收貨方城市",
  "consignee_code": "收件人代碼",
  "consignee_country": "收貨方國家/地區",
  "consignee_info": "Cnee Addr.",
  "consignee_name": "收貨方",
  "consolidation_document": "Consolidation",
  "contact_name": "Contact Name",
  "container": "貨櫃",
  "container_number": "貨櫃號碼",
  "container_type": "貨櫃類型",
  "count": "統計",
  "country": "Country/Area",
  "create_booking": "建立 Booking",
  "created_at_utc": "Booking Req. Create Date",
  "created_by": "Booking Req. Create by",
  "cubic_meter": "立方米",
  "currency": "貨幣",
  "custom_brokerage": "Custom Brokerage",
  "customer_code": "Customer Code",
  "customer_code_name": "客戶代碼/名稱",
  "customer_expected_pickup_at_utc": "Expected Pickup Date",
  "customer_expected_pod_at_utc": "Expected Delivery Date",
  "customer_name": "Customer Name",
  "customized_setting": "客製訊息欄位",
  "customized_table_header": "客製標題",
  "customs_cleared_at": "清關",
  "customs_released_at": "海關放行",
  "dangerous_goods": "Dangerous goods",
  "dangerous_goods_declaration": "Dangerous Goods Declaration",
  "delivery": "Delivery",
  "delivery_container_number": "Delivery/ Container No",
  "delivery_port": "Delivery Port",
  "department": "Department",
  "dest": "進口站",
  "destination": "進口站",
  "destination_port": "進口站",
  "destination_port_country": "進口站國家/地區",
  "destination_port_full": "Destination Port",
  "details": "詳情",
  "di_no": "Delivery #",
  "di_number": "Di No",
  "diff_dest_area_notify": "你選擇的收貨人國家與進口站的國家不同",
  "diff_origin_area_notify": "你選擇的託運人國家與出口站的國家不同",
  "dimension": "尺寸",
  "direct_shipment": "Direct Shipment",
  "direct_shipment_desc": "若您選擇直走單，Tracking Number (提單號碼) 會透過 Booking confirmation 回覆您，在此之前您可用 Booking 號碼追蹤處理進度",
  "discharge": "卸貨港",
  "do_number": "Document No",
  "doc_released_at": "放單",
  "document_slash_image": "文件 / 圖片",
  "documents": "文件",
  "download_qr_code": "下載手機掃碼",
  "effective_from": "有效日(起)",
  "effective_to": "有效日(迄)",
  "email": "郵箱",
  "enter_setting_name": "設定名稱",
  "estimated_delivery": "預計送貨時間",
  "estimated_pickup_at": "提貨日期",
  "eta": "預計到港",
  "etd": "預計離港",
  "event_time_local": "事件時間 (當地)",
  "excepton": "異常",
  "expected_eta": "期望送達時間",
  "expected_eta_note": "MEC會盡力達到您的期望，但不保證一定可滿足",
  "expected_pickup_date": "預計可提貨時間",
  "export": "匯出",
  "fcl": "整櫃 (FCL)",
  "fcr_shipment": "FCR Shipment",
  "fd_number": "FD 號碼",
  "flight": "航班",
  "flight_from_port": "航班起站",
  "flight_number": "航班號",
  "flight_to_port": "航班終站",
  "freight_payment_term": "Freight Payment Term",
  "freight_term": "付款條款",
  "from": "起運",
  "general": "General",
  "goods_description": "Goods Description",
  "goods_info": "Goods information",
  "goods_type": "Goods Type",
  "gross_weight": "毛重",
  "gross_weight_total": "Total Gross Weight",
  "gross_weight_unit": "毛重單位",
  "group": "Group",
  "group_by": "顯示方式",
  "handling_pieces": "貨件數",
  "hawb_doc": "HAWB Doc",
  "height": "高度",
  "housebill_not_found": "找不到該筆提單",
  "housebill_number": "提單號",
  "id": "Id",
  "if_you_can_not_find_cutomer_name_in_list_just_choose_general": "列表中如果沒有申請的客戶名稱請選'General'",
  "incoterm": "Incoterm",
  "insurance_value": "Comercial invoice Value",
  "insurance_value_currency": "Comercial invoice currency",
  "invoice_number": "發票號碼",
  "is_custom_service": "Custom service ",
  "is_dangerous_goods": "Dangerous Good",
  "is_doc_ready": "Doc. Ready",
  "is_effective": "報價單是否有效",
  "is_hazardous": "DG",
  "is_insurence": "Insurance",
  "is_origin_cert": "Certificate of Origin",
  "issued_date": "發票日期",
  "last_status": "最新狀態",
  "lcl": "併櫃貨(LCL)",
  "length": "長度",
  "load": "裝貨港",
  "loads": "Load",
  "local": "當地",
  "local_time": "當地時間",
  "logistic_handling": "物流操作",
  "loose_goods": "散貨",
  "m": "M",
  "mawb": "MAWB",
  "mawb_doc": "MAWB Doc",
  "mec_invoice_doc": "MEC Invoice",
  "method": "裝運方式",
  "milestone": "Milestone",
  "mode": "Ship Mode",
  "morrison_register_email_only": "收信僅限Morrison One註冊email",
  "name": "名稱",
  "need_carge": "是否需要貨物運輸保險?",
  "need_carge_no": "否 - 我已購買保險/我對任何丟失或損壞的貨物承擔全部責任",
  "need_carge_yes": "是 - 我希望 Morrison 協助購買貨物運輸保險",
  "no_download_url": "No DownloadUrl !",
  "no_need_direct_shipment": "No, I am fine with HAWB/HBL Number",
  "no_shipment_detail": "No Shipment Detail !",
  "no_shipment_document": "No Shipment Document!",
  "no_shipment_tracking": "No Shipment Tracking !",
  "not_sure_right_now": "我現在還不知道",
  "notification_name": "Notification Name",
  "notification_settings": "通知設定",
  "notification_settings_hint": "設定啟用之後,會套用到每筆新Shipment 都發通知",
  "notify": "Notify",
  "notify_group": "Notify Group",
  "notify_info": "Notify Addr.",
  "notify_name": "Notify Name",
  "notify_party": "通知方",
  "notify_when_shipment": "發通知當貨態到達",
  "number": "Number",
  "ofd": "OFD",
  "option_milstone_ata": "實際到達",
  "option_milstone_booking_date": "預定確認",
  "option_milstone_completed_at": "貨運完成",
  "option_milstone_etd": "預計出發",
  "order": "Order",
  "origin": "出口站",
  "origin_cert_memo": "Certificate of origin memo",
  "origin_port": "出口站",
  "origin_port_country": "出口站國家/地區",
  "other_docs": "Other Docs",
  "other_reference_file": "Other reference file",
  "others_payment_term": "Others Payment Term",
  "others_term": "Others Payment",
  "out_for_delivery_date": "實際外出遞送日期",
  "overwrite_confirm": "確認是否要覆蓋....... 待BA補字",
  "package_quantity": "包裹數",
  "packing_list": "Packing List",
  "pallet": "Pallet",
  "parent_id": "Parent Code",
  "part_description": "Part Description",
  "part_number": "Part No",
  "parts": "Parts",
  "per_height": "高 (CM)",
  "per_length": "長 (CM)",
  "per_weight": "寬 (CM)",
  "pick_up": "提貨",
  "pickup": "Pickup",
  "pickup_info": "Pickup Addr.",
  "pickup_name": "Pickup Name",
  "place_of_Receipt": "收貨地",
  "po_item_no": "PO Item No",
  "po_no": "PO No",
  "po_number": "PO No",
  "pod": "POD 時間",
  "pod_file": "POD 文件",
  "port_of_delivery": "Port of Delivery",
  "port_of_discharge": "卸貨港",
  "port_of_landing": "裝貨港",
  "port_of_receipt": "Port of Receipt",
  "pre_assign": "Morrison 提供之可預先指派的 HAWB/HBL 號碼",
  "pre_assign_desc": "請先向 MEC取得預先分配給您的單號，且不不得重複使用相同號碼",
  "pre_assign_fill": "I have Morrison provide.",
  "progress": "進度",
  "project": "專案",
  "proof_of_delivery": "Proof of Delivery",
  "proof_of_delivery_other_docs": "Proof of Delivery Other Docs.",
  "qr_code_tracking": "手機掃碼快速查單",
  "qty_ea": "Qty(EA)",
  "qty_of_outers": "Uty of Outers",
  "quantity": "數量",
  "query": "查詢",
  "quotation": "Quotation",
  "quotation_number": "Quotation Number",
  "quotation_status": "報價單狀態",
  "quotation_type": "報價單類別",
  "range_in": "輸入範圍在 %{start}~%{end}",
  "receipt_port": "Receipt Port",
  "reference": "參考",
  "reference_4color_bt": "FourColor - Booking Title",
  "reference_apple_dri": "APPLE DRI",
  "reference_apple_fdmg": "FD MANAGER",
  "reference_apple_fdno": "FD No",
  "reference_apple_prno": "APPLE PR No",
  "reference_book_title": "Book Title",
  "reference_box": "BOX No",
  "reference_cc": "Cost Centre Code",
  "reference_cost_cnt": "Cost Center Number",
  "reference_cra": "Cannister No",
  "reference_dc": "Declaration No for AI and AE",
  "reference_di": "Delivery ID",
  "reference_gl_accunt": "GL Account No",
  "reference_in": "Invoice No",
  "reference_it": "Item No",
  "reference_load_date": "Ex - Factory Date for Sonos Report",
  "reference_lu": "LU No",
  "reference_number": "Reference No",
  "reference_or": "Order No",
  "reference_os_ossg_mm": "UMC Department Code",
  "reference_ot": "Other",
  "reference_pj": "Project Name",
  "reference_pl": "P / L #",
  "reference_pn": "Part No",
  "reference_po": "Purchase Order No",
  "reference_rma": "Returned Material Authorization",
  "reference_s_n": "Serial No",
  "reference_s_o": "S / O No + Ship",
  "reference_sd": "SDAF No",
  "reference_sgce1": "UMC Department Code",
  "reference_sgcs3": "UMC Department Code",
  "reference_si": "SID No",
  "reference_smp_cntct": "Contact / Purchaser",
  "reference_smp_po": "PO No",
  "reference_smp_psp": "PSP - Element No",
  "reference_smp_purno": "Purchase Requisition No",
  "reference_sr": "ShipRef No",
  "reference_st": "Style No",
  "reference_type": "TYPE",
  "reference_umc": "UMC Ref Type",
  "request_brokerage": "是否需要報關服務?",
  "request_brokerage_no": "否 - 我會自行處裡報關事宜",
  "request_brokerage_yes": "是-我希望 Morrison 協助我進行報關",
  "requester": "請求者",
  "result": "結果",
  "route": "Route",
  "sales_name": "Sales Name",
  "same_as_consignee": "與收件人資訊相同",
  "same_as_consignee_check_label": "與收件人資訊相同",
  "same_as_notify": "same as notify",
  "same_as_notify_check_label": "Notify 3rd party when shipment arrival at destination",
  "same_as_shipper": "與寄件人資訊相同",
  "same_as_shipper_check_label": "與寄件人資訊相同",
  "save_to_address": "儲存到我的地址簿",
  "secure_sales_no": "業務人員",
  "secure_station": "Secure Station",
  "select_customer_code": "Select Customer Code",
  "select_quotation_number": "請選擇報價單號碼",
  "send_mail_notification_to": "送email 通知給誰",
  "send_me_pod_file": "寄發 POD 文件",
  "send_notification_from": "寄送通知",
  "send_sms_notification_to": "送SMS 通知給誰",
  "sequence": "順序",
  "service": "Service",
  "service_info": "服務資訊",
  "service_level": "Service Level",
  "service_remark": "服務備註",
  "service_type": "服務類型",
  "ship": "",
  "ship_to": "收貨",
  "ship_type": "Ship Type",
  "shipment_by_to": "這是哪個客戶的貨?",
  "shipment_delay": "貨物延遲",
  "shipment_details": "貨件詳情",
  "shipment_mode": "運輸",
  "shipment_mode_air": "空運",
  "shipment_mode_sea": "海運",
  "shipment_number": "HAWB / HBL",
  "shipment_reference_no": "參考值",
  "shipment_require": "Bill Of Lading Type",
  "shipment_require_option_1": "General Shipment (HAWB/HBL)",
  "shipment_require_option_2": "Direct Shipment (MAWB/MBL)",
  "shipment_require_option_3": "Pre-assign HAWB/HBL provide by Morrison",
  "shipment_to": "Shipment To",
  "shipment_to_info": "Shipto Addr.",
  "shipment_to_name": "Shipto Name",
  "shipper": "發貨方",
  "shipper_address": "發貨方地址",
  "shipper_city": "發貨方城市",
  "shipper_code": "寄件人代碼",
  "shipper_country": "發貨方國家/地區",
  "shipper_info": "Shipper Addr.",
  "shipper_name": "Shipper Name",
  "shipping_instruction": "貨運指示",
  "shipping_marks": "Shipping Marks",
  "shipping_mode": "裝運方式",
  "sign_by": "簽收人",
  "special_instruction": "裝運須知",
  "state": "State",
  "status": "狀態",
  "step_1_Service": "STEP 1: Service",
  "step_2_Address": "STEP 2: Address",
  "step_3_Detail": "STEP 3: Detail",
  "step_4_Doc": "STEP 4: Document",
  "submit_at": "Submit At",
  "submit_by": "Submit By",
  "submit_question": "是否送出訂艙委託單?",
  "submitted_at": "Submit At",
  "submitted_at_utc": "Booking Rea. Submit Date",
  "submitted_by": "Booking Req. Submit by",
  "subtotal": "Subtotal",
  "target_delivery_date": "Target Delivery Date",
  "terms_confirm": "I confirm that this is a commercial shipment and all details are correct. I accept the %{a1}   terms of the booking %{a2}",
  "terms_confirm_check": "Please check \"I confirm that this is a commercial shipment and all details are correct. I accept the terms of the booking.\" before you submit booking request.",
  "teu": "Teu",
  "to": "收貨",
  "total_of_units": "外包裝總數",
  "total_packages": "包裹數",
  "total_pallets": "Pallet Qty",
  "total_pieces": "總件數",
  "total_spend": "總費用",
  "total_weight": "總重量",
  "total_weight_kg": "總重量 (Kg)",
  "trace_other_number": "追蹤其他提單號碼",
  "transporation": "Transportation",
  "transporation_mode": "Transporation Mode",
  "truck_size": "卡車尺寸",
  "truck_type": "車型",
  "type": "類型",
  "unit_weight": "每件毛重",
  "uom": "單位",
  "upload_by": "上傳者",
  "upload_date_time": "上傳時間",
  "upload_doc": "裝運文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_details": "詳情",
  "uppercase_documents": "文件",
  "uppercase_in_progress": "進行中",
  "uppercase_morrison_one": "MORRISON ONE",
  "uppercase_track": "追踪",
  "uppercase_tracking": "追踪",
  "value": "值",
  "vessel": "航班",
  "vessel_name": "船名",
  "vip_name": "客戶",
  "volume_weight": "體積重量",
  "volume_weight_total": "總體積",
  "volumetric_weight": "體積重量",
  "voyage": "航程",
  "weight_kg": "重量 (Kg)",
  "width": "寬",
  "yes_no_0": "N",
  "yes_no_1": "Y",
  "your_shipment_encounters": "貨運配送狀況",
  "zip_code": "郵遞區號",
  "undefined": "",
  "shipment_type": "Shipment Type",
  "shipment_desc": "Shipment Desc",
  "service_level_desc": "Service Level Desc",
  "source_db": "Source DB",
  "is_trade_billing": "Is Trade Billing",
  "origin_port_code": "Origin Port Code",
  "destination_port_code": "Destination Port Code",
  "shipment": "貨物",
  "company_id_error": "Company ID error"
 },
 "packing_station": {
  "l1_creation": "L1 Creation",
  "create_l1": "Create L1",
  "create_l2": "Create L2",
  "create_l3": "Create L3",
  "create_spt": "Create SPT",
  "create_load": "Create Load",
  "l1": "L1",
  "qualified_order": "Qualified Order",
  "verification": "Verification",
  "csm_no": "CSM No",
  "location": "Location",
  "all_special_instruction": "All Special Instruction",
  "create_success": "Create Success",
  "ship_to": "Ship to",
  "ship_to_add": "Ship to add",
  "required": "Required",
  "something_wrong": "Something Wrong !",
  "site": "Site",
  "client": "Client",
  "pack_level": "Pack Level",
  "status": "Status",
  "reference": "Reference",
  "refresh": "Refresh",
  "new": "New",
  "check_your_rule": "Check Your Rule",
  "please_check_ship_to_code_and_pack_level_and_pack_code_have_not_the_same": "Please Check Ship to code、Pack level and Pack code have not the same",
  "status_have_to_confirmed_or_pack_level_have_to_csm": "Status have to Confirmed or Pack Level have to CSM",
  "enter_job_information": "Enter Job Information",
  "do_you_want_to_delete_the_jobs": "Do you want to delete the jobs ? ",
  "ok": "Ok",
  "cancel": "Cancel",
  "delete_success": "Delete Success",
  "do_you_want_to_confirm_the_jobs": "Do you want to confirm the Jobs ?",
  "confirm_success": "Confirm Success",
  "status_have_completed": "Status have Completed",
  "ship_to_code": "Ship To Code",
  "next_step": "Next Step",
  "print_success": "Print Success",
  "close": "Close",
  "job": "Job",
  "ad_hoc_charges": "Ad HOC Charges",
  "save_success": "Save Success",
  "charge_name_is_not_unique": "Charge Name is not unique",
  "name": "Name",
  "description": "Description",
  "remark": "Remark",
  "quantity": "Quantity",
  "cost": "Cost",
  "quotation": "Quotation",
  "create": "Create",
  "update": "Update",
  "do_you_want_to_delete_this_ad_hoc_charges": "Do you want to delete the Ad HOC Charges ?",
  "address": "Address",
  "carrier": "Carrier",
  "special_instruction": "Special Instruction",
  "attachment_list": "Attachment List",
  "update_success": "Update Success",
  "ship_to_info": "Ship To Info",
  "shipper_info": "Shipper Info",
  "ship_from_info": "Ship From Info",
  "attention": "Attention",
  "phone": "Phone",
  "address1": "Address1",
  "address2": "Address2",
  "address3": "Address3",
  "city": "City",
  "state": "State",
  "postal_code": "Postal Code",
  "country": "Country",
  "carrier_code": "Carrier Code",
  "service_code": "Service Code",
  "user_account": "User Account",
  "line_no": "Line No",
  "pack_no": "Pack No",
  "tracking_no": "Tracking No",
  "order_number": "Order Number",
  "pack_job": "Pack Job",
  "load_job": "Load Job",
  "batching_new": "Batching New",
  "job_number": "Job Number",
  "pack_rule": "Pack Rule",
  "load_number": "Load Number",
  "undefined": "",
  "configuration": "Configuration",
  "no": "No",
  "yes": "Yes",
  "consignment_number": "Consignment Number",
  "item_qty": "Item Qty",
  "default_carrier": "Default carrier",
  "empty": "Empty",
  "postcode": "Postcode",
  "sku": "SKU",
  "picking_sn": "Picking SN",
  "packing_sn": "Packing SN",
  "pick_qty": "Pick Qty",
  "total_qty": "Total Qty"
 },
 "devops": {
  "environment": "Environment",
  "branch": "Branch",
  "version": "Version",
  "commit_hash": "Commit Hash",
  "repository": "Repository",
  "created_at": "Created At",
  "created_by": "Created By",
  "ticket_list": "Ticket List",
  "issue_id": "Issue Id",
  "issue_key": "Issue Key",
  "issue_link": "Issue Link",
  "summary": "Summary"
 },
 "shpt": {
  "actual_appointment_date": "實際預約日期",
  "actual_arrive_first_us_ca_dest": "實際抵達美洲進口港時間",
  "actual_arrive_transshipment_port": "實際抵達轉運港時間",
  "actual_delivery_date": "實際送達日",
  "actual_depart_port_at": "實際出口站啟程日期",
  "actual_depart_transshipment_port": "實際轉運港出發時間",
  "actual_departed_port_of_export": "出口港實際出發日期",
  "actual_pickup_date": "實際提貨日期",
  "address": "地址",
  "address_in_local_language": "當地語言地址",
  "address_line_one": "地址行 1",
  "address_line_two": "地址行 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "更新地址到收貨地址相同且尚未建立送貨單的貨件",
  "adjust_column": "調整欄位",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "機場",
  "alert": "警示",
  "amat_edi_alert": "此提單號尚未與一個 AMAT ID %{id} 關聯. 請檢查是否號碼輸入有誤並修正",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "更新到符合條件的貨物",
  "appointment_confirmed_date": "預約確認日期",
  "appointment_confirmed_number": "預約確認號碼",
  "appointment_made_by": "預約發起人",
  "appointment_made_date": "預約發起日期",
  "appointment_made_method": "預約發起管道",
  "appointment_made_with": "預約發起方式",
  "appointment_number": "預約號碼",
  "arrival_city": "抵達城市",
  "arrival_terminal_at": "抵達出口航站日期",
  "arrived_at_cfs": "抵達目的CFS時間",
  "arrived_ata": "到達 (ATA)",
  "arrived_cfs": "抵達目的地CFS",
  "asn_number": "ASN 號碼",
  "at_risk": "存在風險",
  "ata": "到達 (ATA)",
  "ata_first_amer": "實際抵達美洲進口站日期",
  "ata_first_amer_port": "美洲進口站",
  "ata_route_1_port": "轉運港",
  "ata_to_port_arrival": "實際抵達抵達港時間",
  "atd": "出發 (ATD)",
  "attachment": "附件",
  "aw_gw": "實際重/毛重",
  "bol_group_rule": "貨件合併到送貨單(BOL)規則",
  "bol_no": "送貨單號",
  "booked": "預定",
  "booking_date": "預定日期",
  "booking_number": "預定號碼",
  "brokerage": "報關行",
  "cancelled_at": "取消",
  "cargo_receipt": "收貨日期",
  "cargo_receipt_date": "收貨時間",
  "carrier_released": "承運商放貨時間",
  "cartons": "箱數",
  "category": "分類",
  "cbm": "立方公尺",
  "chargeable_weight": "收費重量 (Kg)",
  "checked_ata_and_eta": "更新 ATA：%{ata} 已超過 ETA：%{eta}",
  "checked_atd_and_etd": "更新 ATD：%{atd} 已超過 ETD：%{etd}",
  "checked_pod_and_edd": "更新 POD：%{pod} 已超過 EDD：%{edd}",
  "city": "城市",
  "client_id": "客戶ID",
  "cod_amount": "COD 金額",
  "code": "客戶代號",
  "comment": "評論",
  "comment_here": "留下評論",
  "commercial_Inv": "商業發票號碼",
  "commercial_invoice_number": "商業發票號碼",
  "commercial_invoice_numbers": "商業發票號",
  "commercial_Invoice_value": "商業發票金額",
  "completed_date": "完成時間",
  "confirm_destination_documents": "確認目的地文件",
  "confirm_origin_documents": "確認始發地文件",
  "confirmed": "已確認",
  "consignee": "收貨方",
  "consignee_address": "收貨方地址",
  "consignee_city": "收貨方代碼",
  "consignee_city_name": "收貨方城市",
  "consignee_contact": "收貨方聯繫人",
  "consignee_country": "收貨方國家/地區",
  "consignee_email": "收貨方電子郵件",
  "consignee_id": "收貨方ID",
  "consignee_info": "收貨資訊",
  "consignee_name": "收貨方",
  "consignee_state": "收貨方州別",
  "consignee_tax_id": "收貨人的稅號",
  "consignee_telephone": "收貨方電話",
  "consignee_zipcode": "收貨方郵遞區碼",
  "consol_closed_at": "Console Close",
  "contact": "聯絡人",
  "container_loading_type": "Container Loading Type",
  "container_mode": "貨櫃模式",
  "container_seal_number": "貨櫃號碼",
  "container_type": "貨櫃類型",
  "container_type_description": "貨櫃類型描述",
  "copy_edd_from_export": "複製出口站EDD時間",
  "country": "國家/地區",
  "country_of_origin": "原產地",
  "create_date_utc": "新增日期(UTC)",
  "created_by": "新增人員",
  "cubic_meter": "立方米",
  "currency": "幣別",
  "custom_cleared": "出口清關日期",
  "customer": "客戶",
  "customer_code": "客戶代碼",
  "customer_code_name": "客戶代碼/名稱",
  "customer_special_requirement_sla": "客戶特殊需求 / SLA",
  "customs_cleared": "出口清關日期",
  "customs_released": "進口清關日期",
  "customs_value": "海關價值",
  "customized_shipment_data": "Customized shipment data",
  "customized_shipment_data_select_message": "Please select the report name.",
  "customized_shipment_data_select_hint": "If you couldn’t see the list, please contact Mec Onehelp %{mail} for further permission",
  "cw": "計費重量",
  "dangerous_good": "危險品",
  "delete": "刪除",
  "delivery": "送貨",
  "delivery_chargeable_weight": "Delivery 收費重量",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery 毛重",
  "delivery_loose_cartons": "Delivery 散箱",
  "delivery_method": "遞送方式",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery 包裹數",
  "delivery_port": "交貨港",
  "delivery_port_city": "交貨港城市",
  "delivery_port_country": "交貨港國別",
  "delivery_total_cartons": "Delivery 箱數",
  "delivery_total_pallets": "Delivery 棧板數量",
  "delivery_total_pieces": "Delivery 件數",
  "delivery_volumetric_weight": "Delivery 體積重量",
  "dep": "部門",
  "department": "部門",
  "department_number": "部門",
  "departure_city": "出發城市",
  "departure_from_shipper_warehouse": "離倉發運日期",
  "description_of_goods": "貨物說明",
  "dest_custom_released": "進口清關時間",
  "dest_customs_released": "進口站海關放行",
  "destination": "目的地",
  "destination_city": "目的城市",
  "destination_customs_started_at": "目的站報關開始日期",
  "destination_port": "目的站",
  "destination_port_city": "目的站城市",
  "destination_port_country": "目的站國別",
  "destination_region": "進口站地區",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "調度單號",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "創建例外事件通知客戶?",
  "doc_received_at": "文件接收日期",
  "doc_released": "放單時間",
  "doc_submitted_at": "文件提交日期",
  "doc_to_broker_at": "文件至報關行",
  "document_released": "放單時間",
  "edi_send_date_time_utc": "EDI 發送時間",
  "edi_status_code": "EDI 狀態碼",
  "estimated_delivery_date": "預計送貨日期",
  "estimated_delivery_date_sla": "預計送貨日期(SLA)",
  "estimated_pickup_at": "預計提貨日",
  "eta": "最後預計到達時間",
  "etd": "首次預計出發時間",
  "event_date_and_time_local": "活動時間 (Local)",
  "event_description": "活動描述",
  "event_location": "活動地點",
  "event_routing": "事件與航線",
  "exception": "例外",
  "exceptions": "例外",
  "export": "匯出",
  "export_milestones": "出口",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "魚類與野生動物",
  "freight_term": "付款條款",
  "from": "自",
  "from_tms": "來自 TMS",
  "gross_weight": "毛重",
  "handed_to_carrier": "交付承運人時間",
  "handling_pieces": "件數",
  "hawb": "提單",
  "hawb_detail": "HAWB 詳情",
  "hazardous": "有害物",
  "height": "高",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "查無此ID",
  "import": "進口",
  "import_milestones": "進口",
  "incoterm": "國貿條規",
  "input_more_than_the_next_seven_days_is_not_allowed": "不允許輸入超過未來七天",
  "instruction": "指示",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "國際關稅和稅收",
  "is_customs_clearance": "是否需要清關",
  "item": "項目 #",
  "item_quantity": "項目數量",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "最後併裝箱關閉日期",
  "length": "長",
  "line_item_class_number": "Class Number",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "散箱",
  "loose_cartons": "散箱數量",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "主單號",
  "max_length_two": "最大長度為2",
  "milestone": "里程碑",
  "milestone_utc": "里程碑 (UTC)",
  "milestone_status": "貨態時間查詢",
  "morrison_one_customer": "Morrison One Customer",
  "name": "客戶名稱",
  "need_resubmission": "需要重新上傳",
  "no": "否",
  "no_select_shipment_list": "無選擇需要更新的HAWB",
  "notify_party_address": "通知方地址",
  "notify_party_city": "通知方代碼",
  "notify_party_city_name": "通知方城市",
  "notify_party_country": "通知方國家/地區",
  "notify_party_id": "通知方ID(AMAT)",
  "notify_party_name": "通知方",
  "notify_party_state": "通知方州別",
  "notify_party_zipcode": "通知方郵遞區碼",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "外出遞送時間",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "辦公室",
  "onboard_date": "上船日期",
  "order": "訂單 #",
  "order_number": "訂單編號",
  "orig_port_of_loading": "裝運港",
  "origin": "始發地",
  "origin_customs_broker": "出口報關行",
  "origin_customs_cleared": "出口清關日期",
  "origin_pickup_vechicle_registration": "提貨人員牌照",
  "origin_pickup_vehicle_driver": "提貨司機",
  "hawb_hbl": "HAWB / HBL",
  "orig": "出口站",
  "dest": "進口站",
  "origin_status": "出口站狀態",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
  "dest_mawb_mbl": "進口站 (by MAWB / MBL)",
  "dest_status": "進口站狀態",
  "dn_sid": "DN/SID",
  "doc_status": "檔案狀態",
  "origin_pickup_vehicle_registration_plate": "始發站提貨卡車牌號",
  "origin_port": "出口站",
  "origin_port_city": "出口站城市",
  "origin_port_country": "出口站國別",
  "out_for_delivery_date": "外出遞送",
  "out_of_gauge": "超出規格",
  "pack": "包裝 #",
  "package": "包裝",
  "package_detail": "包裹細節",
  "package_quantity": "包裹數",
  "packing_info": "包裝資訊",
  "part_description": "物料名稱",
  "part_number": "料號",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款給承運人",
  "pick_up_date": "實際提貨時間",
  "pickup_address": "提貨人地址",
  "pickup_contactor": "提貨聯絡人",
  "pickup_info": "提貨資訊",
  "pickup_telephone": "提貨人電話",
  "pickup_up": "提貨",
  "po_item_number": "PO項目編號",
  "po_number": "採購訂單號碼",
  "pod_delivery_date": "實際送達日期",
  "pod_doc_checked": "POD 檔案已檢核",
  "pod_doc_checked_messages": "您已檢查此文件, 我們的客戶將可以看得到此文件",
  "pod_doc_unchecked_messages": "您已取消檢查此文件，我們的客戶將無法看此文件",
  "pod_name": "簽收人",
  "pod_signed_by": "簽收人",
  "port_of_receipt": "收貨港",
  "port_of_receipt_city": "收貨港城市",
  "port_of_receipt_country": "收貨港國別",
  "preview": "預覽",
  "product_characteristic": "產品特徵",
  "product_type": "產品類型",
  "project_code": "專案代號",
  "prolink_export_created_by": "出口站創建者",
  "prolink_import_created_by": "進口站創建者",
  "proof_of_delivery_location": "簽收地",
  "quantity": "數量",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "提貨準備完成時間",
  "reason": "理由",
  "received_from_airline": "從航空公司收到時間",
  "reference": "參考",
  "reference_no": "參考單號",
  "reference_number": "AMAT 參考號碼",
  "reference_type": "參考類型",
  "reference_value_di": "DI 參考值",
  "reference_value_in": "IN 參考值",
  "reference_value_po": "PO 參考值",
  "reference_value_sr": "SR 參考值",
  "reference_value_undefined": "未定義參考值",
  "release_location": "放單地點",
  "release_to": "放單對象",
  "release_type": "放單類型",
  "requested_sailing_date": "要求航行日期",
  "required": "必要的",
  "required_arrival_time": "要求到貨時間",
  "required_pick_up_time": "要求提貨時間",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} 序號",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "卸貨港",
  "security_level": "安全級別",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "選擇需要更新的HAWB",
  "select_milestones_to_update": "選擇需要更新的事件",
  "sequence": "序號",
  "service_level": "服務等級",
  "service_type": "服務類型",
  "ship_date": "起運日期",
  "shipment_details": "貨件詳情",
  "shipment_mode": "Ship Mode",
  "shipment_number": "貨物號碼",
  "shipment_status": "貨物狀態",
  "shipment_type": "Shipment Type",
  "shipper": "發貨方",
  "shipper_address": "發貨方地址",
  "shipper_city": "發貨方代碼",
  "shipper_city_name": "發貨方城市",
  "shipper_contact": "出貨方聯繫人",
  "shipper_country": "發貨方國家/地區",
  "shipper_email": "出貨方電子郵件",
  "shipper_id": "發貨方ID(AMAT)",
  "shipper_name": "發貨方",
  "shipper_state": "發貨方州別",
  "shipper_tax_id": "出貨人的稅號",
  "shipper_telephone": "出貨方電話",
  "shipper_zipcode": "發貨方郵遞區碼",
  "shipping_instructions": "運輸說明",
  "signed_by": "簽收人",
  "sla": "服務水準約定",
  "something_wrong": "出錯了 !",
  "special_delivery_instruction": "特殊遞送指示",
  "special_instruction": "特殊指示",
  "split_shipment": "拆HAWB",
  "state": "州/省",
  "status": "狀態",
  "submitted_to_customs": "提交至海關",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "電話",
  "this_vendor_doesnt_have_amat_id": "此供應商沒有AMAT ID",
  "this_vendor_doesnt_have_it": "此供應商沒有此項目",
  "tianma_edi_alert": "此提單號尚未與一個天馬調度單號%{id}關聯. 請檢查是否調度單號碼輸入有誤並修正",
  "tianma_id": "天馬ID",
  "to": "至",
  "total_carton": "總箱數",
  "total_cartons": "箱子總數",
  "total_gross_weight_kg": "總毛重(KG)",
  "total_net_weight_kg": "總淨重(KG)",
  "total_packages": "包裹數",
  "total_pallet": "總托數",
  "total_pallets": "棧板數量",
  "total_pieces": "總件數",
  "total_volume": "總體積",
  "transshipment_station": "站點",
  "transshipment_type": "轉運類型",
  "type": "Type",
  "unit": "單位",
  "unit_value": "單位價值",
  "uom": "測量單位",
  "update_to_address_book": "更新到地址薄",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "上傳文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_in_progress": "進行中",
  "urgent": "急件",
  "urgent_delivery": "急件",
  "vessel_name": "船名",
  "volume": "體積",
  "volumetric_factor": "體積係數",
  "volumetric_unit": "體積單位",
  "volumetric_weight": "體積重量",
  "voyage": "航程",
  "vw": "體積重量",
  "weight_unit": "重量單位",
  "width": "寬",
  "yes": "是",
  "zipcode": "郵編",
  "required_edi": "需要的EDI",
  "edi_invoice": "EDI 發票",
  "edi_milestone": "EDI 事件",
  "edi_inbound": "客戶EDI接收",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "請檢查",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "Shipment Container Mode 為空，請檢查！",
  "document_no_or_commerce_invoice_no": "文件號碼 / 商業發票號碼",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "應該和 %{routing_number} 的 %{port} 相同",
  "samsung_document_no": "文件號碼",
  "samsung_invoice_no": "發票號碼",
  "samsung_document_no_and_invoice_no": "文件號碼 / 發票號碼",
  "samsung_document_no_placeholder": "使用逗號 (,) 分隔多筆文件號碼",
  "destination_pickup_vehicle_driver": "終點站提貨司機",
  "destination_pickup_vehicle_registration_plate": "終點站提貨卡車牌號",
  "arrival_origin_at": "抵達出口航站日期",
  "departure_from_discharging_port_at": "卸貨港出發",
  "arrived_cfs_at": "抵達目的CFS時間",
  "departure_from_cfs_at": "OFD (CFS 出發)",
  "samsung_edi_alert": "此提單號尚未與一個 Samsung 調度單號 %{id} 關聯. 請檢查是否調度單號碼輸入有誤並修正",
  "edi_message_departure_port": "始發地",
  "edi_message_destination_port": "目的地",
  "edi_message_document_date": "文件日期",
  "edi_message_ship_date": "起運日期",
  "edi_message_transportation_type": "運輸類型",
  "edi_message_order_type": "訂單類型",
  "edi_message_payment_method": "付款方式",
  "edi_message_inco_term": "國貿條規",
  "edi_message_business_type": "業務類型",
  "edi_message_reference_document_number": "參考文件號碼",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "包裹數",
  "edi_message_total_pieces": "總件數",
  "edi_message_total_volume": "總體積",
  "edi_message_total_gross_weight": "總毛重",
  "edi_message_total_net_weight": "總淨重",
  "edi_message_invoice_number": "發票號碼",
  "edi_message_invoice_amount": "發票金額",
  "edi_message_freight_amount": "運費金額",
  "edi_message_shipper_or_sender": "出貨方",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "出貨方聯繫人",
  "edi_message_shipper_phone_or_email": "出貨方電話/電郵",
  "edi_message_shipper_address": "出貨方地址",
  "edi_message_consignee": "收貨方",
  "edi_message_consignee_contact": "收貨方聯繫人",
  "edi_message_consignee_phone_or_email": "收貨方電話/電郵",
  "edi_message_consignee_address": "收貨方地址",
  "edi_message_buyer": "購買方",
  "edi_message_buyer_contact": "購買方聯繫人",
  "edi_message_buyer_phone_or_email": "購買方電話/電郵",
  "edi_message_buyer_address": "購買方地址",
  "edi_message_packing_detail": "包裝細節",
  "edi_message_part_number": "Part 號碼",
  "edi_message_part_description": "品項描述",
  "edi_message_packing_quantity": "包裹數",
  "edi_message_pieces_quantity": "件數",
  "edi_message_gross_weight": "毛重",
  "edi_message_net_weight": "淨重",
  "edi_message_volume": "體積",
  "edi_message_unit_price_amount": "單價金額",
  "edi_message_other_charge_amount": "其他收費金額",
  "container": "貨櫃",
  "add_container": "新增貨櫃",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "bulk_update": "批量更新",
  "bulk_edit_shipment_reference_no": "批量更新Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "創建時間(UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "提貨時間",
  "customs_cleared_at": "出口清關",
  "customs_released_at": "進口清關",
  "shipment_not_existed": "Shipment號碼不存在",
  "containe_invalidate_char": "存在無效字元",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "Shipment號碼重複",
  "reference_bulk_update_upload_memo": "或於表格中貼上資料",
  "reference_bulk_update_upload_memo_description": "(如果選擇新檔案，所有已輸入的資料將會被覆蓋; 換行符號將被取代成逗號)",
  "master_ata": "MAWB - ATA",
  "doc_released_at": "文件釋出",
  "ship_to": "Ship To",
  "arrive_forward_gateway": "Arrived at Gateway",
  "depart_forward_gateway": "OFD (Departed from Gateway)",
  "scac": "SCAC",
  "invalid_scac_code": "SCAC Code is incorrect, please check!",
  "invalid_datetime": "format must be YYYY-MM-DD",
  "Invalid_file": "Invalid file. Please upload a .csv file.",
  "Invalid_file_header_name": "Please upload a file with correct header name(s): %{headers}",
  "Invalid_file_lose_main_field": "You need to upload a file with Container No. and HBL# column.",
  "system_source": "系統來源",
  "receive_booking_req_date": "Rcv. Booking Req. Date",
  "cargo_stuffing_date": "Cargo Stuffing Date",
  "arrived_at_cfs_in_origin": "Arrived at CFS in Origin",
  "full_in_gate_in_origin": "Full in Gate in Origin",
  "document_submitted": "Document Submitted",
  "full_outgate": "Full Outgage",
  "arrive_origin_terminal": "Arrive Origin Terminal",
  "arrival_at_origin_port": "Arrival at Origin Port",
  "depart_from_destination_port": "Depart From Dest Port",
  "cargo_arrived_at_terminal": "Cargo Arrived At Terminal",
  "estimated_pickup": "預計提貨日",
  "booking_confirmed": "Booking Confirmation(SO Released)",
  "shipping_doc_received_date": "Shipping Docs Received Date",
  "appointment_made": "Appointment of POD Made",
  "estimated_pod": "Estimated Delivery",
  "appointment_confirmed": "Appointment of POD Confirmed",
  "cause": "Cause",
  "customized_cause": "Customized Cause",
  "ship_to_no_data_warning": "Please update Notify Part Info on Prolink/CW1.",
  "apple_fd_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "apple_gar_routing_readonly_waring_message": "Please update milestone in Apple Trck!",
  "error_code_search_not_found": "Resource is not found!",
  "orig_customs_inspection_type": "Origin Customs Inspection Type",
  "dest_customs_inspection_type": "Dest Customs Inspection Type",
  "customs": "報關",
  "customer_required": "Customer Required",
  "customer_reference": "參考",
  "report": "Report",
  "report_shipment_status": "Shipment Status",
  "report_forwarder_name": "Forwarder Name",
  "report_lane_id": "Lane ID",
  "report_cargo_type": "Cargo Type",
  "report_good_description": "Good Description",
  "report_gen_size": "Gen Size",
  "report_product_code": "Product Code",
  "report_product_size": "Product Size",
  "report_change_to_low_bed": "Change to Low-Bed",
  "report_air_ride_for_inland": "Air Ride for Inland",
  "report_gw": "gw",
  "report_sheet_qty": "Sheet Qty",
  "report_container_no": "Container No.",
  "report_carrier": "Carrier",
  "report_ex_plant_day": "Ex-Plant Day",
  "report_etd_org_port": "ETD Org Port",
  "report_cargo_delay_days_1": "Cargo Delay Days 1",
  "report_cargo_delay_reason_1": "Cargo Delay Reason 1",
  "report_atd_org_port": "ATD Org Port",
  "report_eta_transit_port": "ETA Transit Port",
  "report_incorrect_docs_received": "Incorrect Docs Received?",
  "report_incorrect_docs_reason": "Incorrect Docs Reason",
  "report_completed_and_correct_custom_clearance_docs_sent_to_broker": "Completed and correct Custom Clearance Docs Sent to Broker",
  "report_cargo_delay_days_2": "Cargo Delay Days 2",
  "report_cargo_delay_reason_2": "Cargo Delay Reason 2",
  "report_ata_transit_port": "ATA Transit Port",
  "report_etd_transit_port": "ETD Transit Port",
  "report_cargo_delay_days_3": "Cargo Delay Days 3",
  "report_cargo_delay_reason_3": "Cargo Delay Reason 3",
  "report_atd_transit_port": "ATD Transit Port",
  "report_eta_gezhouba": "ETA Gezhouba (到达葛洲坝)",
  "report_ata_gezhouba": "ATA Gezhouba (到达葛洲坝)",
  "report_eta_3gorges": "ETA 3gorges (到达三峡)",
  "report_ata_3gorges": "ATA 3gorges (已到达三峡)",
  "report_etd_3gorges": "ETD 3gorges (已过三峡)",
  "report_atd_3gorges": "ATD 3gorges (已过三峡)",
  "report_eta_dest_port": "ETA Dest. Port",
  "report_cargo_delay_days_4": "Cargo Delay Days 4",
  "report_ata_dest_port": "ATA Dest. Port",
  "report_c_c_mode": "C/C Mode",
  "report_customs_declaration_date": "Customs Declaration Date",
  "report_customs_inspection": "Customs Inspection",
  "report_customs_inspection_reason": "Customs Inspection Reason",
  "report_customs_inspection_released": "Customs Inspection Released",
  "report_customs_declaration_sheet_no": "Customs Declaration Sheet No.",
  "report_import_duty": "Import Duty",
  "report_import_vat": "Import VAT",
  "report_vat_or_deposit_release": "VAT or Deposit Release",
  "report_customs_cleared": "Customs Cleared",
  "report_etd_dest_port": "ETD Dest. Port",
  "report_cargo_delay_days_5": "Cargo Delay Days 5",
  "report_atd_dest_port": "ATD Dest. Port",
  "report_eta_final_destination": "ETA Final Destination",
  "report_cargo_delay_days_6": "Cargo Delay Days 6",
  "report_ata_final_destination": "ATA Final Destination",
  "report_empty_equipment_return": "Empty Equipment Return",
  "report_warehouse": "Warehouse",
  "report_demurrage_cost": "Demurrage Cost",
  "report_demurrage_reason": "Demurrage Reason",
  "report_detention_day": "Detention Day",
  "report_detention_cost": "Detention Cost",
  "report_detention_reason": "Detention Reason",
  "report_other_cost": "Other Cost",
  "report_actual_l_t": "Actual L/T",
  "report_required_l_t": "Required L/T",
  "report_plant_tracking": "Plant Tracking#",
  "report_remark": "Remark",
  "report_shipper": "Shipper",
  "report_consignee": "Consignee",
  "report_shipping_mode": "Shipping Mode",
  "report_incoterms": "Incoterms",
  "report_po_number": "PO#",
  "report_commercial_invoice_number": "Inv No.",
  "report_master_number": "MBL#",
  "report_shipment_number": "HBL#",
  "report_loading_port": "Loading Port",
  "report_container_type": "Container Type",
  "report_packing_type": "Packing Type",
  "report_vessel_name": "Vessel name",
  "report_amount": "Amount",
  "report_currency": "Currency",
  "report_completed_and_correct_custom_clearance_docs_received_to_broker": "Completed and Correct Custom Clearance Docs Received by Broker",
  "report_shipment_create_date": "SHIPMENT CREATE DATE",
  "container_not_existed": "Container No. under HBL# can not be found.",
  "duplicate_container": "Container No. under HBL# is duplicate",
  "shipment_not_key_in": "HBL# can not be blank.",
  "container_not_key_in": "Container No can not be blank.",
  "invalidate_datetime": "Format must be YYYY/MM/DD.",
  "report_no_column": "This report no column data",
  "event_routing_actual_pickup_at": "實際提貨時間",
  "report_step1_title": "Step 1: General Upload",
  "report_step2_title": "Step 2: Review Result",
  "report_step2_title_desc": "The listed results including blank field will be uploaded. Please make sure all data is correct.",
  "report_msrnt": "MSRNT",
  "report_via": "VIA",
  "invalidate_string": "Format must be string.",
  "invalidate_integer": "Format must be integer",
  "invalidate_float": "Format must be float",
  "Shipment_Tracking_Report": "Shipment Tracking Report",
  "report_cargo_delay_reason_4": "Cargo Delay Reason 4",
  "report_cargo_delay_reason_5": "Cargo Delay Reason 5",
  "report_cargo_delay_reason_6": "Cargo Delay Reason 6",
  "invalidate_number": "Format must be number.",
  "Invalid_excel_file": "Invalid file. Please upload an Excel file (xlsx, xls).",
  "hbl_not_existed": "HBL# 不存在",
  "report_check_first_memo": "Please rectify all the error in step 1 first then you can click \"Update\".",
  "destination_customs_start_at": "Destination Customs Start At",
  "import_customs_release_at": "Import Customs Release At"
 },
 "forwarder": {
  "actual_appointment_date": "實際預約日期",
  "actual_arrive_first_us_ca_dest": "實際抵達美洲進口港時間",
  "actual_arrive_transshipment_port": "實際抵達轉運港時間",
  "actual_delivery_date": "實際運送日",
  "actual_depart_port_at": "實際出口站啟程日期",
  "actual_depart_transshipment_port": "實際轉運港出發時間",
  "actual_departed_port_of_export": "出口港實際出發日期",
  "actual_pickup_date": "實際提貨日期",
  "address": "地址",
  "address_in_local_language": "當地語言地址",
  "address_line_one": "地址行 1",
  "address_line_two": "地址行 2",
  "address_will_be_updated_which_shipments_not_be_assigned_to_bol": "更新地址到收貨地址相同且尚未建立送貨單的貨件",
  "adjust_column": "調整欄位",
  "agent_id": "Agent ID",
  "agent_role": "H Agent",
  "air_ride": "Air Ride",
  "airport": "機場",
  "alert": "警示",
  "amat_edi_alert": "此提單號尚未與一個 AMAT ID %{id} 關聯. 請檢查是否號碼輸入有誤並修正",
  "amat_id": "AMAT ID",
  "apply_to_all_shipments": "更新到符合條件的貨物",
  "appointment_confirmed_date": "預約確認日期",
  "appointment_confirmed_number": "預約確認號碼",
  "appointment_made_by": "預約發起人",
  "appointment_made_date": "預約發起日期",
  "appointment_made_method": "預約發起管道",
  "appointment_made_with": "預約發起方式",
  "appointment_number": "預約號碼",
  "arrival_city": "抵達城市",
  "arrival_terminal_at": "抵達出口航站日期",
  "arrived_at_cfs": "抵達目的CFS時間",
  "arrived_ata": "到達 (ATA)",
  "arrived_cfs": "抵達目的地CFS",
  "asn_number": "ASN 號碼",
  "at_risk": " At Risk",
  "ata": "到達 (ATA)",
  "ata_first_amer": "實際抵達美洲進口站日期",
  "ata_first_amer_port": "美洲進口站",
  "ata_route_1_port": "轉運港",
  "ata_to_port_arrival": "實際抵達抵達港時間",
  "atd": "出發 (ATD)",
  "attachment": "附件",
  "aw_gw": "實際重/毛重",
  "bol_group_rule": "貨件合併到送貨單(BOL)規則",
  "bol_no": "送貨單號",
  "booked": "預定",
  "booking_date": "預定日期",
  "booking_number": "預定號碼",
  "brokerage": "報關行",
  "cancel": "取消",
  "cancelled_at": "取消",
  "cargo_receipt": "收貨日期",
  "cargo_receipt_date": "收貨時間",
  "carrier_released": "承運商放貨時間",
  "cartons": "箱數",
  "category": "分類",
  "cbm": "立方公尺",
  "chargeable_weight": "收費重量 (Kg)",
  "checked_ata_and_eta": "更新 ATA：%{ata} 已超過 ETA：%{eta}",
  "checked_atd_and_etd": "更新 ATD：%{atd} 已超過 ETD：%{etd}",
  "checked_pod_and_edd": "更新 POD：%{pod} 已超過 EDD：%{edd}",
  "city": "城市",
  "client_id": "客戶ID",
  "cod_amount": "COD 金額",
  "code": "客戶代號",
  "comment": "評論",
  "comment_here": "留下評論",
  "comment_category": "評論類別",
  "commercial_Inv": "商業發票號碼",
  "commercial_invoice_number": "商業發票號碼",
  "commercial_invoice_numbers": "商業發票號",
  "commercial_Invoice_value": "商業發票金額",
  "completed_date": "完成時間",
  "confirm_destination_documents": "確認目的地文件",
  "confirm_origin_documents": "確認始發地文件",
  "confirmed": "已確認",
  "consignee": "收貨方",
  "consignee_address": "收貨方地址",
  "consignee_city": "收貨方代碼",
  "consignee_city_name": "收貨方城市",
  "consignee_contact": "收貨方聯繫人",
  "consignee_country": "收貨方國家/地區",
  "consignee_email": "收貨方電子郵件",
  "consignee_id": "收貨方ID(AMAT)",
  "consignee_info": "收貨資訊",
  "consignee_name": "收貨方",
  "consignee_state": "收貨方州別",
  "consignee_tax_id": "收貨人的稅號",
  "consignee_telephone": "收貨方電話",
  "consignee_zipcode": "收貨方ZIP",
  "consol_closed_at": "Console Close",
  "contact": "聯絡人",
  "container_loading_type": "Container Loading Type",
  "container_mode": "貨櫃模式",
  "container_seal_number": "貨櫃號碼",
  "container_type": "貨櫃類型",
  "container_type_description": "貨櫃類型描述",
  "copy_edd_from_export": "複製出口站EDD時間",
  "country": "國家/地區",
  "country_of_origin": "原產地",
  "create_date_utc": "新增日期(UTC)",
  "created_by": "新增人員",
  "cubic_meter": "立方米",
  "currency": "幣別",
  "custom_cleared": "出口清關日期",
  "customer": "客戶",
  "customer_code": "客戶代碼",
  "forwarder_code_name": "客戶代碼/名稱",
  "forwarder_name": "貨運承攬商",
  "customer_code_name": "客戶代碼/名稱",
  "customer_special_requirement_sla": "客戶特殊需求 / SLA",
  "customs_cleared": "出口清關日期",
  "customs_released": "進口清關日期",
  "customs_value": "海關價值",
  "cw": "計費重量",
  "dangerous_good": "危險品",
  "delete": "刪除",
  "delivery": "送貨",
  "delivery_chargeable_weight": "Delivery 收費重量",
  "delivery_cubic_meter": "Delivery CBM",
  "delivery_gross_weight": "Delivery 毛重",
  "delivery_loose_cartons": "Delivery 散箱",
  "delivery_method": "遞送方式",
  "delivery_no": "Delivery No",
  "delivery_package_quantity": "Delivery 包裹數",
  "delivery_port": "交貨港",
  "delivery_port_city": "交貨港城市",
  "delivery_port_country": "交貨港國別",
  "delivery_total_cartons": "Delivery 箱數",
  "delivery_total_pallets": "Delivery 棧板數量",
  "delivery_total_pieces": "Delivery 件數",
  "delivery_volumetric_weight": "Delivery 體積重量",
  "dep": "部門",
  "department": "部門",
  "department_number": "部門",
  "departure_city": "出發城市",
  "departure_from_shipper_warehouse": "離倉發運日期",
  "description_of_goods": "貨物說明",
  "dest_custom_released": "進口清關時間",
  "dest_customs_released": "進口站海關放行",
  "destination": "目的地",
  "destination_city": "目的城市",
  "destination_customs_started_at": "目的站報關開始日期",
  "destination_port": "目的站",
  "destination_port_city": "目的站城市",
  "destination_port_country": "目的站國別",
  "destination_region": "進口站地區",
  "dim": "DIM",
  "dispatch_no": "Dispatch No",
  "dispatch_number": "調度單號",
  "do_you_want_to_raise_an_irregularity_to_alert_the_customer": "創建例外事件通知客戶?",
  "doc_received_at": "文件接收日期",
  "doc_released": "放單時間",
  "doc_submitted_at": "文件提交日期",
  "doc_to_broker_at": "文件至報關行",
  "document_released": "放單時間",
  "edi_send_date_time_utc": "EDI 發送時間",
  "edi_status_code": "EDI 狀態碼",
  "estimated_delivery_date": "預計送貨日期",
  "estimated_delivery_date_sla": "預計送貨日期(SLA)",
  "estimated_pickup_at": "預計提貨日",
  "eta": "最後預計到達時間",
  "etd": "首次預計出發時間",
  "event_date_and_time_local": "活動時間 (Local)",
  "event_description": "活動描述",
  "event_location": "活動地點",
  "event_routing": "事件與航線",
  "exception": "例外",
  "exceptions": "例外",
  "export": "匯出",
  "export_milestones": "出口",
  "factor": "Factor",
  "fcr": "FCR",
  "fish_and_wildlife": "魚類與野生動物",
  "freight_term": "付款條款",
  "from": "自",
  "from_tms": "From TMS",
  "gross_weight": "毛重",
  "handed_to_carrier": "交付承運人時間",
  "handling_pieces": "件數",
  "hawb": "提單",
  "hawb_detail": "HAWB 詳情",
  "hazardous": "有害物",
  "height": "高",
  "hts_code": "HS Code",
  "id": "ID",
  "id_not_found": "查無此ID",
  "import": "進口",
  "import_milestones": "進口",
  "incoterm": "國貿條規",
  "input_more_than_the_next_seven_days_is_not_allowed": "不允許輸入超過未來七天",
  "instruction": "指示",
  "internal_transaction_number": "ITN#",
  "international_duty_taxes": "國際關稅和稅收",
  "is_customs_clearance": "是否需要清關",
  "item": "項目 #",
  "item_quantity": "項目數量",
  "itn_mrn": "ITN # / MRN #",
  "last_consol_closed_at": "最後併裝箱關閉日期",
  "length": "長",
  "line_item_class_number": "Class Number",
  "logistic_handling": "物流操作",
  "logistic_number": "LOG No",
  "loose_carton_quantity": "散箱",
  "loose_cartons": "散箱數量",
  "lot_number": "Lot No",
  "master_agent_id": "Master Agent ID",
  "master_agent_role": "Consol Agent",
  "master_number": "主單號",
  "max_length_two": "最大長度為2",
  "milestone": "里程碑",
  "milestone_utc": "里程碑 (UTC)",
  "milestone_status": "貨態時間查詢",
  "morrison_one_customer": "Morrison One Customer",
  "name": "客戶名稱",
  "need_resubmission": "需要重新上傳",
  "no": "否",
  "no_select_shipment_list": "無選擇需要更新的HAWB",
  "notify_party_address": "通知方地址",
  "notify_party_city": "通知方代碼",
  "notify_party_city_name": "通知方城市",
  "notify_party_country": "通知方國家/地區",
  "notify_party_id": "通知方ID(AMAT)",
  "notify_party_name": "通知方",
  "notify_party_state": "通知方州別",
  "notify_party_zipcode": "通知方ZIP",
  "npi": "NPI",
  "number": "Number",
  "ofd_departed_from_cfs": "外出遞送時間",
  "off_load_at": "Offload",
  "off_loaded_at": "Off Loaded Date",
  "office": "辦公室",
  "onboard_date": "上船日期",
  "order": "訂單 #",
  "order_number": "訂單編號",
  "orig_port_of_loading": "裝運港",
  "origin": "始發地",
  "origin_customs_broker": "出口報關行",
  "origin_customs_cleared": "出口清關日期",
  "origin_pickup_vechicle_registration": "提貨人員牌照",
  "origin_pickup_vehicle_driver": "提貨司機",
  "hawb_hbl": "HAWB / HBL",
  "orig": "出口站",
  "dest": "進口站",
  "orig_region": "出口地區",
  "dest_region": "進口地區",
  "origin_status": "出口站狀態",
  "mawb_mbl": "MAWB / MBL",
  "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
  "dest_mawb_mbl": "進口站 (by MAWB / MBL)",
  "dest_status": "進口站狀態",
  "dn_sid": "DN/SID",
  "doc_status": "檔案狀態",
  "origin_pickup_vehicle_registration_plate": "始發站提貨卡車牌號",
  "origin_port": "出口站",
  "origin_port_city": "出口站城市",
  "origin_port_country": "出口站國別",
  "out_for_delivery_date": "外出遞送",
  "out_of_gauge": "超出規格",
  "pack": "包裝 #",
  "package": "包裝",
  "package_detail": "包裹細節",
  "package_quantity": "包裹數",
  "packing_info": "包裝資訊",
  "part_description": "物料名稱",
  "part_number": "料號",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款給承運人",
  "pick_up_date": "實際提貨時間",
  "pickup_address": "提貨人地址",
  "pickup_contactor": "提貨聯絡人",
  "pickup_info": "提貨資訊",
  "pickup_telephone": "提貨人電話",
  "pickup_up": "提貨",
  "po_item_number": "PO項目編號",
  "po_number": "採購訂單號碼",
  "pod_delivery_date": "簽收時間",
  "pod_doc_checked": "POD 已檢核",
  "pod_doc_checked_messages": "您已檢查此文件, 我們的客戶將可以看得到此文件",
  "pod_doc_unchecked_messages": "您已取消檢查此文件，我們的客戶將無法看此文件",
  "pod_name": "簽收者",
  "pod_signed_by": "POD簽名",
  "port_of_receipt": "收貨港",
  "port_of_receipt_city": "收貨港城市",
  "port_of_receipt_country": "收貨港國別",
  "preview": "預覽",
  "product_characteristic": "產品特徵",
  "product_type": "產品類型",
  "project_code": "專案代號",
  "prolink_export_created_by": "出口站創建者",
  "prolink_import_created_by": "進口站創建者",
  "proof_of_delivery_location": "簽收地",
  "quantity": "數量",
  "quotation_number": "Quotation No",
  "ready_for_pickup_at": "提貨準備完成時間",
  "reason": "理由",
  "received_from_airline": "從航空公司收到時間",
  "reference": "參考",
  "reference_no": "參考單號",
  "reference_number": "AMAT 參考號碼",
  "reference_type": "參考類型",
  "reference_value_di": "DI 參考值",
  "reference_value_in": "IN 參考值",
  "reference_value_po": "PO 參考值",
  "reference_value_sr": "SR 參考值",
  "reference_value_undefined": "未定義參考值",
  "release_location": "放單地點",
  "release_to": "放單對象",
  "release_type": "放單類型",
  "requested_sailing_date": "要求航行日期",
  "required": "必要的",
  "required_arrival_time": "要求到貨時間",
  "required_pick_up_time": "要求提貨時間",
  "routing_atd": "%{prefix} ATD",
  "routing_console_sequence": "%{prefix} 序號",
  "routing_eta": "%{prefix} ETA",
  "routing_etd": "%{prefix} ETD",
  "routing_from_port": "%{prefix} From",
  "routing_master_number": "%{prefix} MAWB / MBL",
  "routing_number": "%{prefix} Flight / Vessel No",
  "routing_to_port": "%{prefix} To",
  "routing_vessel_name": "%{prefix} Vessel Name",
  "routing_voyage_name": "%{prefix} Voyage",
  "sea_destination_port": "卸貨港",
  "security_level": "安全級別",
  "security_level_handling": "Security Level Handling",
  "select_hawbs_to_apply": "選擇需要更新的HAWB",
  "select_milestones_to_update": "選擇需要更新的事件",
  "sequence": "序號",
  "service_level": "服務等級",
  "service_type": "服務類型",
  "ship_date": "起運日期",
  "shipment_details": "貨件詳情",
  "shipment_mode": "Ship Mode",
  "shipment_number": "貨物號碼",
  "shipment_status": "貨物狀態",
  "shipment_type": "Shipment Type",
  "shipper": "發貨方",
  "shipper_address": "發貨方地址",
  "shipper_city": "發貨方代碼",
  "shipper_city_name": "發貨方城市",
  "shipper_contact": "出貨方聯繫人",
  "shipper_country": "發貨方國家/地區",
  "shipper_email": "出貨方電子郵件",
  "shipper_id": "發貨方ID(AMAT)",
  "shipper_name": "發貨方",
  "shipper_state": "發貨方州別",
  "shipper_tax_id": "出貨人的稅號",
  "shipper_telephone": "出貨方電話",
  "shipper_zipcode": "發貨方ZIP",
  "shipping_instructions": "運輸說明",
  "signed_by": "簽收人",
  "sla": "服務水準約定",
  "something_wrong": "出錯了 !",
  "special_delivery_instruction": "特殊遞送指示",
  "special_instruction": "特殊指示",
  "split_shipment": "拆HAWB",
  "state": "州/省",
  "status": "狀態",
  "submitted_to_customs": "提交至海關",
  "tea": "TEA #",
  "tea_number": "TEA #",
  "tel": "電話",
  "this_vendor_doesnt_have_amat_id": "此供應商沒有AMAT ID",
  "this_vendor_doesnt_have_it": "此供應商沒有此項目",
  "tianma_edi_alert": "此提單號尚未與一個天馬調度單號%{id}關聯. 請檢查是否調度單號碼輸入有誤並修正",
  "tianma_id": "天馬ID",
  "to": "至",
  "total_carton": "總箱數",
  "total_cartons": "箱子總數",
  "total_gross_weight_kg": "總毛重(KG)",
  "total_net_weight_kg": "總淨重(KG)",
  "total_packages": "包裹數",
  "total_pallet": "總托數",
  "total_pallets": "棧板數量",
  "total_pieces": "總件數",
  "total_volume": "總體積",
  "transshipment_station": "站點",
  "transshipment_type": "轉運類型",
  "type": "Type",
  "unit": "單位",
  "unit_value": "單位價值",
  "uom": "測量單位",
  "update_to_address_book": "更新到地址薄",
  "updated_success_shipment_list": "Updated Success Shipment List",
  "upload_document": "上傳文件",
  "uppercase_all": "全部",
  "uppercase_canceled": "取消",
  "uppercase_completed": "完成",
  "uppercase_in_progress": "進行中",
  "urgent": "急件",
  "urgent_delivery": "急件",
  "vessel_name": "船名",
  "volume": "體積",
  "volumetric_factor": "Volume Factor",
  "volumetric_unit": "體積單位",
  "volumetric_weight": "體積重量",
  "voyage": "航程",
  "vw": "體積重量",
  "weight_unit": "重量單位",
  "width": "寬",
  "yes": "是",
  "zipcode": "郵編",
  "required_edi": "需要的EDI",
  "edi_invoice": "EDI 發票",
  "edi_milestone": "EDI 事件",
  "edi_inbound": "客戶EDI接收",
  "empty_container_to_shipper": "Empty Out Gate at Origin",
  "full_container_loaded_at_origin_port": "Full in Gate at Origin",
  "container_available_at_discharge_port": "Discharged From POD",
  "container_out_gate_at_discharge_port": "Full Outgate",
  "container_on_rail_at_discharge_port": "On Rail",
  "container_off_rail_at_discharge_port": "Off Rail",
  "container_out_gate_at_dest_rail_station": "Full Outgate Inland",
  "empty_container_to_carrier": "Empty Container Return ",
  "please_check": "請檢查",
  "container_available_notice": "Container Available Notice",
  "no_shipment_container_mode_warning": "Shipment Container Mode 為空，請檢查！",
  "document_no_or_commerce_invoice_no": "文件號碼 / 商業發票號碼",
  "routing_ata": "ATA",
  "should_be_identical_with_port_of_routing_number": "應該和 %{routing_number} 的 %{port} 相同",
  "samsung_document_no": "文件號碼",
  "samsung_invoice_no": "發票號碼",
  "samsung_document_no_and_invoice_no": "文件號碼 / 發票號碼",
  "samsung_document_no_placeholder": "使用逗號 (,) 分隔多筆文件號碼",
  "destination_pickup_vehicle_driver": "終點站提貨司機",
  "destination_pickup_vehicle_registration_plate": "終點站提貨卡車牌號",
  "arrival_origin_at": "抵達出口航站日期",
  "departure_from_discharging_port_at": "卸貨港出發",
  "arrived_cfs_at": "抵達目的CFS時間",
  "departure_from_cfs_at": "OFD (CFS 出發)",
  "samsung_edi_alert": "此提單號尚未與一個 Samsung 調度單號 %{id} 關聯. 請檢查是否調度單號碼輸入有誤並修正",
  "edi_message_departure_port": "始發地",
  "edi_message_destination_port": "目的地",
  "edi_message_document_date": "文件日期",
  "edi_message_ship_date": "起運日期",
  "edi_message_transportation_type": "運輸類型",
  "edi_message_order_type": "訂單類型",
  "edi_message_payment_method": "付款方式",
  "edi_message_inco_term": "國貿條規",
  "edi_message_business_type": "業務類型",
  "edi_message_reference_document_number": "參考文件號碼",
  "edi_message_hsi_dentifier": "HSI",
  "edi_message_total_package": "包裹數",
  "edi_message_total_pieces": "總件數",
  "edi_message_total_volume": "總體積",
  "edi_message_total_gross_weight": "總毛重",
  "edi_message_total_net_weight": "總淨重",
  "edi_message_invoice_number": "發票號碼",
  "edi_message_invoice_amount": "發票金額",
  "edi_message_freight_amount": "運費金額",
  "edi_message_shipper_or_sender": "出貨方",
  "edi_message_plant": "Plant",
  "edi_message_shipper_contact": "出貨方聯繫人",
  "edi_message_shipper_phone_or_email": "出貨方電話/電郵",
  "edi_message_shipper_address": "出貨方地址",
  "edi_message_consignee": "收貨方",
  "edi_message_consignee_contact": "收貨方聯繫人",
  "edi_message_consignee_phone_or_email": "收貨方電話/電郵",
  "edi_message_consignee_address": "收貨方地址",
  "edi_message_buyer": "購買方",
  "edi_message_buyer_contact": "購買方聯繫人",
  "edi_message_buyer_phone_or_email": "購買方電話/電郵",
  "edi_message_buyer_address": "購買方地址",
  "edi_message_packing_detail": "包裝細節",
  "edi_message_part_number": "Part 號碼",
  "edi_message_part_description": "品項描述",
  "edi_message_packing_quantity": "包裹數",
  "edi_message_pieces_quantity": "件數",
  "edi_message_gross_weight": "毛重",
  "edi_message_net_weight": "淨重",
  "edi_message_volume": "體積",
  "edi_message_unit_price_amount": "單價金額",
  "edi_message_other_charge_amount": "其他收費金額",
  "container": "貨櫃",
  "add_container": "新增貨櫃",
  "booking_no": "Booking No",
  "po_no": "PO No",
  "so_no": "SO No",
  "bulk_update": "批量更新",
  "bulk_edit_shipment_reference_no": "批量更新Shipment References",
  "apple_fd_no": "FD No",
  "apple_pr_no": "APPLE PR NO",
  "apple_fd_manager": "FD MANAGER",
  "apple_dri": "APPLE DRI",
  "purchase_order_no": "PO No",
  "order_no": "OR No",
  "so_number_plus_ship": "S/O No",
  "do_no": "DO No",
  "delivery_id": "DI No",
  "invoice_no": "Invoice No",
  "shipment_request_number": "SR No",
  "packing_list_no": "Packing List No",
  "part_no": "Part No",
  "permit_reference": "Permit Reference",
  "item_no": "Item No",
  "umc_ref_type": "UMC Ref Type",
  "serial_no": "Serial No",
  "returned_material_authorization": "RMA",
  "sid_no": "SID No",
  "contact_purchase": "SMP/CNTCT",
  "other1_ot": "Other1",
  "other2_ot1": "Other2",
  "other3_ot2": "Other3",
  "other4_ot3": "Other4",
  "other5_ot4": "Other5",
  "other6_ot5": "Other6",
  "created_at_utc": "創建時間(UTC)",
  "gross_margin_ready_date": "GM Ready Date (UTC)",
  "actual_pickup_at": "提貨時間",
  "customs_cleared_at": "出口清關",
  "customs_released_at": "進口清關",
  "shipment_not_existed": "Shipment號碼不存在",
  "containe_invalidate_char": "存在無效字元",
  "shipment_no": "Shipment No",
  "fd_no": "FD No",
  "apple_fdno": "Apple-FDNo",
  "fd_manager": "FD Manager",
  "purchase_order_no_po_smp_slash_po": "Purchase Order NO,PO, SMP/PO",
  "or_no": "OR No",
  "s_slash_o_no": "S/O No",
  "s_slash_o_no_plus_ship_s_slash_o": "S/O number \n + Ship(S/O)",
  "di_no": "DI No",
  "in_no": "IN No",
  "sr_no": "SR No",
  "shipmentref_number": "ShipRef Number",
  "pl_no": "PL NO",
  "pn_no": "PN No",
  "permit": "Permit",
  "it_no": "IT No",
  "umc": "UMC",
  "s_slash_n": "S/N",
  "rma": "RMA",
  "material_authorization": "Returned Material\n Authorization",
  "si": "SI",
  "smp_slash_cntct": "SMP/CNTCT",
  "contact_slash_purchaser": "Contact/Purchaser",
  "other1": "Other1",
  "other2": "Other2",
  "other3": "Other3",
  "other4": "Other4",
  "other5": "Other5",
  "other6": "Other6",
  "bulk_update_shipment_references_error_shipment_duplicate": "Shipment號碼重複",
  "reference_bulk_update_upload_memo": "或於表格中貼上資料",
  "reference_bulk_update_upload_memo_description": "(如果選擇新檔案，所有已輸入的資料將會被覆蓋; 換行符號將被取代成逗號)",
  "master_ata": "MAWB - ATA",
  "error_code_forwarder_id_name_not_found": "",
  "you_cannot_cancel_the_shipment_without_a_comment": "沒有留言，將無法取消貨物",
  "back": "返回",
  "are_you_sure_you_want_to_cancel_the_shipment": "確認刪除貨物?",
  "operating_number": "單號",
  "level": "層級",
  "no_data": "沒有資料",
  "pick_up_address_same_as_shipper_address": "提貨與發貨地址相同",
  "ship_to_address_same_as_consignee_address": "交貨與收貨地址相同",
  "pick_up": "Pick Up",
  "ship_to": "Ship To",
  "customer_name": "Customer Name",
  "custom_value": "Custom Value",
  "commercial_invoice": "Commercial Inv#",
  "comments": "留言",
  "edit_comments": "編輯留言",
  "is_dangerous": "危險品",
  "new_comments": "新增留言"
 },
 "sys": {
  "role_type": "角色種類",
  "role_name": "角色名稱",
  "role_id": "角色識別碼",
  "system": "系統",
  "delete": "刪除",
  "role_type_function": "Function",
  "role_type_data": "Data",
  "role_type_privilege": "Privilege",
  "are_you_sure_to_delete_the_role": "您確定刪除此角色嗎?",
  "customer": "客戶",
  "owner": "擁有者",
  "client_id": "Client ID",
  "shipper": "托運人",
  "consignee": "收貨人",
  "company_id": "使用者公司代碼",
  "shipment_mode": "運輸方式",
  "origin_port": "起始地",
  "destination_port": "目的地",
  "station": "站點",
  "carrier": "承運人",
  "agent": "代理",
  "export_prolink_db": "Prolink資料庫 - 出口",
  "import_prolink_db": "Prolink資料庫 - 進口",
  "site": "地點",
  "user_info": "User Information",
  "user_type": "User Type",
  "api_token": "API Token",
  "employee_id": "Employee Id",
  "forwarder_id": "貨運承攬商代碼",
  "region": "Region",
  "division": "Division",
  "department": "Department",
  "supervisor": "Supervisor",
  "job_title": "Job Title",
  "individual": "Individual",
  "update_logs": "更新紀錄",
  "system_current_value": "系統目前值",
  "shipment_number": "Shipment 號碼",
  "delivery_number": "Delivery 號碼",
  "source_system": "來源系統",
  "target_system": "目標系統",
  "shipment_no": "Shipment No",
  "delivery_no": "Delivery No",
  "milestone_name": "Milestone Name",
  "milestone_value": "Milestone Value",
  "updated_at": "更新於",
  "dataflow_process_at": "Dataflow 處理時間",
  "dataflow_scheduler": "Dataflow 調度器",
  "table": "表格",
  "m1_records": "M1 紀錄",
  "dataflow_logs_from_ods": "Dataflow Logs (From ODS)",
  "ods_records": "ODS 紀錄",
  "dataflow_logs_from_trck": "Dataflow Logs (From TRCK)",
  "rec_seq_no": "REC_SEQ_NO",
  "prolink": "Prolink",
  "datapool": "Datapool",
  "process_at": "處理於",
  "process_status": "處理狀態",
  "error_class": "Error Class",
  "quotation_no": "Quotation No",
  "awb_type": "AWB_TYPE",
  "pkg": "PKG",
  "transshipment_type": "Transshipment Type",
  "wb_status": "WB_STATUS",
  "log_id": "Log Id",
  "trck": "TRCK",
  "updated_table": "Updated Table",
  "module": "Module",
  "epic_ticket_number": "Epic Number",
  "ready_to_publish": "Ready to Publish",
  "create_by": "Create By",
  "last_edit_by": "Last Edit By",
  "title": "Title",
  "content": "Content",
  "release_note_config": "Release Note Config",
  "release_date": "Release Date",
  "language": "Language",
  "create_at": "Create At",
  "last_by": "Last By",
  "there_is_no_more_content": "There is No More Content",
  "scroll_down_to_see_more": "Scroll Down to See More",
  "content_has_been_updated": "%{module} Just Updated, Click to Refresh",
  "release_notes": "發佈版本通知",
  "role_black_list_alert_message": "注意，若同時在功能上套用兩組Policy，而這兩組Policy 在同一個維度上同時存在黑名單與白名單，則黑名單設定效果可能不會生效。",
  "publish": "佈版至PROD",
  "mark_all_as_read_and_close": "已全部讀取且不再顯示",
  "system_current_value_mop": "系統目前值 - MOP",
  "system_current_value_ods": "系統目前值 - Prolink (ODS)",
  "system_current_value_apple_trck": "系統目前值 - APPLE TRCK",
  "system_change_logs_mop": "更新紀錄 - MOP",
  "system_change_logs_apple_trck": "更新紀錄 - APPLE TRCK",
  "dataflow_sync_logs_ods": "Dataflow Sync Logs - Prolink (ODS) To MOP",
  "dataflow_sync_logs_apple_trck": "Dataflow Sync Logs - APPLE TRCK To MOP",
  "milestone_value_local_time": "Milestone Value (Local Time)",
  "updated_at_utc": "更新於 (UTC)",
  "prolink_code": "Prolink Code",
  "source_db": "Source DB",
  "create_flow": "Flow",
  "revised_source": "Source",
  "flow": "Flow",
  "search_milestone_and_log": "Search milestone and log",
  "search_shipment_and_log": "Search shipment and log",
  "system_current_value_mop_description": "Schema: tracking, Table: latest_milestone",
  "system_current_value_ods_description": "Schema: odsprd, Table: BLSTATUS",
  "system_current_value_apple_trck_description": "Schema: trckprd, Table: hawd_records, dn_shipments",
  "system_change_logs_mop_description": "Schema: tracking, Table: milestone_history",
  "system_change_logs_apple_trck_description": "Schema: trckprd, Table: edi_milestone_logs",
  "dataflow_sync_logs_ods_description": "Schema: data_flow, Table: log_blstatus",
  "dataflow_sync_logs_apple_trck_description": "Schema: data_flow, Table: trck_milestone_log",
  "mop_log_at_utc": "MOP Log At (UTC)",
  "process_message": "Process Message",
  "datapool_created_at_utc": "Datapool At (UTC)",
  "updated_by": "更新者",
  "user_apple_trck": "Apple Trck Information",
  "shipment_dimension_title": "Shipment Dimension",
  "shipment_dimension_description": "Apply on Shipment, Billing, TMS, CFS, Tracking, Comprehensive",
  "master_dimension_title": "Master Dimension",
  "master_dimension_description": "Apply on Master, Routing",
  "user_dimension_title": "User Dimension",
  "user_dimension_description": "Apply on Job weighting, Dashboard, Task",
  "master_origin_port": "MAWB 起始地",
  "master_destination_port": "MAWB 目的地",
  "bamboo_location": "Bamboo 地點",
  "appletrckrole_superuser": "SUPERUSER",
  "appletrckrole_exporter": "EXPORTER",
  "appletrckrole_export_supervisor": "EXPORT_SUPERVISOR",
  "appletrckrole_importer": "IMPORTER",
  "appletrckrole_import_supervisor": "IMPORT_SUPERVISOR",
  "appletrckrole_client": "CLIENT",
  "appletrckrole_finance_and_accounting": "FINANCE_AND_ACCOUNTING",
  "appletrckrole_control_tower": "CONTROL_TOWER",
  "appletrckrole_fd_user": "FD_USER",
  "appletrckrole_fd_importer_user": "FD_IMPORTER_USER",
  "appletrckrole_fd_exporter_user": "FD_EXPORTER_USER",
  "generate_password": "Generate Password",
  "get_token": "Get Token",
  "privilege_role": "Privilege Role",
  "do_you_want_to_send_email_to_user": "Do you want to send email to user?",
  "send_welcome_email_to_user": "Send Welcome Email To User",
  "simulate_login_user": "Simulate Login User",
  "check_privilege": "Check Privilege",
  "privilege_not_found": "Privilege not found",
  "error_role_policy_submit": "Following roles need to set policy",
  "error_role_name_duplicate": "權限名稱重複"
 },
 "task_mgmt": {
  "all__issue_type__count": "總任務數",
  "all_task": "全部任務",
  "all_tasks": "全部任務",
  "assign_at_utc": "指派日(UTC)",
  "assignee": "被指派人",
  "assignee_reason": "指派原因",
  "category": "分類",
  "client_id": "客戶ID",
  "closed_by": "關單人員",
  "complete_invalid_fd_pr_in_apple_trck": "修正錯誤的FD/PR號碼在Apple TRCK",
  "complete_missing_milestone": "完成缺失事件",
  "complete_missing_milestone_in_apple_trck": "填寫缺失事件在Apple TRCK",
  "complete_missing_shipment_data": "填寫缺失資料",
  "complete_missing_shipment_data_in_prolink": "在Prolink填寫未確認 cost ",
  "complete_missing_tms_data": "填寫缺失的TMS資料",
  "complete_missing_invoice": "填寫缺失發票",
  "complete_missing_voucher": "填寫缺失憑單",
  "create_valid_quotation_in_prolink": "在Prolink/CW1中創建有效的報價",
  "correct_shipment_data_in_prolink": "在Prolink/CW1中更正貨物資料",
  "created_at": "新增日期 (UTC)",
  "created_at_utc": "創建日期(UTC)",
  "created_by": "創建者",
  "days": "天",
  "days_elapsed": "%{days} 經過天數",
  "department": "部門",
  "description": "描述",
  "do_you_want_to_change_task_status_to_exemption": "您是否想要從KPI計算中免除這些任務?",
  "escalation_at_utc": "上報主管日期 (UTC)",
  "escalation_owner": "主管",
  "event_date_and_time_local": "事件發生當地時間",
  "event_location": "事件地點",
  "exempted": "KPI 已豁免",
  "exemption": "KPI 豁免",
  "expected_due_date": "期望完成日期",
  "expected_due_date_utc": "期望完成日期(UTC)",
  "general_guidelines": "一般指南",
  "urgent": "緊急",
  "high": "高",
  "hours": "小時",
  "instruction": "指示",
  "is_qa_team_assigned": "已指派QA團隊",
  "issue_type": "問題類型",
  "issue_type_aged_booking": "過時的預定",
  "issue_type_ar_overdue": "應收逾期",
  "issue_type_booking": "預定",
  "issue_type_consol_data": "併裝箱資料",
  "issue_type_document": "文件",
  "issue_type_edi_failure": "EDI 失敗",
  "issue_type_invoice_data": "發票資料",
  "issue_type_late_billing": "延遲的帳單",
  "issue_type_milestone": "事件",
  "issue_type_voucher_data": "憑單資料",
  "issue_type_quotation": "報價",
  "issue_type_shipment_data": "貨物資料",
  "issue_type_tms_data": "派車資料",
  "issue_type_unclosed_console": "未完成的併裝箱",
  "issue_type_tms_milestone": "派車事件",
  "issue_type_tms_document": "派車文件",
  "issue_type_tms_po": "TMS PO",
  "issue_type_log_milestone": "物流事件",
  "issue_type_log_invoice_data": "物流發票號碼",
  "issue_type_log_voucher_data": "物流憑單號碼",
  "issue_type_log_shipment_data": "物流貨物資料",
  "issue_type_log_document": "物流文件",
  "issue_type_booking_request": "訂艙委託",
  "leave_a_comment_in_apple_trck": "留言告知最新進度在 Apple TRCK",
  "leave_an_exception": "留言或提交例外",
  "confirm_booking_request": "確認訂艙委託",
  "leave_a_comment": "留言",
  "linked_issues": "關聯問題",
  "linked_task_number_list": "關聯的任務清單",
  "low": "低",
  "medium": "中",
  "my_task": "我的任務",
  "my_tasks": "我的任務",
  "my_watching": "我的關注",
  "or_specify_who_or_what_is_stopping_you_from_closing_the_task": "或是留下因某人的事項耽擱而無法處理的原因",
  "or_to_leave_your_reason_and_latest_update_by": "或是留下你的原因以及最新進度",
  "overdue": "逾期 (小時)",
  "overdue_tip": "數值",
  "is_overdue": "是否逾期?",
  "overdue__issue_type__count": "延遲任務數",
  "overdue__overdue__avg": "平均延遲 (小時)",
  "overdue_percentage": "延遲比例 (%)",
  "pending_a_feedback_from": "等待反饋來自",
  "pending": "等待反饋",
  "pending_reason": "等待原因",
  "priority": "優先級",
  "reason_code": "原因",
  "reference_no": "參考單號",
  "reference_number": "參考號碼",
  "reference_type": "參考類型",
  "search_for_issues": "搜尋問題",
  "search_for_tags": "搜尋標籤",
  "search_issue": "查詢任務",
  "shipment_mode": "運輸方式",
  "shipment_number": "貨物號碼",
  "specify_your_reason_for_stopping": "說明您停止此Task的原因",
  "specify_your_reason_for_late_billing": "說明您帳務延遲的原因",
  "task_watched": "已關注",
  "watch_task": "關注",
  "start_watching": "開始關注",
  "station": "站點",
  "status": "狀態",
  "stop_watching": "停止關注",
  "tags": "標籤",
  "target_due_date": "目標完成日",
  "target_due_date_utc": "目標完成日 (UTC)",
  "task_closed_at_utc": "任務關閉時間 (UTC)",
  "task_closed_at": "任務關閉時間 (UTC)",
  "task_detail_general_quidelines_terms": "一般指南:\n1.    被指定人需負責將任務如期內完成關閉\n2.    被指定人在一些情境下無法如期完成任務，請留下你的原因以及最新進度\n3.    將你預期解決的時間更新至目標完成日期\n4.    如有相關聯的其他任務，請主動關聯任務",
  "task_id": "任務編號",
  "task_status": "任務狀態",
  "task_status_action_taken": "已行動",
  "task_status_assigned": "已指派",
  "task_status_closed": "關閉",
  "task_status_in_progress": "處理中",
  "task_status_open": "開立",
  "task_status_reopen": "再次開立",
  "task_status_unassigned": "未指派",
  "this_will_trigger_edi_to_customer": "這將會觸發EDI發送給客戶",
  "title": "標題",
  "to_close_task_by": "關閉此任務(任務狀態每十分鐘更新)",
  "Type": "類別",
  "type_number_of_overdue": "輸入逾期天數",
  "typing_title_or_task_number_or_reference_no": "輸入任務標題, 號碼, 參考號碼",
  "updated_at_utc": "更新日期 (UTC)",
  "updated_by": "更新人員",
  "upload_documents": "上傳文件",
  "upload_documents_in_apple_trck": "上傳文件在 Apple TRCK",
  "upload_the_invoice_document_and_check_the_rate_in_apple_trck": "上傳正確發票文件並確認費率在Apple TRCK",
  "vip_name": "客戶",
  "watcher_list": "關注人員清單",
  "last_comment": "最後評論",
  "delivery_number": "Delivery No",
  "master_number": "主單號",
  "vessel_name_list": "航班號",
  "voyage_number_list": "航次",
  "exemption_true": "是",
  "exemption_false": "否",
  "stakeholder": "等待反饋",
  "stakeholder_true": "有",
  "stakeholder_false": "無",
  "region": "地區",
  "division": "分支",
  "issue_type_wms_consignment_data": "WMS 託運物資料",
  "issue_type_wms_dn_data": "WMS 送貨單資料",
  "issue_type_production_support": "Production Support",
  "week_year": "週數",
  "choose_actions": "選擇執行動作",
  "exempt_kpi": "KPI 豁免",
  "add_comment": "新增留言",
  "add_pending_reason": "新增暫停原因",
  "add_late_billing_reason": "新增帳款延遲原因",
  "close_task": "關閉任務",
  "reassign": "重新指派",
  "info_to_update_target_due_date": "請更新您的目標完成日期，如果您預期無法如期完成",
  "warn_to_update_target_due_date": "因此任務已逾時，請更新您的目標完成日期",
  "assignee_reason_prolink_created_by_hawb": "提單帶單者",
  "assignee_reason_prolink_created_by_booking": "Booking 創建者",
  "assignee_reason_consol_created_by": "Consol 製單者",
  "assignee_reason_op_manager": "OP 主管",
  "assignee_reason_station_head": "站長",
  "assignee_reason_regional_qa": "地區 QA",
  "assignee_reason_assignee_config": "指定指派人",
  "assignee_reason_agent_compensate_prolink_created_by": "代理站",
  "assignee_reason_trck_created_by": "TRCK 創建者",
  "assignee_reason_hard_assign": "重新指派",
  "assignee_reason_tip_prolink_created_by_hawb": "提單帶單者",
  "assignee_reason_tip_prolink_created_by_booking": "Booking創建者",
  "assignee_reason_tip_consol_created_by": "Consol製單者",
  "assignee_reason_tip_op_manager": "在 Prolink/CW1 系統尚無帶單者, 故指派給部門經理",
  "assignee_reason_tip_station_head": "在 Prolink/CW1 系統尚無帶單者,以及部門經理, 故指派給站長",
  "assignee_reason_tip_regional_qa": "在 Prolink/CW1 系統尚無帶單者, 部門經理,以及站長 , 故指派給 Regional QA",
  "assignee_reason_tip_assignee_config": "你是該任務種類的預設指定負責人",
  "assignee_reason_tip_agent_compensate_prolink_created_by": "當為 VIP 客戶時, 必須負責agent對站事宜, 而你是該站在 Prolink/CW1 系統內該張提單的帶單者",
  "assignee_reason_tip_trck_created_by": "你是在 TRCK 系統內該張提單的建立者",
  "assignee_reason_tip_hard_assign": "人為重新指派",
  "upload_documents_bol": "上傳 POD 簽收文件到送貨單(BOL)",
  "confirm_documents": "檢核貨件(Shipment)的 POD 簽收文件",
  "task_created_at (in nth week)": "創建時間(UTC)",
  "add_comments": "新增留言",
  "add_watchers": "新增關注人員",
  "advised_action": "建議動作",
  "aging": "已過",
  "choose_category": "選擇類型",
  "choose_subcategory": "選擇子類型",
  "late_reason_category": "帳務延遲類型",
  "late_reason_code": "帳務延遲代碼",
  "late_reason_subcategory": "帳務延遲子類型",
  "late_reason_subcode": "帳務延遲子代碼",
  "can_not_perform_action": "無相關建議動作，無法繼續操作任務號碼 %{taskNumbers}",
  "selected_task_count": "總任務數: %{count}",
  "you_cannot_close_the_task_without_a_comment": "沒有留言，將無法關閉任務",
  "comment": "留言",
  "comment_here": "在此新增留言",
  "option_milstone_task_closed_at": "任務關閉時間 (UTC)",
  "option_milstone_task_created_at": "創建日期(UTC)",
  "option_milstone_task_expected_due_date": "期望完成日期(UTC)",
  "task_expected_due_date": "期望完成日期(UTC)",
  "system_source": "出口系統來源",
  "import_system_source": "進口系統來源",
  "prolink_export_created_by": "Prolink出口創建者(HAWB/HBL)",
  "prolink_import_created_by": "Prolink 進口創建者(HAWB/HBL)",
  "quotation_number": "報價單號",
  "incoterm": "貿易條款",
  "freight_term": "付款條款",
  "origin_port": "始發站",
  "destination_port": "目的站",
  "gross_weight": "毛重",
  "package_quantity": "件數",
  "etd": "預計出發時間(ETD)",
  "eta": "預計到達時間(ETA)",
  "atd": "實際出發時間(ATD)",
  "ata": "實際到達時間(ATA)",
  "service_type": "服務類型",
  "service_level": "服務等級",
  "estimated_pickup_at": "預計提貨時間",
  "actual_pickup_at": "提貨時間",
  "doc_released_at": "放單",
  "customs_released_at": "進口海關放行",
  "arrived_cfs_at": "抵達目的CFS時間",
  "cargo_receipt_at": "收貨日期",
  "shipper_id": "發貨方代碼",
  "shipper_name_1": "發貨方名字",
  "payer": "付款方",
  "payer_name": "付款方名字",
  "master_number_2": "2nd MAWB/MBL",
  "lot_number_1": "1st Lot No ",
  "lot_number_2": "2nd Lot No",
  "voyage_name_1": "1st Voyage  Name",
  "voyage_name_2": "2nd Voyage  Name",
  "vessel_name_1": "1st Vessel Name",
  "vessel_name_2": "2nd Vessel Name",
  "out_for_delivery_date": "外出遞送",
  "actual_delivery_date": "實際送達日期",
  "consignee_id": "收貨方代碼",
  "consignee_name_1": "收貨方名字",
  "notify_id": "通知方代碼",
  "notify_name_1": "通知方名字",
  "inv_vou_number": "發票/憑單號碼",
  "inv_vou_trade_type": "發票/憑單種類",
  "inv_vou_bill_to_credit_to": "Debit To/ Vendor",
  "inv_vou_print_at": "發票/憑單列印日期",
  "inv_vou_print_by": "發票/憑單列印者",
  "can_not_perform_comment_action": "無相關建議動作，無法繼續操作任務號碼",
  "consignee_state": "收貨州別",
  "consignee_city": "收貨方城市",
  "origin_port_list": "始發站",
  "origin_country": "Origin Country",
  "origin_region": "Origin Region",
  "destination_country": "Dest Country",
  "destination_region": "Dest Region",
  "config_priority": "Config Priority",
  "first_assignee_type": "1st Assignee Type",
  "first_assignee": "1st Assignee",
  "second_assignee_type": "2nd Assignee Type",
  "second_assignee": "2nd Assignee",
  "handling_station": "責任站點",
  "lock": "Lock",
  "unlock": "Unlock",
  "first_assignee_status": "1st Assignee Status",
  "second_assignee_status": "2nd Assignee Status",
  "inactive_assignee_status": "已離職",
  "watcher": "關注人員",
  "cancel": "取消",
  "clone": "複製",
  "name": "Name",
  "error_assignee_config_is_duplicated": "已存在被指派人config",
  "error_assignee_is_not_exists": "被指派人不存在",
  "error_can_not_find_reference_type": "無法找到該 reference type",
  "error_assignee_config_is_not_exists": "被指派人config不存在",
  "error_task_asignee_create": "創建被指派人config時出現錯誤",
  "error_task_asignee_update": "更新被指派人config時出現錯誤",
  "error_task_asignee_delete": "刪除被指派人config時出現錯誤",
  "error_task_asignee_lock": "修改Lock狀態時出現錯誤",
  "error_issue_type_must_have_value": "問題類型必須輸入值",
  "error_category_must_have_value": "分類必須輸入值",
  "error_mode_list_must_have_value": "mode必須輸入值",
  "error_handling_station_list_must_have_value": "責任站點必須輸入值",
  "error_client_id_list_must_have_value": "client_id必須輸入值",
  "error_vip_name_list_must_have_value": "vip_name必須輸入值",
  "error_consignee_state_list_must_have_value": "收貨州別必須輸入值",
  "error_consignee_city_list_must_have_value": "收貨方城市必須輸入值",
  "error_origin_port_list_must_have_value": "始發站必須輸入值",
  "error_origin_country_list_must_have_value": "Origin Country必須輸入值",
  "error_origin_region_list_must_have_value": "Origin Region必須輸入值",
  "error_dest_port_list_must_have_value": "目的站必須輸入值",
  "error_dest_country_list_must_have_value": "Dest Country必須輸入值",
  "error_dest_region_list_must_have_value": "Dest Region必須輸入值",
  "error_first_assignee_type_must_have_value": "1st Assignee Type必須輸入值",
  "error_first_assignee_must_have_value": "1st Assignee必須輸入值",
  "error_second_assignee_type_must_have_value": "2nd Assignee Type必須輸入值",
  "error_second_assignee_must_have_value": "2nd Assignee必須輸入值",
  "error_config_priority_must_have_value": "任務優先級別必須輸入值",
  "input_is_limited_to_max_selections": "輸入僅限於 %{max} 個選擇",
  "lock_assignee_config_cant_be_delete": "鎖定的配置無法刪除",
  "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未儲存的資料將遺失，您確定要離開嗎?",
  "customer_code_name": "客戶代碼/名稱",
  "delete_comment_failed": "留言刪除失敗",
  "assignee_reason_dispatch_created_by": "Dispatch創建者",
  "assignee_reason_invoice_printed_by": "發票列印者",
  "assignee_reason_invoice_created_by": "發票創建者",
  "assignee_reason_loading_plan_generator": "Loading Plan 創建者",
  "assignee_reason_assignee_supervisor": "原指派人直屬主管",
  "assignee_reason_shipment_created_by": "貨物創建者",
  "assignee_reason_tip_dispatch_created_by": "Dispatch創建者",
  "assignee_reason_tip_invoice_printed_by": "發票列印者",
  "assignee_reason_tip_invoice_created_by": "發票創建者",
  "assignee_reason_tip_loading_plan_generator": "Loading Plan 創建者",
  "assignee_reason_tip_assignee_supervisor": "原指派人直屬主管",
  "assignee_reason_tip_shipment_created_by": "Shipment創建者",
  "complete_missing_invoice_in_prolink": "在Prolink填寫缺失發票",
  "complete_missing_charge_in_prolink": "在Prolink填寫缺失charge",
  "complete_missing_voucher_in_prolink": "在Prolink填寫缺失憑單",
  "complete_unconfirm_cost_in_prolink": "在Prolink還有未確認的cost ",
  "complete_unprint_invoice_in_prolink": "在Prolink還有未打印發票",
  "issue_type_sentry": "Sentry",
  "issue_type_non_trade_voucher_data": "Non-trade憑單",
  "issue_type_non_trade_invoice_data": "Non-trade發票",
  "origin_iata_region": "Origin IATA Region",
  "destination_iata_region": "Dest IATA Region",
  "customer_name_code": "客戶名稱/代碼",
  "more_fields": "More Fields",
  "comments": "Comments",
  "due_date": "Due Date",
  "complete_job_duty": "請至Job duty頁面完成任務",
  "more_fields_header_children": "KPI Exemption, add tags, Reference No…",
  "upload_photo_on_cfs": "請至CFS頁面上傳照片",
  "issue_type_jobduty_need_action": "Job Duty Need Action",
  "config_id": "Config ID",
  "task_assignee_config_priority_p1": "第一順位",
  "task_assignee_config_priority_p2": "第二順位",
  "task_assignee_config_priority_p3": "第三順位",
  "task_assignee_config_priority_p4": "第四順位",
  "task_assignee_config_priority_p5": "第五順位",
  "task_assignee_config_priority_p6": "第六順位",
  "task_assignee_config_priority_low": "Low",
  "task_assignee_config_priority_medium": "Medium",
  "task_assignee_config_priority_high": "High",
  "task_assignee_type_job_group": "JOB_GROUP",
  "task_assignee_type_email": "EMAIL",
  "task_assignee_group_prolink_import_created_by": "PROLINK_IMPORT_CREATED_BY",
  "task_assignee_group_prolink_export_created_by": "PROLINK_EXPORT_CREATED_BY",
  "task_assignee_group_invoice_created_by": "INVOICE_CREATED_BY",
  "task_assignee_group_console_created_by": "CONSOLE_CREATED_BY",
  "task_assignee_group_consol_created_by": "CONSOL_CREATED_BY",
  "task_assignee_group_invoice_printed_by": "INVOICE_PRINTED_BY",
  "task_assignee_group_booking_created_by": "BOOKING_CREATED_BY",
  "task_assignee_group_dispatch_created_by": "DISPATCH_CREATED_BY",
  "task_assignee_group_loading_plan_generator": "LOADING_PLAN_GENERATOR",
  "task_assignee_group_shipment_created_by": "SHIPMENT_CREATED_BY",
  "task_assignee_group_op_manager": "OP_MANAGER",
  "task_assignee_group_transshipment_source_created_by": "TRANSSHIPMENT_SOURCE_CREATED_BY",
  "is_overdue_true": "是",
  "is_overdue_false": "否",
  "region_apac": "APAC",
  "region_emea": "EMEA",
  "region_amer": "AMER",
  "delete_this_config": "刪除此設定：%{id}?",
  "sec_sales": "接單業務員",
  "chargeable_weight": "計費重量",
  "delivery_teu": "Delivery TEU",
  "shipment_teu": "Shipment TEU",
  "reassign_by": "重新指派人",
  "reassign_at_utc": "重新指派日期",
  "any_inactive": "任一人離職",
  "both_active": "1st, 2nd 皆在職",
  "assignee_status": "指派人狀態",
  "assignee_status_active": "在職",
  "assignee_status_inactive": "離職",
  "error_late_billing_not_allowed": "This Task is not related to Late Billing, this category is not allowed",
  "search_issue_tooltip_text": "模糊搜尋貨物號碼, 任務號碼, 或參考號碼",
  "complete_missing_milestone_ofd_dispatch": "輸入派車單(Dispatch)的外出遞送時間(OFD Time)",
  "complete_missing_milestone_pod_bol": "輸入送貨單(BOL)的POD簽收時間(POD Time)",
  "complete_missing_tms_data_assign_to_carrier": "指派貨件給承運人",
  "complete_missing_tms_data_create_dispatch": "建立派車單(Dispatch)",
  "complete_missing_tms_data_create_po": "建立派車單(Dispatch)的 PO Request",
  "leave_shipment_exception": "至Shipment提交例外",
  "undefined": ""
 },
 "user_registration_mgmt": {
  "active_description": "啟用的客戶",
  "all": "全部",
  "carrier_code": "承運人代碼",
  "client_id": "客戶ID",
  "clients_account_appliction_records": "客戶申請紀錄",
  "company_name": "公司名稱",
  "destination_port": "目的站",
  "disabled_desciption": "使用者已建立，尚未寄送邀請信",
  "invitation": "邀請",
  "origin_port": "出口站",
  "password_rest_description": "重設密碼",
  "request_date": "申請日期",
  "search_key": "搜尋關鍵字",
  "shipment_mode": "運輸方式",
  "site": "地點",
  "station": "站點",
  "submitted_description": "送出申請表",
  "system": "系統",
  "tracking_slash_shipment": "Tracking / Shipment",
  "tracking_slash_spend_report": "Tracking / Spend Report",
  "vip_name": "重要客戶名稱",
  "wait_verified_description": "送出邀請信",
  "your_data_authority": "你的資料權限"
 },
 "wms": {
  "alert_check_customer_pickup_shipments": "This selection including %{shipmentCounts} shipments with “Self pick-up” service type.(WHSP) \nIncluding Consignment: %{shipmentNumbers}.\nPlease change service type to “To Door” type (WHTD) before entering dispatch creation.",
  "alert_check_customer_pickup_before_check_in_counter": "This selection including %{shipmentCounts} shipment(s) with “To port”  service type ( ATA or DTA or PTP or DTP or ATP or PTA) .\nIncluding HAWB: %{shipmentNumbers}.\nPlease change service type to “To Door” type (ATD or DTD or PTD) before Assign Carrier.",
  "warning_has_dispatch_shipments": "Warning:\n\nThis selection including %{shipmentCounts} shipments have already had OFD/POD.\nSystem will update OFD & POD timestamps and POD files on these shipments based on TMS .\nIncluding Consignment: %{shipmentNumbers}.\n\nDo you want to proceed DN & Dispatch creation?",
  "bol_details": "送貨單(DN)詳情",
  "bol_grouping_rule": "貨物合併到送貨單(DN)規則",
  "cancel_quotation_and_continue_to_cancel_this_bill_of_lading": "送貨單(DN) %{bol_number} 若已接受報價，刪除送貨單(DN)將同時取消報價，確認要刪除送貨單(DN)嗎？",
  "cancel_quotation_and_delete_dispatch_dispatchnumber": "派車單(Dispatch)%{dispatchnumber} 若已接受報價，刪除派車單(Dispatch)將同時取消報價，確認要刪除派车单(Dispatch)嗎？",
  "dispatch_station": "站點",
  "tracking_device": "追踪器號碼",
  "actual_driver_arrival_time": "實際司機到達時間",
  "actual_out_for_delivery_date": "外出遞送日期",
  "actual_out_for_delivery_time": "外出遞送時間",
  "actual_ship_method": "實際運送方式",
  "advanced_search": "進階搜尋",
  "arrival_date": "抵達日期",
  "arrival_time": "抵達時間",
  "arrival_type": "抵達類別",
  "asn": "ASN#",
  "asn_number": "ASN Number",
  "asn_qty": "ASN 數量",
  "assistant_driver_1_name": "第一跟車司機",
  "assistant_driver_2_name": "第二跟車司機",
  "available": "可進行",
  "available_qty": "可用數量",
  "bid_required": "比價",
  "billing_period": "Billing Period",
  "bol_dn_no": "DN No",
  "bol_dn_type": "送貨類型",
  "bol_no": "DN No",
  "bol_status": "送貨單(DN)狀態",
  "bol_status_update": "送貨單(DN)狀態更新",
  "booking_status": "報價狀態",
  "cancelled": "已取消",
  "carrier": "承運人",
  "carrier_service_level": "承運人服務",
  "close_date": "Close Date",
  "commodity": "貨品",
  "completed_date": "完成日期",
  "complete_time": "完成時間",
  "confirm_complete": "確認完成",
  "confirm_report": "成果報告",
  "consignment": "Consignment",
  "consignment_detail": "托運物明細",
  "consignment_no": "Consignment No",
  "consignment_pcs": "貨物件數",
  "consignment_progress": "貨物狀態",
  "country": "國家/地區",
  "creation_date": "創建日期",
  "custom_ref": "Cust Ref#",
  "customer": "Customer",
  "customer_name": "客戶名稱",
  "customer_ref": "客戶單號",
  "damage_qty": "不良品數量",
  "date_from": "起始日",
  "date_to": "截止日",
  "day_0_30": "< 30 天",
  "day_121_180": "121 - 180 天",
  "day_181_270": "181 - 270 天",
  "day_271_360": "271 - 360 天",
  "day_31_60": "31 - 60 天",
  "day_360_plus": "> 360 天",
  "day_61_90": "61 - 90 天",
  "day_91_120": "91 - 120 天",
  "delivery_count": "SO數",
  "delivery_date": "送貨日期",
  "delivery_from": "要求送達時間（起）",
  "delivery_method": "遞送方式",
  "delivery_no": "SO",
  "delivery_to": "（訖）",
  "detail": "Detail",
  "dimensions": "尺寸",
  "dispatch_creation_date": "派車單建立時間",
  "dispatch_delivery_method": "派車單遞送方式",
  "dispatch_no": "Dispatch No",
  "dispatch_planned_ofd_date": "計劃外出遞送時間",
  "dispatch_status": "派車單(Dispatch)狀態",
  "dispatch_type": "派車單(Dispatch)類型",
  "dispatchDeliveryMethod": "遞送方式",
  "dispatchShipmentMode": "運輸方式",
  "dn_carrier": "DN 承運人",
  "dn_carrier_service_level": "實際承運人服務",
  "dn_details": "DN 詳情",
  "dn_group_rule": "貨物合併到送貨單(DN) 規則",
  "dn_no": "DN No",
  "dn_status": "DN 狀態",
  "document_date": "Document Date",
  "edi_date": "EDI日期",
  "edi_ship_method": "EDI運送方式",
  "edi_time": "EDI時間",
  "error_client_not_provided": "未提供倉庫客戶",
  "europe_destination_region": "歐盟目的國家/地區",
  "event": "事件",
  "exception_message": "例外訊息",
  "fast_delete_booking_status_tips_accepted": "已接受承運人報價，貨物從送貨單移除將同時取消報價。",
  "fast_delete_booking_status_tips_submitted": "已收到承運人報價，貨件從送貨單移除將同時取消報價。",
  "fragile": "易碎",
  "gross_weight": "毛重",
  "gw": "總毛重",
  "hawb_bl": "HAWB / BL",
  "hawb_count": "Consignment數",
  "hawb_no": "Consignment No",
  "hazardous": "有害物品",
  "height": "高",
  "inbound_detail": "入庫詳細資訊",
  "inbound_header": "入庫",
  "inbound_ref": "進貨號碼",
  "inbound_scheduled_date": "預計收貨日期",
  "inbound_scheduled_time": "預計收貨時間",
  "inbound_start_date": "收貨起始日",
  "inbound_start_time": "收貨起始時間",
  "instruction": "特殊指示",
  "inv_damage": "不良品",
  "inv_expired": "過期",
  "inv_good": "良品",
  "inv_hold": "擱置",
  "inv_quarantine": "檢疫",
  "inv_rma": "換貨",
  "inv_special": "特殊",
  "length": "長",
  "line_number": "行號",
  "location": "儲位",
  "loose_ctn_qty": "散箱數量",
  "notification": "提示",
  "number": "No",
  "ofd_departed_from_warehouse": "OFD(離開倉庫)",
  "ofd_time": "外出遞送時間",
  "onhold_qty": "擱置數",
  "order": "Order#",
  "order_date": "Order Date",
  "order_info": "訂單內容",
  "order_number": "訂單編號",
  "order_picked_date": "撿貨時間",
  "order_picked_time": "撿貨日期",
  "order_qty": "Order QTY",
  "order_start_date": "出貨起始日期",
  "order_start_time": "出貨起始時間",
  "order_time": "Order Time",
  "order_type": "訂單類型",
  "ordered_qty": "預計收貨量",
  "orig_line_number": "原始行號",
  "outbound_attachment": "出貨附件",
  "outbound_detail": "出貨詳細",
  "outbound_header": "出貨",
  "outbound_package": "出貨包裝",
  "outbound_sn": "出貨序號",
  "package_id": "包裝識別號",
  "package_info": "包裝內容",
  "package_unit": "包裝類型",
  "packed_date": "包裝日期",
  "packed_qty": "已包裝數量",
  "packed_time": "包裝時間",
  "payer_account": "付款人帳號",
  "payment_method": "付款方式",
  "payment_to_carrier_by": "付款給承運人",
  "pick_qty": "Pick QTY",
  "picked_date": "Picked Date",
  "picked_qty": "已撿數量",
  "picked_time": "Picked Time",
  "pid": "PID",
  "planned_driver_arrival_date": "司機計劃到達日期",
  "planned_driver_arrival_time": "司機計劃到達時間",
  "planned_out_for_delivery_time": "計劃外出遞送時間",
  "plt_qty": "棧板數量",
  "po_received_date": "訂購單日期",
  "po_received_time": "訂購單時間",
  "pod": "POD 時間",
  "pod_date": "POD 日期",
  "pod_file": "POD 文件",
  "preassigned_carrier": "預指派承運人",
  "preassigned_carrier_service_level": "預指派承運人服務",
  "primary_driver_license_no": "駕駛證號",
  "primary_driver_name": "司機",
  "primary_driver_phone_no": "司機電話",
  "print": "列印",
  "print_label": "列印標籤",
  "priority": "優先權",
  "product_desc": "產品名稱",
  "product_sku": "產品料號",
  "product_sn": "產品序號",
  "quantity": "數量",
  "query": "查詢",
  "query_date": "日期查詢",
  "query_ref": "參考",
  "rcv_date": "收貨日期",
  "rcv_qty": "RCV QTY",
  "received_date": "收貨完成日",
  "received_qty": "實際收貨量",
  "received_time": "收貨完成時間",
  "reference": "參考資訊",
  "tms": "參考文件",
  "reference_files": "參考文件",
  "reference_no": "參考單號",
  "remark_general": "倉庫指示",
  "remark_label": "客戶指示",
  "remark_receive": "入庫指示",
  "remark_shipping": "出貨指示",
  "remark_topic": "備註",
  "require_sn": "需要序號",
  "result": "結果",
  "rma_qty": "換貨數",
  "sequence": "順序",
  "sharp_bols": "DN數",
  "sharp_consignments": "Consignment數",
  "sharp_deliveries": "SO數",
  "sharp_dns": "DN數",
  "sharp_gross_weight": "毛重",
  "sharp_gw": "總重",
  "sharp_packages": "包裹數",
  "sharp_pkgs": "包裹數",
  "sharp_shipments": "Consignment數",
  "ship_from": "起運",
  "ship_mode": "運輸方式",
  "ship_to": "收貨",
  "ship_to_city": "收貨城市",
  "ship_to_country": "Ship To Country",
  "sn": "序號",
  "to_name": "收貨人",
  "ship_to_state": "收貨州/省",
  "ship_type": "出貨類型",
  "shipment_customer": "客戶",
  "shipment_mode": "運輸方式",
  "shipment_no": "Consignment No",
  "shipped": "出倉",
  "shipped_date": "出倉日期",
  "shipped_qty": "出倉數",
  "shipped_time": "出倉時間",
  "shipper_account": "托運人帳號",
  "site": "地點",
  "sku": "SKU",
  "so": "SO",
  "so_and_package": "SO & Package",
  "so_no": "出貨單號",
  "sorting_completed_date": "分揀完成日",
  "sorting_completed_time": "分揀完成時間",
  "special_delivery_instruction": "特殊遞送指示",
  "special_instruction": "特殊指示",
  "station": "站點",
  "status": "狀態",
  "summary": "概要",
  "svc_type": "服務類型",
  "tms_shipment_leg": "TMS 送貨類型",
  "to_address": "收貨地址",
  "to_city": "收貨城市",
  "to_country": "收貨國家/地區",
  "to_pack_id": "裝箱號",
  "to_state": "收貨州/省",
  "total_gross_weight": "總重",
  "total_gw": "毛重合計",
  "total_lines": "出貨項次",
  "total_packages": "PKG數",
  "total_qty": "總數",
  "tracking_device_number": "追踪器號碼",
  "tracking_no": "追踪號碼",
  "trailer_no": "拖車號",
  "truck_no": "車牌",
  "truck_type": "車型",
  "type": "Type",
  "uom": "尺寸單位",
  "urgent_delivery": "急件",
  "valuable": "高單價",
  "vip_name": "客戶",
  "weight": "重量",
  "weight_unit": "重量單位",
  "width": "寬",
  "will_also_save_fields_confirm": "以下變更欄位也會被存入: %{change_fields}",
  "wms": "兩用",
  "wms_clients": "倉庫客戶",
  "pick_area": "Pick Area",
  "sku_description": "SKU Description",
  "new": "New",
  "verification_code": "驗證碼",
  "lot_no": "Lot No",
  "line": "Line #",
  "ship_to_name": "Ship-To Name",
  "post_code": "Postcode",
  "customer_line": "Customer Line #",
  "packed": "Packed",
  "aged_stock": "Aged Stock",
  "bonded": "Bonded",
  "damage": "Damage",
  "expired": "Expired",
  "hold": "Hold",
  "inspection": "Inspection",
  "quarantine": "Quarantine",
  "returns": "Returns",
  "write_off": "Write Off",
  "demo_stock": "Demo Stock",
  "good": "Good",
  "manufacture_date": "Manufacture Date",
  "po_no": "Po No",
  "inbound_sn": "Inbound SN",
  "special_remark": "Special Remark",
  "special": "Special",
  "ship_info": "Ship Info",
  "tracking_number": "Tracking Number",
  "custom_po_number": "Customer PO Number",
  "po_number": "PO Number",
  "reference_number": "Reference Number",
  "remarks": "Remarks",
  "edi_acked": "EDI Acked",
  "ibound_tally_sheet": "Ibound Tally Sheet",
  "picking_list": "Picking List",
  "packing_list": "Packing List",
  "customer_code": "Customer Code",
  "client": "客戶",
  "portal_query": "查詢",
  "portal_result": "結果",
  "portal_type": "類型",
  "portal_multiple_keyword": "可輸入多個關鍵字",
  "portal_customer": "客戶",
  "portal_wms_client": "倉庫客戶",
  "portal_status": "狀態",
  "portal_available": "Available",
  "portal_in_progress": "進行中",
  "portal_completed": "已完成",
  "portal_cancelled": "已取消",
  "portal_date": "日期",
  "option_milstone_edi_date": "EDI 日期",
  "option_milstone_received_date": "收貨完成日期",
  "portal_keyword_search": "關鍵字查詢",
  "portal_print_its": "列印收貨單",
  "portal_please_tick_asn_with_status_in_progress": "請勾選狀態為進行中的收貨單",
  "portal_please_tick_order_with_status_in_progress": "請勾選狀態為進行中的出貨單",
  "portal_edit_remark": "編輯備註",
  "portal_please_check_asn_to_edit": "請勾選欲編輯備註的收貨單",
  "portal_confirmation_report": "完成報告",
  "portal_please_specify_received_date_in_date_query_fields": "請在日期查詢欄位指定收貨完成日期",
  "portal_cust_ref": "客戶單號",
  "portal_arrival_type": "運送方式",
  "portal_hawb_bl": "空 / 海運提單",
  "portal_edi_date": "EDI 日期",
  "portal_arrival_date": "進倉日期",
  "portal_received_date": "收貨完成日期",
  "portal_asn": "收貨單號",
  "portal_remark": "備註",
  "portal_so": "出貨單號",
  "portal_customer_po": "客戶單號",
  "portal_order_type": "訂單類型",
  "portal_ship_to_name": "出貨對象",
  "portal_country": "國家",
  "portal_total_qty": "出貨數量",
  "portal_order_start_date": "開始揀貨日期",
  "portal_completed_date": "包裝完成日期",
  "portal_shipped_date": "出倉日期",
  "portal_shipped": "已出倉",
  "portal_edi_acked": "EDI 已發送",
  "portal_order_number": "出貨編號",
  "portal_customer_ref": "客戶單號",
  "portal_sku": "產品料號",
  "portal_serial_number": "序號",
  "portal_tracking_number": "託運單號",
  "portal_print_manifest": "列印預約通知",
  "portal_please_tick_order_to_edit": "請勾選欲編輯備註的出貨單",
  "portal_please_specify_shipped_date_in_date_query_fields": "請在日期查詢欄位指定出倉日期",
  "portal_please_input_load_number": "請輸入預約單號",
  "portal_do_you_want_to_print_this": "確認列印 ?",
  "portal_ok": "確認",
  "portal_cancel": "取消",
  "portal_print_picking_list": "列印揀貨單",
  "portal_no_data": "無資料",
  "portal_invalid_load_number": "預約單號格式錯誤",
  "portal_miss_options": "Miss Options",
  "portal_fetch_error": "Fetch Error",
  "portal_something_wrong": "Something Wrong",
  "portal_download_error": "Download Error",
  "portal_inbound_detail": "Inbound Detail",
  "portal_site": "Site",
  "portal_reference_no": "Reference No",
  "portal_customer_reference": "Customer Reference",
  "portal_required": "必要的",
  "portal_save": "Save",
  "portal_success": "Success",
  "portal_print_success": "列印成功",
  "portal_print_error": "Print Error",
  "portal_inbound_sn": "Inbound SN",
  "portal_po_no": "PO No",
  "portal_receiving_note": "Receiving Note",
  "portal_shipping_instruction": "出貨指示",
  "portal_warehouse_instruction": "倉庫指示",
  "portal_note_to_customer": "客戶指示",
  "portal_line": "Line #",
  "portal_lot_no": "Lot No",
  "portal_asn_qty": "ASN Qty",
  "portal_received_qty": "Received Qty",
  "portal_manufacture_date": "Manufacture Date",
  "portal_require_sn": "Require S/N",
  "portal_pack_id": "Pack ID",
  "portal_serial_no": "Serial No",
  "portal_sku_desc": "SKU Desc",
  "portal_inbound": "Inbound",
  "portal_remark_too_long_and_limit_to_variable_characters": "Remark too long and limit to %{max} characters",
  "portal_task": "單別",
  "portal_pick_order": "揀貨單號",
  "portal_total_lines": "出貨項次",
  "portal_please_enter_picking_list_number": "Please Enter Picking List Number",
  "portal_print_orders": "Print Orders",
  "portal_available_qty": "Available QTY",
  "portal_picked_qty": "Picked QTY",
  "portal_packed_qty": "Packed QTY",
  "portal_marketing_qty": "Marketing QTY",
  "portal_damage_qty": "Damage QTY",
  "portal_order_details": "Order Details",
  "portal_order_info": "Order Info",
  "portal_morrison_asn": "Morrison ASN #",
  "portal_morrison_ord": "Morrison ORD #",
  "portal_morrison_customer_code": "Morrison Customer Code",
  "portal_open_date": "Open Date",
  "portal_ship_date": "Ship Date",
  "portal_ship_to": "Ship To",
  "portal_ship_to_code": "Ship To Code",
  "portal_address": "Address",
  "portal_city": "City",
  "portal_zip": "Zip",
  "portal_tel": "Tel",
  "portal_shipping_info": "Shipping Info",
  "portal_expect_ship_method": "Expect Ship Method",
  "portal_actual_ship_method": "Actual Ship Method",
  "portal_courier_tracking_number": "Courier Tracking Number",
  "portal_shipping_cost": "Shipping Cost",
  "portal_package_count": "Package Count",
  "portal_sold_to": "Sold To",
  "portal_sold_to_code": "Sold To Code",
  "portal_sold_to_name": "Sold To Name",
  "portal_incoterm_one": "Incoterm1",
  "portal_incoterm_two": "Incoterm2",
  "portal_special_instructions": "Special Instructions",
  "portal_priority": "Priority",
  "portal_special_note_message_of_consignee": "Special Note (Message of Consignee)",
  "portal_processing_instructions_message_to_warehouse_operators": "Processing Instructions (Message to Warehouse Operators)",
  "portal_documents": "Documents",
  "portal_order_items": "Order Items",
  "portal_packing_details": "Packing Details",
  "portal_customer_line": "Customer Line #",
  "portal_customer_sku": "Customer SKU",
  "portal_description": "Description",
  "portal_plant_delta": "Plant (Delta)",
  "portal_location_delta": "Location (Delta)",
  "portal_order_qty": "Order Qty",
  "portal_shipped_qty": "Shipped Qty",
  "portal_pack_id_box": "Pack ID (Box #)",
  "portal_courier": "Courier",
  "portal_length_cm": "Length (cm)",
  "portal_width_cm": "Width (cm)",
  "portal_hight_cm": "Hight (cm)",
  "portal_weight_kg": "Weight (kg)",
  "portal_desc": "Desc",
  "portal_qty": "數量",
  "portal_OFD": "OFD",
  "portal_po": "Po",
  "portal_order_line": "Order Line",
  "portal_order_quantity": "Order Quantity",
  "portal_edi_ship_method": "EDI Ship Method",
  "portal_edi_time": "EDI Time",
  "portal_order_date": "Order Date",
  "portal_order_time": "Order Time",
  "portal_picked_date": "Picked Date",
  "portal_picked_time": "Picked Time",
  "portal_packed_date": "Packed Date",
  "portal_packed_time": "Packed Time",
  "portal_customer_name": "客戶名稱",
  "portal_customer_order_no": "客戶訂單編號",
  "portal_wms_order_no": "WMS 訂單編號",
  "portal_order_comparison": "訂單比對",
  "portal_update_comparison": "比對結果更新",
  "portal_difference_export": "異動匯出",
  "portal_cust_line": "客戶訂單行",
  "portal_product": "產品",
  "portal_cust_qty": "客戶訂單數量",
  "portal_wms_qty": "WMS 訂單數量",
  "portal_category": "類別",
  "portal_wms_line": "WMS 訂單行",
  "portal_please_select_upload_template": "請選擇上傳模板",
  "portal_wrong_template": "上傳模板錯誤",
  "portal_no_difference": "無差異",
  "portal_comparing_result": "比對結果",
  "portal_upload": "上傳",
  "portal_client": "Client",
  "portal_state": "State",
  "portal_post_code": "Postcode",
  "portal_phone": "Phone",
  "portal_outbound_detail": "Outbound Detail",
  "portal_outbound_package": "Outbound Package",
  "portal_outbound_sn": "Outbound SN",
  "portal_product_sku": "Product SKU",
  "portal_commodity": "Commodity",
  "portal_product_desc": "Product Desc",
  "portal_ordered_qty": "Ordered Qty",
  "portal_package_info": "Package Info",
  "portal_package_id": "Package ID",
  "portal_dimensions": "Dimensions",
  "portal_weight": "Weight",
  "portal_quantity": "Quantity",
  "portal_outbound_attachment": "Outbound Attachment",
  "portal_print_put_back_list": "列印揀貨取消單",
  "portal_please_enter_customer_order_number": "請輸入客戶單號",
  "portal_wrong_customer_order_number": "客戶單號錯誤",
  "portal_wms": "WMS",
  "portal_photo_mgmt_direct_upload": "直接上傳",
  "portal_photo_mgmt_save": "Save",
  "portal_photo_mgmt_check": "確認",
  "portal_photo_mgmt_confirm": "確認",
  "portal_photo_mgmt_order_no": "訂單號碼",
  "portal_photo_mgmt_main_page": "主頁面",
  "portal_photo_mgmt_photo_upload": "前往拍照",
  "portal_photo_mgmt_photo_management": "圖片管理",
  "portal_photo_mgmt_order": "訂單",
  "portal_photo_mgmt_select_site": "選擇站點",
  "portal_photo_mgmt_scan_qrcode_or_enter_order_no": "掃描 QR Code 或是輸入訂單號碼",
  "portal_photo_mgmt_select_process": "選擇 Process",
  "portal_photo_mgmt_please_select_at_least_one_photo": "你至少要選擇一張照片才行",
  "portal_photo_mgmt_select_the_photos_you_surely_want_to_upload": "請選擇您確定要上傳的圖片",
  "portal_photo_mgmt_select_all": "全部選擇",
  "portal_photo_mgmt_cancel": "取消",
  "portal_photo_mgmt_cancel_all": "全部取消",
  "portal_photo_mgmt_please_input_valid_order_number": "請輸入有效的訂單號碼",
  "portal_photo_mgmt_data_uploaded_failed": "注意！檔案資料中發現錯誤，請修正資料並重新上傳",
  "portal_photo_mgmt_take_photo_now": "開始拍照",
  "portal_photo_mgmt_select_source_of_photo": "選擇上傳照片方式",
  "portal_photo_mgmt_compress_img_before_upload": "上傳前壓縮相片",
  "portal_photo_mgmt_photo_source_camera": "前往拍照",
  "portal_photo_mgmt_photo_source_device": "從裝置選取",
  "portal_photo_mgmt_photo_selection_info": "相片: %{number} / %{total}",
  "portal_photo_mgmt_please_grant_the_permission_for_accessing_webcam": "請授予存取照相機權限",
  "portal_photo_mgmt_check_all_photo": "確認照片",
  "portal_photo_mgmt_enter_order_no": "輸入訂單號碼",
  "portal_photo_mgmt_please_enter_valid_order_no": "請輸入有效的訂單號碼",
  "portal_photo_mgmt_select_the_photos_to_upload": "請選擇要上傳的圖片",
  "portal_photo_mgmt_please_take_a_photo": "你至少要拍一張照片才行",
  "portal_photo_mgmt_enter": "Enter",
  "portal_photo_mgmt_take_photo": "開始拍照",
  "portal_photo_mgmt_check_photos": "確認照片",
  "portal_photo_mgmt_the_maximum_of_photos_taken": "拍照上限為 %{number} 張",
  "portal_photos": "Photos",
  "portal_seq": "Seq",
  "portal_original_received_date": "Original Received Date",
  "portal_inbound_ref_hashtag": "Inbound Ref #",
  "portal_area": "Area",
  "portal_location": "Location",
  "portal_sku_description": "SKU Description",
  "portal_onhold_qty": "Onhold QTY",
  "portal_outbound": "Outbound",
  "portal_remark_receive": "入庫指示",
  "portal_photo": "Photo",
  "portal_download_all": "下載全部",
  "portal_upload_max_%{max_length}_ohotos": "Upload (max %{max_length} photos)",
  "portal_ship_type": "Ship Type",
  "portal_asn_received_date": "ASN Received Date",
  "portal_inbound_scheduled_date": "Inbound Scheduled Date",
  "portal_unloading_in_progress_date": "Unloading in Progress Date",
  "portal_sorting_completed_date": "Sorting Completed Date",
  "portal_close_date": "Close Date",
  "portal_reference": "Reference",
  "portal_order_not_exist": "查無訂單",
  "portal_customer_order_number_is_different": "客戶單號錯誤",
  "portal_order_upload_failed": "上傳模板錯誤",
  "portal_does_not_support_order_comparison": "不支援訂單比對",
  "portal_no_export_data": "無匯出資料",
  "portal_tsmc_po": "TSMC PO",
  "portal_tsmc_asn": "TSMC ASN",
  "portal_tsmc_crate": "TSMC Crate",
  "portal_asn_hashtag": "ASN #",
  "portal_po_hashtag": "PO #",
  "portal_crate_hashtag": "Crate #",
  "portal_wms_po_hashtag": "WMS PO #",
  "portal_receiver_date": "Receiver Date",
  "portal_pid": "PID",
  "portal_remarks": "Remarks",
  "portal_so_hashtag": "SO #",
  "portal_inbound_ref": "Inbound Ref",
  "portal_print_batch_picking_list": "列印批次揀貨單",
  "portal_please_enter_batch_order_number": "請輸入批次揀貨單號",
  "portal_print": "列印",
  "transport_control_invalid_date": "Invalid Date",
  "transport_control_iless_than_other_date": "Less than other date",
  "transport_control_carrier_code": "Carrier Code",
  "transport_control_client": "Client",
  "transport_control_description": "Description",
  "transport_control_printer": "Printer",
  "transport_control_service_level": "Service Level",
  "transport_control_rules": "Transport Control Rules",
  "transport_control_printer_mapping": "Printer Mapping",
  "transport_control_printer_setup": "Printer Setup",
  "transport_control_mail_list": "Mail List",
  "transport_control_query": "Query",
  "transport_control_create_success": "Create Success",
  "transport_control_add": "Add",
  "transport_control_destroy_success": "Destroy Success",
  "transport_control_delete": "Delete",
  "transport_control_add_notification": "Add Notification",
  "transport_control_mail": "Mail",
  "transport_control_add_printer_mapping": "Add Printer Mapping",
  "transport_control_add_printer_setup": "Add Printer Setup",
  "transport_control_printer_name": "Printer Name",
  "transport_control_agent_host_key": "Agent Host Key",
  "transport_control_ip_address": "IP Adress",
  "transport_control_port": "Port",
  "transport_control_add_rule": "Add Rule",
  "transport_control_customer_account": "Customer Account",
  "transport_control_configuration": "Configuration",
  "transport_control_less_than_pickup_datetime": "Less than Pickup Datetime",
  "transport_control_less_than_booking_datetime": "Less than Booking Datetime",
  "transport_control_less_than_now": "Less than Now",
  "transport_control_ofd_datetime": "OFD",
  "transport_control_print_order": "Print Order",
  "transport_control_clients": "Clients",
  "transport_control_transport_types": "Transport Types",
  "transport_control_categories": "Categories",
  "transport_control_statuses": "Statuses",
  "transport_control_order_numbers": "Order Numbers",
  "transport_control_ofd_was_filled": "OFD was filled",
  "transport_control_pickup_datetime": "Pickup Datetime",
  "transport_control_booking_datetime": "Booking Datetime",
  "transport_control_no_selected_orders": "No selected orders",
  "transport_control_send_to_booking": "Send to booking",
  "transport_control_send_to_klairy": "Send to Klairy",
  "transport_control_close": "Close",
  "transport_control_update_success": "Update success",
  "transport_control_order_number": "Order Number",
  "transport_control_rollback": "Rollback",
  "transport_control_transport_type": "Transport Type",
  "transport_control_save": "Save",
  "transport_control_trucking_company": "Trucking Company",
  "transport_control_contact_name": "Contact Name",
  "transport_control_address": "Address",
  "transport_control_post_code": "Post Code",
  "transport_control_country": "Country",
  "transport_control_change_to_self_pickup": "Change to Self-pickup",
  "transport_control_courier": "Courier",
  "transport_control_service_type": "Service Type",
  "transport_control_attachment": "Attchment",
  "transport_control_commercial_invoice": "Commercial Invoice",
  "transport_control_export_document": "Export Document",
  "transport_control_total_parcels": "Total Parcels",
  "transport_control_pk_value": "PK Value",
  "transport_control_incoterms": "Incoterms",
  "transport_control_coo": "COO",
  "transport_control_change_to_parcel": "Change to Parcel",
  "transport_control_change_to_pallet": "Change to Pallet",
  "transport_control_host_key": "Host Key",
  "transport_control_status": "Status",
  "transport_control_remark": "Remark",
  "transport_control_pkg_qty": "PKG Qty",
  "transport_control_pack_type": "Pack Type",
  "transport_control_total_gross_weight": "Total Gross Weight",
  "transport_control_has_attachments": "Has Attachments",
  "transport_control_total_pk_count": "Total PK Count",
  "transport_control_total_pk_value": "Total PK Value",
  "transport_control_category": "Category",
  "transport_control_delete_success": "Delete Success",
  "transport_control_printer_document_type": "Printer Document Type",
  "transport_control_printer_location": "Printer Location",
  "transport_control_city": "City",
  "transport_control_tracking_no": "Tracking No.",
  "transport_control_tracking_label": "Tracking Label",
  "undefined": ""
 },
 "kpi": {
  "kpi_owner": "KPI Owner",
  "kpi_owner_department": "KPI Owner Department",
  "kpi_owner_division": "KPI Owner Division",
  "kpi_owner_station": "KPI Owner Station",
  "kpi_owner_region": "KPI Owner Region",
  "kpi_owner_reason": "KPI Owner Reason",
  "kpi_name": "KPI 名稱",
  "kpi_detail": "KPI Detail",
  "kpi_result": "KPI Result",
  "kpi_review_period": "KPI review Period",
  "kpi_date": "KPI Date",
  "kpi_ref_number": "KPI Ref Number",
  "vip_name": "VIP Name",
  "client_id": "Customer ID",
  "kpi_ref_department": "KPI Ref Department",
  "kpi_ref_station": "KPI Ref Station",
  "kpi_ref_region": "KPI Ref Region",
  "kpi_date_review_period": "Review Period",
  "kpi_date_datetime": "Date Time",
  "kpi_group": "KPI Group",
  "kpi_exemption_flag": "Exemption Flag",
  "kpi_exemption_reason": "Exemption Reason",
  "kpi_number": "KPI Amount USD",
  "kpi_reference_type": "KPI Reference Type",
  "kpi_reference_number": "KPI Reference Number",
  "kpi_reference_station": "KPI Reference Station",
  "kpi_reference_department": "KPI Reference Department",
  "kpi_reference_region": "KPI Reference Region",
  "kpi_reference_vip_name": "KPI Reference VIP Name",
  "kpi_reference_client_id": "KPI Reference Client ID",
  "LATE_INVOICE": "Late Invoice",
  "LATE_INVOICE_OVER_60D": "Late Invoice Over 60 Days",
  "LATE_VOUCHER": "Late Voucher",
  "LATE_VOUCHER_OVER_60D": "Late Voucher Over 60 Days",
  "LATE_MILESTONE": "Late Milestone",
  "MISSING_MILESTONE": "MIssing Milestone",
  "TASK_OVERDUE": "Task Overdue",
  "INCOMPLETE_TASK": "Incomplete Task",
  "invalid_format": "Invalid Format",
  "undefined": "",
  "region": "Region",
  "department": "Department",
  "division": "Division",
  "owner": "Owner",
  "station": "Station",
  "year_week": "Year Week",
  "pass_count": "Pass Count",
  "failed_count": "Failed Count",
  "total_count": "Total Count",
  "failed_percentage": "Failed (%)",
  "target_percentage": "Target (%)",
  "amount": "Amount",
  "overdue_percentage": "Overdue (%)",
  "overdue_count": "Overdue Count",
  "assignee": "Asignee (email)",
  "kpi_date_review_period_2022h1": "2022 H1",
  "kpi_date_review_period_2022h2": "2022 H2",
  "kpi_date_review_period_2021h1": "2021 H1",
  "kpi_date_review_period_2021h2": "2021 H2",
  "kpi_date_review_period_2020h1": "2020 H1",
  "kpi_date_review_period_2020h2": "2020 H2",
  "pass_percentage": "Pass (%)",
  "customer_code": "客戶代碼",
  "bill_to_id": "Bill-To Code",
  "origin_port": "Orig",
  "origin_port_country": "Orig Country",
  "destination_port": "Dest",
  "destination_port_country": "Dest Country",
  "created_by": "Created By",
  "requested_by": "Requested By",
  "kpi_exception_type": "KPI Exception Type",
  "effective_date": "Effective Date",
  "exception_service_type": "Exception Service Type",
  "shipment_number": "HAWB / HBL",
  "extension_days": "Day(s)",
  "extension_days_required": "Days*",
  "effective_start": "Effective From (Local Time)",
  "effective_end": "Effective To (Local Time)",
  "kpi_exception_rule_exemption": "Exemption",
  "kpi_exception_rule_extension": "Extension",
  "deactivate": "撤銷",
  "activate": "啟用",
  "error_data_is_duplicated": "",
  "error_input_value_in_enum_attribute": "",
  "exception_type": "Exception Type",
  "mass_upload": "Mass Upload",
  "exception_service_type_required": "Exception Service Type*",
  "exemption_type": "Exemption Type*",
  "master_number": "MAWB / MBL",
  "quotation_number": "Quotation No",
  "charge_code": "Charge Code",
  "exception_invoice_number": "Exception Invoice Number",
  "remark": "Remark*",
  "requester": "Requester*",
  "invalid_service_type": "無效的服務類型",
  "invalid_exception_service_type": "Invalid Exception Service Type",
  "invalid_exemption_type": "Invalid Exemption Type",
  "invalid_department": "Invalid Department",
  "invalid_extension_type": "Invalid Extension Type",
  "invalid_incoterm": "Invalid Incoterm",
  "invalid_requester": "Invalid Requester",
  "extension_type": "Extension Type*",
  "extension_type_first_bill": "FIrst Bill",
  "extension_type_second_bill": "Second Bill",
  "extension_type_all_bill": "All Billing",
  "extension_type_holiday": "Holiday"
 },
 "hrs": {
  "undefined": "",
  "division": "部門",
  "need_action": "新進人員 / 職務調動",
  "name": "姓名",
  "station": "站點",
  "status": "狀態",
  "region": "區域",
  "download_template": "檔案下載",
  "check": "check",
  "employee_id": "員工編號",
  "update_detail_description": "你有填寫Other main task 的百分比，請再填入相對應的detail描述才能正確儲存資料",
  "update_detail_total_percentage": "儲存失敗，每一位員工的 total (%) 必須要是 100%，請確認%{id}員工的資料",
  "update_error_no_employee_id": "Missing employee ID, please check your excel",
  "update_error_no_review_start_date": "Missing review period, please check your excel",
  "update_error_employee_id_and_review_start_date_not_created_yet": "The employee ID or review period is incorrect",
  "update_error_illegal_percentage": "不正確的百分比數字，請確認  ID %{id} 員工的資料",
  "unknown_error_on_row": "發生未知錯誤，請聯絡MOP help desk",
  "is_confirmed": "確認狀態",
  "review_period": "資料區間",
  "bulk_confirm": "批次確認",
  "please_select_at_least_one_profile": "請勾選至少一筆資料",
  "import": "匯入",
  "import_failed": "注意！檔案資料中發現錯誤，請修正資料並重新上傳",
  "import_sheet_with_illegal_header": "你所上傳的Excel有變更到header欄位 : 欄位 %{cellname} 應該是 \"%{expect}\" 但檔案中是 \"%{actual}\"       ",
  "update_data_completed_message": "保存完成。您已更新 %{number} 個員工的數據。 ID：${update_id_list}",
  "bulk_confirm_count": "目前已勾選 %{number} 個項目",
  "before_bulk_confirm_title": "是否變更 Confirm 狀態為 Y",
  "before_bulk_confirm_message": "目前已勾選 %{number} 個項目，是否將 Confirm 狀態變更為 Y ?",
  "NEW_HIRED": "新進員工",
  "JOB_CHANGE": "職位異動 / 部門異動",
  "before_save_confirm_title": "儲存已編輯的資料",
  "before_save_confirm_message": "你編輯了 %{number} 位職員的 Job weighting，是否進行資料儲存？",
  "before_save_confirm_sync_bulk_confirm_field": "同步變更 confirm 狀態為 Y",
  "after_save_confirm_title": "儲存成功",
  "after_save_confirm_message": "成功儲存資料",
  "after_save_confirm_and_sync_confirm_field_message": "成功儲存資料且更新 Confirm 狀態",
  "nothing_changed": "無資料變更, 不用存檔",
  "you_have_select_number_of_data": "您已選取 %{number} 筆資料",
  "error_no_data_selected": "沒有選取任何資料",
  "error_data_no_review_period": "上傳的員工資料不存在目前的的 Review period 中，請確認。ID: %{id} 以及 review period",
  "are_you_sure_to_confirm_these_employee_data": "Confirmed 狀態變更後就無法更改，是否將 Confirmed 狀態變更為Ｙ，%{employee_list}",
  "location": "地點",
  "is_abnormal": "異常",
  "detail_total_percentage_warning": "Biggest Job Weighting is in \"%{topDuty}\", but this employee is in \"%{division}\"",
  "all_record_will_not_save": "所有輸入皆未儲存，請修改錯誤資料後再次儲存。",
  "review_period_hint": "\"資料區間\"有修改, 請記得重新\"搜尋\"",
  "handover_submit_error_ofd_earlier_than_adat": "外出遞送時間早於司機實際到達時間，請仔細檢查後再錄入",
  "handover_submit_error_ofd_later_than_current": "晚於當前時間，請仔細檢查後再錄入",
  "handover_submit_error_ofd_later_than_pod": "簽收時間早於外出遞送時間，請仔細檢查後再錄入",
  "handover_submit_error_pod_later_than_current": "簽收時間晚於當前時間，請仔細檢查後再錄入"
 },
 "sop_mgmt": {
  "sop_category": "SOP類別",
  "vip_name": "VIP名稱",
  "sop_name": "SOP名稱",
  "ship_mode": "運輸模式",
  "sop_station": "站點",
  "sop_station_type_billing_station": "Billing Station",
  "sop_station_type_handling_station": "Handling Station",
  "sop_station_type_export_station": "Export Station",
  "sop_station_type_import_station": "Import Station",
  "sop_station_type_control_tower": "Control Tower​",
  "sop_station_type_trans_ship_station": "Trans-ship Station​",
  "step_one": "步驟 1",
  "step_two": "步驟 2",
  "step_three": "步驟 3",
  "process_category": "流程類別",
  "version": "版本",
  "valid_from": "生效日",
  "valid_to": "到期日",
  "customer_type": "客戶",
  "instruction": "說明",
  "url": "URL",
  "version_tooltip": "不可小於原有版號",
  "sop_step_one_header_description": "設定SOP類別資訊",
  "sop_step_two_header_description": "決定適用站點",
  "sop_step_three_header_description": "選擇一或多個檔案",
  "sop_publish": "發布",
  "sop_number": "SOP編號",
  "sop_status": "文件狀態",
  "validity": "文件效力",
  "validity_valid": "生效",
  "validity_invalid": "失效",
  "sop_status_draft": "Draft",
  "sop_status_approved": "已核准",
  "sop_status_canceled": "已取消",
  "attachment_list": "檔案",
  "invalid_version": "無效版本",
  "fuzzy_search": "模糊搜尋",
  "export_station_list": "Export",
  "import_station_list": "Import",
  "handling_station_list": "Handling",
  "billing_station_list": "Billing",
  "trans_ship_station_list": "Trans-Ship",
  "control_tower_list": "Control Tower",
  "file_duplicate": "檔名重複",
  "are_you_sure_want_to_delete": "確認刪除這份SOP?",
  "sop_category_placeholder": "單選",
  "sop_name_placeholder": "請輸入SOP名稱",
  "process_category_placeholder": "多選",
  "version_placeholder": "ex. 1.0",
  "instruction_placeholder": "其他說明或操作指南",
  "url_placeholder": "請填入影片網址",
  "sop_number_placeholder": "ex. SP0001",
  "sop_category_customer": "客戶SOP",
  "sop_category_standard": "標準SOP",
  "sop_category_policy": "政策",
  "sop_category_guideline": "準則",
  "ship_mode_placeholder": "多選",
  "stations_placeholder": "搜尋...",
  "vip_name_placeholder": "搜尋...",
  "customer_type_placeholder": "搜尋...",
  "edit_attachment_list": "檔案",
  "standard_process_category_standard_quotation_mgmt": "Standard Quotation Mgmt",
  "standard_process_category_export_operation_mgmt": "Export Operation Mgmt",
  "standard_process_category_export_booking_handling": "Export Booking Handling",
  "standard_process_category_export_consol_processing": "Export Consol Processing",
  "standard_process_category_export_awb_or_bl_processing": "Export AWB/ BL Processing",
  "standard_process_category_export_others": "Export Others",
  "standard_process_category_import_operation_mgmt": "Import Operation Mgmt",
  "standard_process_category_import_consol_handling": "Import Consol Handling",
  "standard_process_category_import_awb_or_bl_processing": "Import AWB/ BL Processing",
  "standard_process_category_import_dispatch_and_pod": "Import Dispatch & POD",
  "standard_process_category_import_other_main_task": "Import Others",
  "standard_process_category_logistics_operation_mgmt": "Logistics Operation Mgmt",
  "standard_process_category_logistics_customer_service_mgmt": "Logistics Customer Service Mgmt",
  "standard_process_category_logistics_warehouse_mgmt": "Logistics Warehouse Mgmt",
  "standard_process_category_logistics_distribution_mgmt": "Logistics Distribution Mgmt",
  "standard_process_category_logistics_billing": "Logistics Billing",
  "standard_process_category_logistics_others": "Logistics Others",
  "customer_process_category_customer_quotation_mgmt": "Quotation Mgmt",
  "customer_process_category_shipment_data_and_milestone": "Shipment Data & Milestone",
  "customer_process_category_warehouse_operation": "Warehouse Operation",
  "customer_process_category_billing": "Billing",
  "customer_process_category_control_tower_and_kpi": "Control Tower & KPI",
  "customer_process_category_agent_mgmt": "Agent Mgmt",
  "invalid_url": "無效的連結",
  "invalid_date_range": "到期日不可早於生效日",
  "error_resource_not_found": "找不到該筆資料",
  "error_version_not_match": "版號不符",
  "error_version_lower": "不可小於現有版號",
  "error_attachment_duplicate": "檔名重複新增",
  "error_valid_interval_not_match": "到期日不可早於生效日",
  "error_sop_name_duplicate": "該SOP名稱已存在，請使用新名稱。",
  "error_item_list_count": "需要至少一筆資料",
  "create_successfully": "發布成功",
  "update_successfully": "儲存成功",
  "please_add_least_min_stations": "請新增至少一個站點",
  "published_at": "發布時間(UTC)",
  "updated_at_utc": "更新時間(UTC)",
  "published_by": "發布者",
  "error_invalid_station": "以下站點不存在，請移除: ",
  "invalid_level_value": "無效的Level: %{level}",
  "error_invalid_station_value": "Station第 %{row_index} 列 - %{station}",
  "error_process_category_not_match": "",
  "is_reviewed": "Reviewed by HQ",
  "undefined": ""
 },
 "3rd_party": "第三方",
 "abbr_no": "No",
 "accept_all_cookies": "同意所有Cookies",
 "accept_all_cookies_v20201223": "如果您繼續瀏覽且並未改變您的設定，或是您點擊了\"同意所有Cookies\"，我們將認為您同意接收所有來自MEC網站的 Cookies。當然您也可以隨時改變您的Cookies設定。",
 "accept_terms_and_conditions_is_required": "必須同意條款和條件",
 "account": "帳號",
 "action": "操作",
 "actions": "動作",
 "actual_arrive_transshipment_port": "實際抵達中轉站",
 "actual_delivery_date": "實際遞送時間",
 "actual_delivery_date_should_be_greater_actual_pickup_date": "實際遞送時間應在實際提貨時間之後",
 "actual_delivery_dt_utc": "實際遞送時間",
 "actual_delivery_time": "實際遞送時間",
 "actual_depart_transshipment_port": "實際離開中轉站",
 "actual_driver_arrival_time": "實際司機抵達倉庫",
 "actual_ofd_time": "外出遞送時間",
 "actual_out_for_delivery_date": "外出遞送日期",
 "actual_out_for_delivery_time": "外出遞送時間",
 "actual_pickup_at": "提貨時間",
 "actual_pickup_time": "實際提貨時間",
 "acutal_arrive_final_usca_destination": "實際抵達美國/加拿大最終站",
 "acutal_arrive_first_usca_destination": "實際抵達美國/加拿大首站",
 "acutal_pickup_time": "實際提貨",
 "add": "新增",
 "add_at_least_one_shipment_to_bol": "至少新增一個 Shipment 至送貨單",
 "add_bol": "加入送貨單(BOL)",
 "add_comment": "新增備註",
 "add_dimension": "新增尺寸",
 "add_field_select": "Add Field Select",
 "add_hawb": "加入 HAWB",
 "add_minus_n": "± Num",
 "add_part": "新增料件細節",
 "add_type": "新增類型",
 "address": "地址",
 "adjust_column": "欄位調整",
 "advanced_search": "進階搜尋",
 "ae": "AE",
 "agent": "Agent",
 "agree": "同意",
 "air": "空運",
 "alert": "警告",
 "alert_station": "警告站點",
 "all": "全部",
 "all_docs_received": "全部文件接收",
 "all_station": "All Station",
 "all_the_unsaved_work_will_be_lost": "全部未儲存的內容將會遺失",
 "allow_to_view_function": "允許使用的功能",
 "amat": "AMAT",
 "amount": "金額",
 "ap_ar": "應付&應收帳款",
 "ap_processing": "應付帳款處理中",
 "api": "API",
 "application_data_will_process_to_review": "申請資料將進行審核",
 "appointment_no": "Appointment No",
 "appointment_number": "Appt/C#",
 "approved_amount": "核准金額",
 "apptc_no": "送貨預約號碼",
 "are_you_sure_to_delete_the_mawb": "您確定要刪除此 MAWB 嗎?",
 "are_you_sure_to_delete_this_view": "Are you sure to delete this view?",
 "are_you_sure_you_want_to_cancel_this_invoice_number": "您確定要刪除此發票嗎?",
 "are_you_sure_you_want_to_confirm_the_dispatch": "您要確認此派車單(Dispatch)嗎?",
 "are_you_sure_you_want_to_delete_the_subscription": "您確定要刪除此訂閱嗎？",
 "are_you_sure_you_want_to_edit_it": "您確定要編輯嗎?",
 "are_you_sure_you_want_to_edit_the_dispatch": "您確定要編輯此派車單(Dispatch)嗎?",
 "are_you_sure_you_want_to_exit": "您確定要離開嗎?",
 "are_you_sure_you_want_to_save_the_dispatch": "您確定要儲存此派車單(Dispatch)嗎?",
 "arrival_notice_sent_date": "發送抵達通知",
 "arrive_cfs_date": "抵達出口 CFS 倉庫日期",
 "arrived": "抵達",
 "arrived_at_dest_cfs": "抵達進口 CFS 倉庫",
 "arrived_ata": "抵港(ATA)",
 "arrived_cfs": "到達目的地CFS",
 "arrived_dest_cfs": "抵達進口 CFS 倉庫",
 "asn_details": "ASN 詳情",
 "asn_info": "ASN 資訊",
 "asn_items": "ASN 項目",
 "asn_qty": "ASN 數量",
 "asn_received_date": "ASN 接收時間",
 "assign_carrier": "指派承運人",
 "assign_to": "指派給",
 "assigned": "已指派",
 "assigned_carrier": "已指派承運人",
 "assigned_to_bol": "已指派给送貨單(BOL)?",
 "assigned_to_carrier": "已指派承運人",
 "assigned_to_dispatch": "已創建派車單(Dispatch)",
 "assigned_to_driver": "已指派司機",
 "assistant_driver_1_name": "隨車人员",
 "assistant_driver_2_name": "隨車人员",
 "at_risk": "處於風險",
 "ata": "實際到港(ATA)",
 "ata_date": "實際到港(ATA)",
 "atd": "實際離港(ATD)",
 "attachment_list": "附件",
 "attributes": "屬性",
 "audit_actions": "審計動作",
 "aw": "實際重量",
 "awb_doc": "提單文件",
 "awgw": "實重/毛重",
 "back": "返回",
 "bid_required": "比價",
 "bigger_than_end_number": "大於結束值",
 "bigger_than_end_on": "大於開始值",
 "bigger_then_endon": "大於結束條件",
 "bill_to_code": "Bill-To code",
 "billto_party": "付款方 (Bill-to)",
 "bol_details": "送貨單(BOL)詳情",
 "bol_dispatch_creation_successfully": "派車單(Dispatch)創建成功。",
 "bol_grouping_rule": "貨件合併到送貨單(BOL)規則",
 "bol_no": "送貨單(BOL)號碼",
 "bol_pod": "簽收地點",
 "bol_shipment_mode": "運輸方式",
 "bol_shipto_city": "收貨城市",
 "bol_shipto_country": "收貨國家/地區",
 "bol_shipto_name": "收貨方",
 "bol_special_instruction": "送貨指示(BOL)",
 "bol_station": "站點",
 "bol_status": "送貨單(BOL)狀態",
 "bol_status_update": "送貨單(BOL)狀態更新",
 "bol_type": "送貨單(BOL)類型",
 "bol_warning": "送貨單(BOL)警告",
 "bols": "送貨單(BOL)",
 "bols_added": "已加入的送貨單(BOL)",
 "booked_on": "Booked On",
 "booking": "預訂：",
 "booking_id": "Booking ID",
 "booking_mgmt": "預訂管理",
 "booking_no": "Booking No",
 "box": "箱",
 "broker_date": "Broker Date",
 "by": "By",
 "by_clicking_alert_your_unsaved_data_will_also_be_saved": "點擊告警，將會儲存尚未儲存的資料。",
 "by_selecting_this_you_confirm_the_value_and_detail_of_your_uploaded_invoice_and_linked_reference_numbers_are_correct": "您確認上傳的發票內容、金額以及關聯的參考號碼皆正確。",
 "c": "C",
 "call_printer": "呼叫印表機",
 "can_not_be_0": "不可為0",
 "can_not_contain_special_characters": "不可包含特殊字元",
 "cancel": "取消",
 "cancel_bol": "取消送貨單 (BOL)",
 "cancel_booking": "取消預訂",
 "cancel_edit": "取消編輯",
 "canceled": "已取消",
 "cancelled": "已取消",
 "cannot_be_empty": "不可為空",
 "cannot_be_empty_or_zero": "不可為空或是0",
 "cannot_update_to_null": "無法更新為 null",
 "cant_get_notreceived_count_error": "無法取得未收貨的數量",
 "cargo_receipt": "貨物接收",
 "cargosolution": "CARGO SOLUTION",
 "carrier": "承運人",
 "carrier_released": "承運人放行",
 "carrier_service_level": "承運人服務",
 "carton": "箱",
 "cartons_in_pallet": "棧板箱數",
 "cartons_in_ref": "Cartons in Ref#",
 "cash_on_delivery": "COD 金額",
 "cash_on_delivery_amount": "COD 金額",
 "category": "類別",
 "category_section": "類別",
 "cbm": "立方米",
 "cbf": "立方英尺",
 "changed_by_name_on_time": "%{name} 修改在 %{time}",
 "charge_code": "Charge Code",
 "charge_item": "收費項目",
 "charge_weight": "收費重量",
 "check_first_memo": "請先執行\"檢查\"",
 "check_result": "檢核結果",
 "checked_in_counter": "已指派承運人",
 "checked_to_confirmed": "確認",
 "children_node_will_have_the_same_privilege_as_parent": "子節點將擁有和父節點相同的權限",
 "choose_one": "選擇一個",
 "choose_policy": "選擇權限",
 "choose_the_put_away_location": "選擇入庫地點",
 "city": "城市",
 "clear_options": "全部清除",
 "cleared_customs": "清關",
 "click_here_or_drop_a_file_to_upload": "點擊或是拖拉檔案以上傳",
 "client_code": "客戶代碼",
 "close": "關閉",
 "close_date": "關閉日期",
 "closed": "已關閉",
 "cod_amount_fee_terms": "貨到付款條款",
 "column_with_error": "發生錯誤的欄位",
 "comment": "註釋",
 "comment_category": "註釋類別",
 "comment_category_close_task": "關閉任務",
 "cm": "公分",
 "cm_and_inch_are_available": "可用公分和吋",
 "cod": "貨到付款",
 "collect": "Collect",
 "column_name": "欄位名稱",
 "comment_category_exception": "例外",
 "comment_category_external_normal": "External Normal",
 "comment_category_internal_normal": "Internal Normal",
 "comment_category_late_billing_reason": "帳務延遲原因",
 "comment_category_message_code": "Message Code",
 "comment_category_normal_update": "一般更新",
 "comment_category_pending_reason": "等待原因",
 "comment_category_action_required": "需要採取的行動",
 "comment_category_tms_exception": "TMS 送貨異常",
 "comment_category_tms_exception_message_code": "送貨異常代碼",
 "comment_category_tms_exception_message_code_A2": "A2-地址錯誤",
 "comment_category_tms_exception_message_code_AG": "AG-其他收貨人相關",
 "comment_category_tms_exception_message_code_AJ": "AJ-其他承運人相關",
 "comment_category_tms_exception_message_code_AN": "AN-放假關門",
 "comment_category_tms_exception_message_code_B1": "B1-收貨人關門",
 "comment_category_tms_exception_message_code_BG": "BG-其他",
 "comment_category_tms_exception_message_code_S1": "S1-貨物短少",
 "comment_here": "在此新增註釋",
 "comment_category_void_shipment": "取消貨物",
 "commercial_inv": "商業發票",
 "commercial_invoice": "商業發票",
 "commercial_invoice_doc": "商業發票",
 "company_name": "公司名稱",
 "company_phone_number": "公司電話號碼",
 "complete": "完成",
 "completed": "已完成",
 "completed_date": "完成時間",
 "comprehensive_routing_service_type_cross_border_truck": "跨邊境卡車運輸",
 "comprehensive_routing_service_type_customs": "關務服務",
 "comprehensive_routing_service_type_domestic_flight": "國內航班運輸",
 "comprehensive_routing_service_type_domestic_truck": "國內卡車運輸",
 "comprehensive_routing_service_type_integrated": "整合服務",
 "comprehensive_routing_service_type_international_flight": "國際航班",
 "comprehensive_routing_service_type_loading_and_unloading": "上貨與卸貨",
 "comprehensive_routing_service_type_packing": "包裝",
 "comprehensive_routing_service_type_warehousing": "倉儲",
 "comprehensive_routing_truck_type_air_ride_truck": "氣墊卡車",
 "comprehensive_routing_truck_type_platform_truck": "平板卡車",
 "comprehensive_routing_truck_type_refrigerated_van": "冷藏貨車",
 "comprehensive_routing_truck_type_refrigerated_van_for_dangerous_goods": "危險品冷藏貨車",
 "comprehensive_routing_truck_type_temperature_controlled_air_ride_truck": "溫控氣墊卡車",
 "comprehensive_routing_truck_type_van": "普通廂式貨車",
 "comprehensive_routing_truck_type_vehicle_for_dangerous_goods": "危險品貨車",
 "comprehensive_routing_truck_type_sprinter": "輕型貨車",
 "confirm": "確認",
 "confirm_bol": "確認送貨單(BOL)",
 "confirm_password": "確認密碼",
 "confirmed": "已確認",
 "consignee": "收貨方",
 "consignee_address": "收貨方地址",
 "consignee_address_local_language": "收貨方中文地址",
 "consignee_address_localized": "收貨地址(中文)",
 "consignee_address1": "收貨方地址",
 "consignee_address2": "收貨方地址",
 "consignee_city": "收貨方城市",
 "consignee_code": "收貨方代碼",
 "consignee_country": "收貨方國家/地區",
 "consignee_ctry": "收貨方國家/地區",
 "consignee_idamat": "收貨方ID(AMAT)",
 "consignee_name": "收貨方",
 "consignee_name1": "收貨方名稱1",
 "consignee_name2": "收貨方名稱2",
 "consignee_state": "收貨方州/省",
 "consignee_zip": "收貨方郵遞區號",
 "consol_closed": "Consol Close",
 "contact": "聯繫人",
 "contact_phone": "聯繫人電話",
 "contact_email": "聯繫人電子郵件",
 "container_no": "貨櫃號碼",
 "content": "內容",
 "continue": "繼續",
 "continue_to_cancel_this_bill_of_lading": "要取消該送貨單(BOL)嗎？",
 "continue_to_save": "要繼續儲存嗎?",
 "continue_uploading": "繼續上傳",
 "contractual_ctn_qty": "約定箱數",
 "contractual_loose_ctn_qty": "約定散箱數",
 "contractual_pallet_qty": "約定棧板數",
 "contractual_pcs_qty": "約定件數",
 "contractual_wgt": "約定重量",
 "contry_region": "Country Region",
 "conversation_id": "Conversation Id",
 "convert_to_ap": "轉換至應付",
 "copy_from_export": "從出口複製",
 "cost": "成本",
 "country": "國家/地區",
 "country_of_origin": "原產地",
 "create": "創建",
 "create_bol_failed": "創建送貨單(BOL)失敗",
 "create_by": "創建人",
 "create_csv_failed": "Create CSV failed ",
 "create_date": "創建時間",
 "create_date_end": "新增時間結束",
 "create_date_start": "新增時間開始",
 "create_date_utc": "創建時間(UTC)",
 "create_master": "創建主單",
 "create_password": "創建密碼",
 "create_subscription": "建立訂閱",
 "create_success": "創建成功",
 "created_at": "建立時間",
 "created_at_utc": "創建時間(UTC)",
 "created_by": "創建者",
 "created_date": "創建日期",
 "created_utc_time": "創建時間(UTC)",
 "creation_date": "建立日期",
 "creator": "創建者",
 "cron_no_data_input": "Cron No Data Input",
 "cron_schedule": "排程",
 "csv_upload": "CSV檔上傳",
 "ctn_qty": "箱數",
 "currency": "幣別",
 "custom_clearance_date": "清關",
 "custom_value": "報關價值",
 "customer": "客戶",
 "customer_check_acceptable": "接受客戶檢查",
 "customer_code": "客戶代碼",
 "customer_id": "客戶ID",
 "customer_name": "客戶名稱",
 "customer_reference_number": "客戶參考號碼",
 "customer_selfpickup": "客戶自提",
 "customer_tracking": "追踪貨件",
 "customers": "客戶",
 "customized_setting": "個人化設定",
 "customized_table_header": "個人化表頭",
 "customs_cleared": "清關",
 "customs_released": "海關放行",
 "cw": "收費重量",
 "dangerous_good": "危險品",
 "data": "資料",
 "data_export": "匯出",
 "data_uploaded_failed": "注意！檔案資料中發現錯誤，請修正資料並重新上傳",
 "data_uploaded_successfully": "資料上傳成功 ",
 "data_validator_error_0001": "你目前權限無法看到此一HAWB資訊",
 "data_validator_error_0002": "尚未完成配艙，HAWB與MAWB的關聯未建立",
 "data_validator_error_0003": "尚未收到EDI945",
 "data_validator_error_0004": "此一HAWB不存在，請確認是否輸入的內容正確",
 "data_validator_error_0005": "尚未在Prolink建報價(系統未紀錄客戶代碼)",
 "data_validator_error_0006": "來自其他原因，請與IT人員進一步確認",
 "data_validator_error_0007": "資料存在，請檢查搜尋條件",
 "data_validator_error_0008": "標籤已經透過緊急輸入生成 (情境：其他)",
 "data_validator_error_0009": "標籤已經透過緊急輸入生成 (情境：未收到EDI)",
 "data_validator_error_0010": "配艙資料(shipment_to_master)不存在",
 "data_validator_error_0011": "權限不足",
 "data_validator_error_no_authorization": "你沒被授權接觸此筆資料",
 "data_validator_error_empty_vip_name": "客戶代碼為空值",
 "data_validator_error_empty_handling_pieces": "沒有設定HAWB件數",
 "data_validator_error_no_cfs_delivery_package_data": "Package ID (delivery_package)資料沒有生成",
 "data_validator_error_no_shipment_data": "MorrisonOne不存在HAWB資料",
 "data_validator_error_no_master_data": "艙位(MAWB)資料不存在",
 "data_validator_error_console_info_is_not_complete": "艙位資料(包含loading plan)沒有完整設定",
 "data_validator_error_not_assign_master_yet": "配艙資料(shipment_to_master)不存在",
 "data_validator_error_empty_customer_code_in_source_db": "客戶代碼為空值",
 "data_validator_error_not_sync_from_source_db_yet": "資料尚未從Prolink/CW1同步過來",
 "data_validator_error_not_received_edi945_yet": "還沒收到EDI945",
 "data_validator_error_not_assign_master_yet_in_source_db": "要確認loading plan是否設定完整",
 "data_validator_error_cannot_find_in_both_source_db_and_mop": "HAWB資料不存在，請確認是否完成booking",
 "data_validator_error_cannot_find_master_in_mop": "MAWB資料不存在，請確認是否完成booking",
 "data_validator_error_shipment_created_time_out_of_range": "這筆HAWB在搜尋的時間範圍之外(是在一個月前建立的)",
 "data_validator_error_empty_etd": "ETD是空值",
 "data_validator_error_wrong_port_in_search_conditions": "資料確實存在，請確認搜尋條件是否正確",
 "wrong_sscc_qty_in_edi945": "EDI945內容錯誤(SSCC數量)",
 "data_validator_error_wrong_sscc_qty_in_edi945": "EDI945內容錯誤(SSCC數量)",
 "data_validator_error_failed_to_invoking_validation_api": "驗證 API 發生錯誤",
 "data_validator_error_other": "由於其他因素，請與IT同仁進一步確認",
 "date_range": "日期區間",
 "date_range_between": "之間",
 "date_range_between_relative_days": "相對天數之間",
 "date_range_gte": "之後",
 "date_range_is_empty": "為空值",
 "date_range_last_month": "上個月",
 "date_range_last_week": "上週",
 "date_range_last_year": "去年",
 "date_range_lte": "之前",
 "date_range_month_today": "本月到今天",
 "date_range_next_month": "下個月",
 "date_range_next_week": "下週",
 "date_range_not_empty": "不為空值",
 "date_range_relative_date_gte": "自",
 "date_range_relative_date_lte": "之前",
 "date_range_this_month": "本月",
 "date_range_this_week": "這週",
 "date_range_this_year": "今年",
 "date_range_today": "今天",
 "date_range_tomorrow": "明天",
 "date_range_week_number_range": "週數",
 "date_range_week_today": "本週到今天",
 "date_range_within_days": "幾天之內",
 "date_range_year_today": "今年到今天",
 "date_range_yesterday": "昨天",
 "string_eq": "Equal",
 "string_ne": "Not Equal",
 "string_in": "Contain",
 "string_not_in": "Not Contain",
 "string_like": "Like",
 "string_like_start_with": "Start With",
 "string_like_end_with": "End With",
 "string_is_empty": "Is Empty",
 "string_not_empty": "Not Empty",
 "days": "Days",
 "days_after": "Days After",
 "days_ago": "Days Ago",
 "default": "預設",
 "delay": "延誤",
 "delayed": "已延誤",
 "delete": "刪除",
 "delete_dispatch": "要刪除派車單(Dispatch)嗎?",
 "delete_dispatch_dispatchnumber": "刪除派車單 : \"%{dispatchNumber}\"",
 "delete_query_save_view_success": "刪除工作表 %{name} 成功!",
 "delete_view": "刪除工作表",
 "delivered": "已送達",
 "delivered_time": "送達時間",
 "delivery_container": "Delivery No/ 貨櫃號碼",
 "delivery_count": "Delivery數量",
 "delivery_date": "要求送達時間",
 "delivery_from": "要求送達時間(起)",
 "delivery_in_progress": "在途中",
 "delivery_method": "遞送方式",
 "delivery_method_customer_self_pickup": "客戶自提",
 "delivery_method_express": "快遞",
 "delivery_method_ftl": "整車",
 "delivery_method_ltl": "拼車",
 "delivery_method_ltl_ftl": "拼車/整車",
 "delivery_no": "Delivery No",
 "delivery_on_progress": "在途中",
 "delivery_status_update": "貨態更新",
 "delivery_time": "遞送時間",
 "delivery_to": "要求遞送時間(訖)",
 "delivery_to_customer": "遞送至客戶",
 "deny": "拒絕",
 "dep": "部門",
 "departed_to_terminal": "離開去航站",
 "department": "部門",
 "departure": "出發",
 "departured_atd": "實際離港(ATD)",
 "desc": "描述",
 "description": "描述",
 "dest": "進口站",
 "dest_country": "進口國家",
 "dest_custom_cleared": "進口站海關清關",
 "dest_custom_released": "進口站海關放行",
 "dest_hawb_hbl": "進口站",
 "dest_mawb_mbl": "進口站 (by MAWB / MBL)",
 "dest_port_city": "進口站城市",
 "dest_port_country": "進口站國家/地區",
 "dest_port_ctry": "進口站國家/地區",
 "dest_port_of_discharge": "進口站(卸貨港)",
 "dest_region": "進口站地區",
 "dest_status": "進口站狀態",
 "destination": "進口站",
 "destination_custom_released": "進口站海關放行",
 "destination_doc_status": "目的站檔案狀態",
 "destroy_success": "刪除完成",
 "detail": "詳情",
 "details": "詳情",
 "dimension": "尺寸",
 "dimension_unit": "尺寸單位",
 "dimension_uom": "尺寸單位",
 "disabled_input_select": "Select...",
 "disabled_input_text": "Type...",
 "dismiss_cookies_statements": "取消",
 "dispatch": "派車",
 "dispatch_actual_ofd_date": "外出遞送日期",
 "dispatch_carrier": "承運人",
 "dispatch_creation_date": "派車單(Dispatch)創建時間",
 "dispatch_customer_selfpu": "客戶自提",
 "dispatch_delivery_method": "貨件遞送方式",
 "dispatch_details": "派車單(Dispatch)詳情",
 "dispatch_edit": "編輯派車單(Dispatch)",
 "dispatch_form": "派車單(Dispatch)表單",
 "dispatch_id": "派車單ID",
 "dispatch_indicator": "派車指示器",
 "dispatch_mgmt": "派車管理",
 "dispatch_no_or_verification_code_is_wrong": "派車單(Dispatch)號或驗證碼錯誤",
 "dispatch_planned_ofd_date": "預計外出遞送日期",
 "dispatch_shipment_mode": "運輸方式",
 "dispatch_special_instruction": "特殊指示",
 "dispatch_status": "派車單(Dispatch)狀態",
 "dispatch_status_update": "派車單(Dispatch)狀態更新",
 "dispatch_summary": "派車單(Dispatch)總覽",
 "dispatch_truck_pickup_date": "實際抵達時間",
 "dispatch_truck_pu_date": "卡車提貨時間",
 "dispatch_type": "派車單(Dispatch)類型",
 "dn_sid": "DN/SID",
 "dn_sid_id": "DN/SID",
 "dn_timeline": "DN Timeline",
 "undefined": "",
 "do_you_want_to_delete_this_shipment": "您想要刪除此票貨件嗎?",
 "do_you_want_to_print_bol_manifest": "您要列印以下文件嗎？",
 "do_you_want_to_reprocess_it": "您想要再次執行嗎?",
 "do_you_want_to_resubmit_it": "您想要再次上傳嗎?",
 "do_you_want_to_send_email_to_user": "請問您是否要寄信給使用者？",
 "doc_avail_date": "文件可用",
 "doc_release": "放單時間",
 "doc_released": "放單",
 "doc_status": "文件狀態",
 "doc_to_broker": "文件至報關行",
 "document": "文件",
 "document_check": "文件檢核",
 "document_id": "文件 ID",
 "document_ready": "文件就緒",
 "document_section": "文件",
 "documents": "文件",
 "documents_attached": "已附的文件",
 "download": "下載",
 "download_auth_failed": "Download Auth Failed",
 "download_file": "Download File",
 "download_file_expires": "Download File Expires",
 "download_request_data_empty": "Download Data Empty",
 "download_template": "模板下載",
 "draft": "草稿",
 "driver": "司機",
 "driver_info_entered": "司機資料已錄入",
 "driver_name": "司機",
 "driver_phone_no": "司機電話",
 "drop_the_files_here": "拖拉檔案到此处",
 "each": "每個",
 "eccn": "ECCN",
 "edi_event": "EDI事件",
 "edi_log_id": "EDI紀錄ID",
 "edi_method": "EDI 方法",
 "edi_monitor": "EDI監控",
 "edi_monitor_edit": "EDI監控編輯",
 "edi_platform_id": "EDI平台ID",
 "edi_platform_sent_to_customer": "EDI Platform Sent To Customer",
 "edi_req_date": "EDI請求時間",
 "edi_req_date_utc": "EDI請求時間(UTC)",
 "edi_sent_date": "EDI發送時間",
 "edi_sent_date_timeutc0": "EDI發送時間(UTC+0)",
 "edi_sent_date_utc": "EDI發送時間(UTC)",
 "edi_sent_datetimeutc0": "EDI發送時間(UTC+0)",
 "edi_sent_utc_time": "EDI發送時間(UTC+0)",
 "edit": "編輯",
 "edit_subscription": "編輯訂閱",
 "editcancel": "編輯/取消",
 "effective_end": "Effective End",
 "effective_start": "Effective Start",
 "eic_code": "EIC號碼",
 "email": "Email",
 "email_id": "郵件編號",
 "email_sent": "郵件已發送",
 "email_status": "郵件狀態",
 "email_subject": "郵件主旨",
 "email_to": "Email收件人",
 "email_type": "郵件類型",
 "employeeid": "員工編號",
 "end_on": "結束",
 "english": "英文",
 "env_notice": "DEVELOP Environment",
 "env_notice_demo": "Demo Environment",
 "env_notice_develop": "DEVELOP Environment",
 "env_notice_epic": "EPIC Environment",
 "env_notice_feature": "FEATURE Environment",
 "env_notice_hint": "",
 "env_notice_hint_demo": "All The Data Is Not Real",
 "env_notice_local": "LOCAL Environment",
 "env_notice_prod": "PROD Environment",
 "env_notice_uat": "UAT Environment",
 "error": "錯誤",
 "error_code": "錯誤代碼",
 "error_code_400": "",
 "error_code_401": "登入逾時！請重新登入",
 "error_code_403": "請嘗試重試或者刷新頁面。若仍然無法訪問，請聯絡 mec_onehelp@morrisonexpress.com。",
 "error_code_404": "請嘗試重試或者刷新頁面。若仍然無法訪問，請聯絡 mec_onehelp@morrisonexpress.com。",
 "error_code_406": "請求內容類型不正確。",
 "error_code_409": "請求衝突，請重新整理後再試",
 "error_code_500": "伺服器錯誤，請複製錯誤追蹤碼並 email 到 mec_onehelp@morrisonexpress.com",
 "error_code_504": "連線逾時",
 "error_code_address_book_already_exist": "Address Book Already Exist",
 "error_code_asn_number_correspond_replicated_400": "asn_number已經與其他shipment關聯\n%{error_string} ",
 "error_code_booking_accept_bol_not_found": "無法以報價 ID: %{car_booking_id} 找到送貨單(BOL/ DN)。",
 "error_code_booking_accept_bol_status_not_allowed": "送貨單 %{bol_id} 已被取消，狀態為 %{bol_status}，無法送出請求。",
 "error_code_booking_accept_booking_status_not_allowed": "當前報價狀態是 %{booking_status}，無法接受報價。",
 "error_code_booking_accept_car_booking_not_found": "無法以報價 ID: %{car_booking_id} 找到報價。",
 "error_code_booking_accept_carrier_name_not_defined": "該承運人沒有 API。",
 "error_code_booking_accept_exception_bol_or_dispatch_not_found": "無法以報價 ID: %{car_booking_id} 找到所屬的%{quotation_level}。",
 "error_code_booking_accept_exception_booking_status_not_allowed": "無法以報價 ID: %{car_booking_id} 找到報價。",
 "error_code_booking_accept_exception_car_booking_not_found": "無法以報價 ID: %{car_booking_id} 找到報價。",
 "error_code_booking_accept_exception_exist_another_accepted_booking": "因當前 %{quotation_level} id: %{relation_id} 已存在一個報價，故無法接受報價。",
 "error_code_booking_accept_exist_another_accepted_booking": "因當前BOL ID: %{bol_id}已存在一個報價，故無法接受報價。",
 "error_code_booking_accept_no_submit_response_s3_key": "因沒有 quotation_res_s3_key，而無法找到報價 %{car_booking_id} 資訊。",
 "error_code_booking_accept_offline_exist_another_accept_booking": "已經存在一個線下的報價。",
 "error_code_booking_accept_partner_info_not_found": "無法找到承運人API %{partner_name} 的連接訊息。",
 "error_code_booking_accept_response": "回傳錯誤訊息： %{error_message}\nCarrier quotation log id: %{carrier_booking_log_id}, Car quotation id: %{car_booking_id}, BOL/ DN id: %{bol_id}",
 "error_code_booking_accept_ups_api_validate_error": "UPS 資料檢核錯誤： %{result}。報價 ID: %{car_booking_id}",
 "error_code_booking_cancel_bol_not_found": "無法以報價 ID %{car_booking_id} 找到送貨單。",
 "error_code_booking_cancel_bol_status_not_allowed": "送貨單 %{bol_id} 已被取消，狀態為 %{bol_status}，無法送出請求。",
 "error_code_booking_cancel_booking_status_not_allowed": "當前報價狀態是 %{booking_status}，無法取消報價。",
 "error_code_booking_cancel_car_booking_not_found": "無法以報價 ID: %{car_booking_id} 找到報價。",
 "error_code_booking_cancel_carrier_name_not_defined": "承運人\"%{carrier_code}\" \"%{carrier_name}\"不存在。",
 "error_code_booking_cancel_partner_info_not_found": "無法找到承運人API %{partner_name} 的連接訊息。",
 "error_code_booking_cancel_response": "回傳錯誤訊息： %{error_message}",
 "error_code_booking_cancel_ups_api_validate_error": "UPS 資料檢核錯誤： %{result}。報價 ID: %{car_booking_id}",
 "error_code_booking_mop_shipping_key_not_found": "MOP shipping key 不存在. 請確認承運人\"%{carrier_code} \", 發貨帳號\"%{shipper_account}\".",
 "error_code_booking_submit_bol_not_found": "無法找到送貨單(BOL/ DN) : %{bol_id}。",
 "error_code_booking_submit_bol_status_not_allowed": "送貨單(BOL/ DN) : %{bol_id} 已被取消，狀態為 %{bol_status}，無法送出請求。",
 "error_code_booking_submit_carrier_name_not_defined": "承運人\"%{carrier_code}\" “%{carrier_name}”不存在。",
 "error_code_booking_submit_partner_info_not_found": "無法找到承運人API %{partner_name} 的連接訊息。",
 "error_code_booking_submit_redis_carrier_mapping_error": "無法用承運人 %{carrier_code}  匹配到承運人服務。",
 "error_code_booking_submit_response": "回傳錯誤訊息： %{error_message}",
 "error_code_booking_submit_ups_api_validate_error": "UPS 資料檢核錯誤：%{result}。",
 "error_code_can_not_find_bol": "無法找到送貨單: %{bol_number}.",
 "error_code_can_not_find_item": "找不到 %{item_name}: %{item_key}: ",
 "error_code_can_not_find_shipment": "無法找到货物: %{shipment_number}.",
 "error_code_cant_save_with_processed_voucher": "There are processed vouchers, please void them first. %{error_detail}",
 "error_code_create_shipment_invalid_pr": "This is an invalid PR, which cannot be used to create shipment anymore",
 "error_code_delivery_status_update_error_PDF_file_broken": "PDF 檔案損壞，請更換檔案後重試。",
 "error_code_failed_to_create_invoice_request": "創建發票請求失敗",
 "error_code_failed_to_create_voucher_request": "創建 PO 請求失敗",
 "error_code_failed_to_delete_voucher_request": "刪除 PO 請求失敗",
 "error_code_failed_to_post_all_to_prolink": "Post All to Prolink Failed",
 "error_code_failed_to_retrieve_voucher_request": "檢索 PO 請求失敗",
 "error_code_failed_to_update_voucher_request": "更新 PO 請求失敗",
 "error_code_failed_to_void": "請勿重複 Void，已有 Void 請求正在處理中...",
 "error_code_failed_to_void_invoice": "作廢發票失敗",
 "error_code_failed_to_void_voucher": "作廢 PO 失敗",
 "error_code_forbidden": "You don't have the privilege id %{privilege_id}.",
 "error_code_label_data_not_ready": "貨件 %{shipment} 在出運前需要列印收貨人托盤標籤，目前 “收貨人托盤標籤資料” 為 “尚未就緒” 而無法列印。請聯絡客戶經理 %{account_manager}. 確認。",
 "error_code_permission_401": "您沒有瀏覽權限，可以選擇登出。",
 "error_code_policy_not_set": "Policy scope is not correct. reason: %{reason}",
 "error_code_print_pallet_label": "缺少收貨人標籤必要資料 %{missing_required_data}。如已跟客戶經理 %{account_manager} 確認，請按下”確認“繼續，否則請按”取消”。",
 "error_code_s3_client": "平台檔案操作錯誤",
 "error_code_tms_bol_grouping_different_bol_delivery_method": "您選擇了不同遞送方式的送貨單(BOL/ DN) ，請重新選擇相同遞送方式的送貨單(BOL/ DN)加入派車單(Dispatch)。",
 "error_code_unable_to_find_the_invoice_id": "Can't find the invoice ID",
 "error_code_unable_to_find_the_shipment": "找不到貨件",
 "error_code_unable_to_find_the_voucher_id": "Can't find the voucher ID",
 "error_code_unauthorized": "登入逾時！請重新登入",
 "error_code_xxx": "與伺服器連接失敗. 請嘗試重試或者刷新頁面。若仍然無法訪問，請聯絡 mec_onehelp@morrisonexpress.com。",
 "error_confirm_not_checked": "請勾選選項",
 "error_confirmed": "我已瞭解",
 "error_detail": "詳細錯誤資訊",
 "error_invalid_cron": "Invalid Schedule",
 "error_row": "Error Row",
 "error_tracking_id": "錯誤追蹤碼",
 "escort": "押運",
 "estimated_delivery": "計劃送貨時間",
 "estimated_delivery_date": "計劃送貨日期",
 "estimated_pickup_at": "預定揀貨",
 "eta": "預計到港(ETA)",
 "etd": "預計離港(ETD)",
 "etd_utc": "預計離港(UTC)",
 "event": "事件",
 "event_category": "事件類別",
 "event_cateogry": "事件類別",
 "event_date_local_time": "事件時間(當地時間)",
 "event_date_time_local": "Event Datetime (Local)",
 "event_datetime_local": "Event Datetime (Local)",
 "event_datetimelocal": "事件時間(當地時間)",
 "event_location": "事件發生地",
 "exception": "例外",
 "exception_count": "例外數量",
 "exception_history": "例外紀錄",
 "exception_id": "例外編號",
 "exception_invoice_number": "Exception Invoice Number",
 "exception_message": "例外訊息",
 "exemption_type": "豁免類型",
 "expensive": "高價",
 "export": "匯出",
 "export_cfs_to_terminal": "出口集散站至航站",
 "export_depart_to_terminal_time_utc": "出口至航站時間(UTC)",
 "export_errors": "出口錯誤!",
 "export_picking": "出口撿貨",
 "export_put_away": "出口入庫",
 "export_receive": "出口收貨",
 "export_receive_edit": "編輯出口收貨",
 "export_received_time_utc": "出口收件時間(UTC)",
 "export_status": "出口狀態",
 "express": "快遞",
 "ext": "Ext",
 "extension_type": "Extension Type",
 "fail": "失敗",
 "fast_dispatch_create": "快速建派車單",
 "feet": "英呎",
 "field_select": "選擇欄位",
 "file": "檔案路徑",
 "file_name": "檔案名稱",
 "file_over_quantity": "檔案數量超過上限",
 "files_submitted": "檔案已上傳",
 "filter": "篩選",
 "final_ata": "實際抵港(ATA)",
 "finish": "已完成",
 "first_atd": "首個 ATD",
 "first_bill": "First Bill",
 "first_etd": "首個 ETD",
 "fish_wildlife": "魚類與野生動物",
 "flight": "航班",
 "flight_no": "航班號",
 "forget_password": "忘記密碼",
 "form": "表單",
 "freight_charge_terms": "運費條款",
 "freight_charges_collect": "到付",
 "freight_charges_prepaid": "預付",
 "freight_charges_third_party": "第三方",
 "freight_counted_by": "貨物計數",
 "freight_counted_driver_pallets": "Driver/pallets said to contain",
 "freight_counted_driver_pieces": "Driver/Pieces",
 "freight_counted_shipper": "Shipper",
 "freight_term": "付款條款",
 "from": "起運",
 "ftl": "整車",
 "function": "功能",
 "general": "一般",
 "general_menu": "一般選單",
 "get_bolnumber_data_failed": "獲取\"%{bolNumber}\" 資料失敗!",
 "get_edi": "獲取EDI資料",
 "get_picking_failed": "撿貨失敗!",
 "get_presigned_url_failed": "獲取網址失敗！",
 "get_putaway_failed": "獲取入庫失敗",
 "get_putaway_location_error": "獲取入庫地點失敗",
 "get_summary_data_failed": "獲取摘要失敗",
 "goods_description": "貨物描述",
 "gross_weight": "毛重",
 "gross_weight_must_be_greater_than_tare_weight": "毛重必須大於皮重",
 "gross_wgt": "毛重",
 "group_id": "群組 ID",
 "had_the_different_auto_print": "貨件存在不同的自動列印註記",
 "had_the_different_carrier": "貨件存在不同的預指派承運人",
 "had_the_different_consignee_address": "貨件存在不同的收貨地址",
 "had_the_different_consignee_id": "貨件存在不同的收貨人ID",
 "had_the_different_delivery_method": "貨件存在不同的遞送方式",
 "had_the_different_document_ready": "貨件存在不同的文件狀態",
 "had_the_different_payer": "貨件存在不同付款方",
 "had_the_different_payer_account": "貨件存在不同付款帳號",
 "had_the_different_payer_name": "貨件存在不同付款人",
 "had_the_different_quotation_level": "貨件存在不同的計價方式",
 "handed_to_air_carrier": "移交至空運方",
 "handed_to_mec": "移交至鴻霖",
 "handling_agent": "操作代理",
 "handover": "交接貨物",
 "handover_to_driver": "交接貨物至司機",
 "has_duplicate_query_save_view_name": "警告! 此工作表名稱已被使用",
 "hawb": "HAWB#",
 "hawb_cartons": "HAWB 箱數",
 "hawb_count": "Shipment數",
 "hawb_dest": "HAWB 目的港",
 "hawb_doc": "HAWB 文件",
 "hawb_edit": "編輯 HAWB",
 "hawb_first_atd_local_time": "HAWB 首個實際離港(當地時間)",
 "hawb_hbl": "HAWB / HBL",
 "hawb_id": "HAWB ID",
 "hawb_label_print": "HAWB 印制標籤",
 "hawb_no": "HAWB / HBL",
 "hawb_orig": "HAWB 始發地",
 "hawb_pallets": "HAWB 棧板數",
 "hawb_pcs": "HAWB 件數",
 "hawb_pkg_detail": "HAWB 包裹詳情",
 "hawb_total_cartons": "HAWB 總箱數",
 "hawb_upload": "上傳 HAWB",
 "hawbbl": "HAWB / HBL",
 "hawbhbl": "HAWB / HBL",
 "height": "高度",
 "hello_name": "您好, %{name}!",
 "hide": "隱藏",
 "high": "高",
 "history": "歷史資訊…",
 "hkg_milestone": "HKG Milestone",
 "hts": "HTS",
 "i_accept": "我接受",
 "id": "編號",
 "import": "進口",
 "in_process": "處理中",
 "in_progress": "進行中",
 "inbound_file_path": "檔案路徑",
 "incomplete": "未完成",
 "incoterm": "Incoterm",
 "index_properties": "Index Properties",
 "indicator": "Indicator",
 "inland_trucking": "內陸卡車",
 "inner_cartons": "內箱",
 "input_more_than_the_next_n_days_is_not_allowed": "不允許輸入超過未來 %{n} 天",
 "instruction": "指示",
 "internal_id": "內部 ID",
 "inv_amount": "發票金額",
 "inv_canceled": "發票已被取消",
 "inv_currency": "發票幣別",
 "inv_id": "發票編號",
 "inv_no": "發票號碼",
 "inv_rejected": "發票已被拒絕",
 "inv_submitted": "發票已上傳",
 "invalid_date": "無效的日期",
 "invalid_date_time_please_enter_yyyy_mm_dd_hh24_mm": "Invalid Date time, please enter yyyy/MM/dd HH:mm",
 "invalid_datetime": "無效的日期時間",
 "invalid_email": "無效的Email",
 "invalid_future_time": "不可輸入未來時間",
 "invalid_input": "無效的輸入",
 "invalid_password": "無效的密碼",
 "invalid_phone_number": "無效的電話號碼",
 "invoice": "發票",
 "invoice_amount": "發票金額",
 "invoice_date": "發票日期",
 "invoice_edit": "編輯發票",
 "invoice_file": "發票檔案",
 "invoice_no": "發票號碼",
 "invoice_no_number": "發票號碼: %{number}",
 "invoice_number": "發票號碼",
 "invoice_query": "發票搜尋",
 "invoice_status": "票票狀態:",
 "invoice_upload": "發票上傳",
 "isa_no": "ISA編號",
 "issue_date": "開據日期",
 "itn": "ITN#",
 "key": "鍵值",
 "kg": "公斤",
 "last_4_digits_of_your_phone_number": "手機後四碼",
 "last_eta": "預計到港(ETA)",
 "lb": "磅",
 "lefted_atd": "No use",
 "length": "長度",
 "level": "Level",
 "license_code": "證照代碼",
 "lifted_atd": "No use",
 "lifted_departed_atd": "啟航(ATD)",
 "line": "Line",
 "link": "關聯",
 "link_reference_no_confirm": "關聯參考號碼並確認",
 "linked_po_no_ref_no": "關聯PO號碼/參考號碼",
 "list": "清單",
 "load_dispatch": "添加派車單(Dispatch)",
 "loading": "處理中",
 "loading_meter": "裝載量",
 "loading_type": "裝載類型",
 "logistic_handling": "物流操作",
 "logout": "登出",
 "loose_cartons": "散箱",
 "loose_ctn_qty": "散箱",
 "ltl": "LTL / FTL",
 "m": "尺",
 "mawb": "MAWB",
 "mawb_awb": "MAWB AWB",
 "mawb_dest": "MAWB 目的港",
 "mawb_doc": "MAWB 文件",
 "mawb_mbl": "MAWB / MBL",
 "mawb_no": "MAWB / MBL",
 "mawb_orig": "MAWB 始發港",
 "mawb_pcs": "MAWB 件數",
 "mawb_upload": "上傳MAWB",
 "max_length_colon": "Max Length: %{max}",
 "max_length_must_be_variable_characters_or_less": "超出長度: 最多只能 %{max} 個字元",
 "mec_booking_no": "MEC 預訂號碼",
 "medium": "中等",
 "menu": "選單",
 "message": "訊息",
 "message_id": "訊息編號",
 "milestone": "里程碑",
 "milestone_options_air_delivery_actual_pod": "實際送達",
 "milestone_options_air_delivery_estimated_pod": "預計送達",
 "milestone_options_air_delivery_estimated_pod_sla": "SLA",
 "milestone_options_air_delivery_ofd": "外出遞送",
 "milestone_options_air_shipment_actual_pickup": "實際提貨",
 "milestone_options_air_shipment_arrive_cfs": "抵達CFS",
 "milestone_options_air_shipment_ata": "實際抵達",
 "milestone_options_air_shipment_atd": "實際出發",
 "milestone_options_air_shipment_booking_date": "預定日期",
 "milestone_options_air_shipment_customs_clear": "始發地清關",
 "milestone_options_air_shipment_dest_customs_start": "目的地報關開始",
 "milestone_options_air_shipment_doc_release": "放單時間",
 "milestone_options_air_shipment_estimated_pickup": "預計提貨",
 "milestone_options_air_shipment_eta": "預計抵達",
 "milestone_options_air_shipment_etd": "預計出發",
 "milestone_options_air_shipment_packing": "包裝",
 "milestone_options_cargo_returned": "貨物退回",
 "milestone_options_destination_customs_start": "Import Station Customs Started",
 "milestone_options_import_customs_release": "Import Station Customs Released",
 "milestone_status": "貨態時間查詢",
 "milestone_status_arrived": "抵達",
 "milestone_status_arrived_cfs": "抵達目的地CFS",
 "milestone_status_booked": "預定",
 "milestone_status_cancelled": "取消",
 "milestone_status_customs_cleared": "出口清關",
 "milestone_status_customs_released": "進口海關放行",
 "milestone_status_delivered": "送達",
 "milestone_status_departured": "出發",
 "milestone_status_document_released": "文件釋出",
 "milestone_status_estimated_arrival": "預計抵達",
 "milestone_status_estimated_departure": "預計出發",
 "milestone_status_out_for_delivery": "外出遞送",
 "milestone_status_packing": "裝貨",
 "milestone_status_picked_up": "提貨",
 "milestones": "里程碑",
 "milestones_with_blue_font_will_be_sent_via_edi_214_to_customer": "藍色字體之事件會發送EDI214至客戶端",
 "misc_docs": "其他檔案",
 "mobile_number": "退回貨物",
 "mode": "運輸方式",
 "mop_application_form": "MOP 申請表單",
 "mop_status_code": "MOP 狀態代碼",
 "more": "更多…",
 "morrison": "Morrison",
 "morrison_asn": "Morrison ASN#",
 "morrison_customer_id": "Morrison Customer ID",
 "morrison_rcv": "Morrison RCV#",
 "multiple_key": "Multiple Key",
 "must_a_number": "必須是一個數字",
 "must_be_only_y_n": "必須輸入 Y / N",
 "must_in_options": "輸入內容不符合, 僅接受 %{options}",
 "must_integer": "必須是一個整數",
 "my_colleague": "我的同事",
 "my_station": "我的站點",
 "my_subscription": "我的訂閱",
 "my_team": "我的團隊",
 "only_allow_decimal": "只允許輸入小數%{num}位",
 "n": "N",
 "name": "名稱",
 "nav_more": "更多",
 "necessary_to_input_only_if_scenario_is_urgent_use": "只有在情境是\"未收到EDI\"，才是必填的",
 "need_resubmission": "需要重新上傳",
 "net_wgt": "淨重",
 "new": "新增",
 "new_content": "新內容",
 "new_dispatch": "新增派車單(Dispatch)",
 "new_exception": "新增例外",
 "new_hawb": "新增 HAWB",
 "new_shipment": "新增貨件",
 "new_update": "更新",
 "next": "下一步",
 "no": "否",
 "no_data": "沒有資料",
 "no_dispatch": "沒有派車單(Dispatch)!",
 "no_dispath_bols": "沒有派車單(Dispatch)!",
 "no_dnsid_selected": "沒有選取DN/SID!",
 "no_document": "沒有文件!",
 "no_documents": "沒有文件!",
 "no_downloadurl": "沒有下載網址",
 "no_future_datetime_is_allowed": "不允許輸入未來時間",
 "no_invoice": "沒有發票!",
 "no_invoices": "沒有發票!",
 "no_milestones": "沒有 Milestone！",
 "no_pre_signed_list": "沒有預先簽署的名單",
 "no_records": "沒有資料！",
 "no_references": "沒有參考值！",
 "no_reports": "沒有報表！",
 "no_roles": "沒有角色！",
 "no_shipment": "沒有貨件!",
 "no_shipment_tracking_data": "沒有貨物追蹤資料！",
 "no_shipments": "沒有貨件！",
 "no_users": "沒有使用者!",
 "noDocument": "No Document!",
 "noDownloadUrl": "No Download Url!",
 "none": "None",
 "nontrade": "Non-Trade",
 "normal": "一般",
 "not_assigned": "未指派",
 "not_ready": "尚未就緒",
 "not_received": "尚未收貨",
 "not_special_characters": "不可包含特殊字元",
 "note": "註記",
 "notice_status": "Notice Status",
 "notification": "提醒",
 "notify_party": "通知方",
 "npi": "NPI",
 "num": "Num",
 "number": "Number",
 "number_of_line_items": "項目數量",
 "numbering_assigned_to_carrier": "10-已指派承運人",
 "numbering_assigned_to_driver": "15-已指派司機",
 "numbering_canceled": "90-已取消",
 "numbering_cancelled": "90-已取消",
 "numbering_delivered": "30-已送達",
 "numbering_delivery_in_progress": "25-在途中",
 "numbering_not_assigned": "00-未派車",
 "numbering_out_for_delivery": "20-外出遞送",
 "numbering_planning": "05-計劃中",
 "numbering_shipment_canceled": "99-已取消",
 "ocean": "海運",
 "ocr": "OCR",
 "ofd": "外出遞送日期",
 "ofd_time": "外出遞送日期",
 "ofddeparted_from_cfs": "從集散站出發遞送",
 "off_for_delivery_date": "交付派送時間",
 "off_load": "Offload",
 "ok": "OK",
 "onboard": "登船",
 "onground": "On-Ground",
 "open": "打開",
 "operator": "作業員",
 "option_contactrole_agent": "Agent",
 "option_contactrole_airliner_carrier": "",
 "option_contactrole_branch": "Branch",
 "option_contactrole_broker": "",
 "option_contactrole_co_loader": "",
 "option_contactrole_general_cargo": "",
 "option_contactrole_ocean_liner": "",
 "option_contactrole_other_vendor": "",
 "option_contactrole_trucker": "",
 "option_contactrole_warehouse": "",
 "option_milestone_arrive_at_cfs": "",
 "option_milestone_arrived_at_cfs": "抵達目的CFS",
 "option_milestone_custom_cleared": "出口清關",
 "option_milestone_customs_cleared_at": "海關清關",
 "option_milestone_customs_released_at": "海關放行",
 "option_milestone_dest_custom_released": "進口海關放行",
 "option_milestone_ofd": "外出遞送",
 "option_milestone_packing": "包裝日期",
 "option_milestone_pickup_at": "實際提貨",
 "option_milestone_pod": "實際送達",
 "option_milstone_actual_delivery_date": "實際送貨",
 "option_milstone_actual_pickup_at": "提貨",
 "option_milstone_arrived_at_cfs": "抵達目的地CFS",
 "option_milstone_arrived_cfs": "抵達目的地CFS",
 "option_milstone_arrived_cfs_at": "抵達目的地CFS",
 "option_milstone_ata": "最後實際到達",
 "option_milstone_atd": "首次實際出發",
 "option_milstone_billing_issued_at": "Invoice / Voucher Date",
 "option_milstone_booking_date": "預定",
 "option_milstone_created_at_utc": "生成日期",
 "option_milstone_customs_cleared": "清關",
 "option_milstone_customs_cleared_at": "始發地清關",
 "option_milstone_customs_released": "海關放行",
 "option_milstone_customs_released_at": "目的地關務放行",
 "option_milstone_doc_released": "文件釋出",
 "option_milstone_doc_released_at": "文件釋出",
 "option_milstone_estimated_delivery_date": "預計送貨",
 "option_milstone_estimated_delivery_date_sla": "SLA 到期日",
 "option_milstone_estimated_pickup_at": "預定揀貨",
 "option_milstone_eta": "最後預計到達",
 "option_milstone_etd": "首次預計出發",
 "option_milstone_first_etd": "首次預計出發",
 "option_milstone_out_for_delivery_date": "外出遞送",
 "option_milstone_print_at": "Print Date",
 "option_milstone_void_at": "Void Date",
 "option_milstone_actual_pickup": "Actual Pickup Date",
 "option_milstone_estimated_pickup": "Estimated Pickup Date",
 "order": "訂單",
 "order_class": "Order Class",
 "order_id": "訂單ID",
 "orig": "出口站",
 "orig_country": "Orig Country",
 "orig_hawb_hbl": "出口站",
 "orig_mawb_mbl": "出口站 (by MAWB / MBL)",
 "orig_port_city": "出口站城市",
 "orig_port_country": "出口站國家/地區",
 "orig_port_ctry": "出口站國家/地區",
 "orig_port_of_loading": "出口站(裝貨港)",
 "orig_status": "出口站狀態",
 "origin": "出口站",
 "origin_custom_cleared": "出口站清關",
 "osd_status": "OSD狀態",
 "other_docs": "其他文件",
 "other_instruction": "其他指令",
 "others": "其他",
 "out_for_delivery": "外出遞送",
 "out_of_allow_date_range": "超出允許時間日期",
 "out_of_delivery": "實際外出遞送日期",
 "out_of_gauge": "超標(貨物)",
 "output": "輸出",
 "pack_unit": "包裝單位",
 "package_details": "包裹細節",
 "package_id": "包裹ID",
 "package_qty": "包裹數",
 "package_quantity": "包裹數",
 "package_type": "包裝類型",
 "package_unit": "包裝類型",
 "packing_list": "箱單",
 "packs": "包裝",
 "pallet": "棧板",
 "pallet_id": "棧板ID",
 "pallet_wgt": "棧板重量",
 "parser": "Parser",
 "part": "料件",
 "part_desc": "料件描述",
 "part_no": "料件號碼",
 "partial_received": "部分收貨",
 "partilly_receive": "部分收貨",
 "password": "密碼",
 "password_must_match": "密碼必須相同",
 "payer": "付款方",
 "payer_name": "付款方名字",
 "payment_completed": "付款已完成",
 "payment_to_carrier_by": "付款方(給承運人)",
 "pcs": "件數",
 "pcs_in_location": "在庫數量",
 "pcs_is_not_a_positive_number": "件數非正數",
 "pcs_is_over_received": "件數超過收貨數量",
 "pcs_qty": "件數",
 "period_end": "結束",
 "period_start": "開始",
 "permission_denied_warning": "与伺服器连接失败. 请尝试重试或者刷新页面。若仍然无法访问，请联络 mec_onehelp@morrisonexpress.com。",
 "photo_list": "照片列表",
 "photo_management_milestone_export_handover": "Export - Handover",
 "photo_management_milestone_export_receiving": "Export - Receiving",
 "photo_management_milestone_import_after_sorting": "Import - After Sorting",
 "photo_management_milestone_import_reweigh": "Import - Reweigh",
 "photo_management_milestone_import_delivery": "Import - Delivery",
 "photo_management_milestone_import_pickup": "Import - Pickup",
 "photo_management_milestone_import_receiving": "Import - Receiving",
 "photo_management_milestone_export_received": "Export - Received",
 "photo_management_milestone_import_received": "Import - Received",
 "inventory_transfer_pickup": "Inventory - Pickup",
 "inventory_transfer_receiving": "Inventory - Receiving",
 "photos": "照片",
 "picked": "已揀貨",
 "picked_pcs": "已揀貨件數",
 "picked_up": "提貨",
 "picking_pcs": "揀貨件數",
 "picking_status": "揀貨狀態",
 "pickup_complete": "取貨完成",
 "pickup_date": "提貨日期",
 "pickup_from": "提貨日期(起)",
 "pickup_from_shipper": "從發貨人處提貨",
 "pickup_to": "取貨日期(訖)",
 "planned_delivery_date": "預計遞送時間",
 "planned_delivery_dt_utc": "預計送貨時間 (UTC)",
 "planned_delivery_time": "預計送貨時間",
 "planned_driver_arrival_date": "預計司機抵達倉庫日期",
 "planned_driver_arrival_time": "預計司機抵達倉庫",
 "planned_driver_arrival_time_and_ofd_time": "預計司機抵達倉庫和外出遞送時間",
 "planned_ofd_time": "預計外出遞送時間",
 "planned_out_for_delivery_date": "預計外出遞送日期",
 "planned_out_for_delivery_time": "預計外出遞送時間",
 "planned_pickup_time": "預計司機抵達倉庫",
 "planning": "計劃中",
 "please_click_link_below_to_download_report_if_automatic_download_do_not_initialize": "如沒有自動下載，請點連接下載報告。",
 "please_enter_a_valid_datetime": "請輸入一個有效的時間。",
 "please_enter_a_valid_date_and_time": "請輸入正確格式。",
 "please_enter_a_valid_number": "請輸入一個有效的數字。",
 "please_enter_invoice_no": "請輸入發票號碼。",
 "please_select_at_least_one_record": "請至少選一筆資料。",
 "please_select_delivery_nubmer_for_document_upload": "請選擇送貨號碼來上傳文件。",
 "please_select_station": "請選擇站點",
 "please_type_in_your_printed_invoice_value": "Please type in your printed invoice value",
 "please_upload_the_supporting_doc_eg_proof_of_delivery": "請上傳證明文件(如POD簽收單)",
 "please_upload_your_invoice": "請上傳發票。",
 "please_use_google_chrome_to_access": "請使用 Google Chrome 訪問 Morrison One",
 "plt_qty": "棧板數",
 "plts": "棧板數",
 "po": "PO#",
 "po_created_date": "PO 建立日期",
 "po_issue_date": "PO 發行日期",
 "po_request_status": "PO Request 狀態",
 "pod": "POD",
 "pod_date": "簽收日期",
 "pod_file": "簽收單(POD File)",
 "pod_location": "簽收地點",
 "pod_name": "簽收人",
 "pod_reference_file": "簽收參考文件",
 "pod_signed_by": "簽收人",
 "pod_time": "簽收時間",
 "poddelivery_date": "簽收日期",
 "policy_attribute": "策略屬性",
 "policy_form": "權限表單",
 "policy_name": "策略名稱",
 "policy_setting": "策略設定",
 "do_you_want_to_delete_policy?": "您確定要刪除權限嗎?",
 "port_of_delivery": "交貨港",
 "port_of_delivery_city": "交貨港城市",
 "port_of_delivery_country": "交貨港國家/地區",
 "port_of_receipt": "收貨港",
 "port_of_receipt_city": "收貨港城市",
 "port_of_receipt_country": "收貨港國家/地區",
 "potential_delay": "遞送可能延誤",
 "preassigned_carrier": "承運人",
 "preassigned_carrier_different_bol_carrier": "預指派承運人和實際承運人不同",
 "prepaid": "預付",
 "preview": "預覽",
 "preview_file": "預覽檔案",
 "previous": "上一頁",
 "primary_driver_name": "司機",
 "print": "列印",
 "print_bol": "列印送貨單(BOL)",
 "print_manifest": "列印派車單(Dispatch)",
 "print_status": "列印狀態",
 "privacy_policy": "隱私政策",
 "private": "Private",
 "privilege": "權限",
 "process": "處理",
 "process_message": "處理訊息",
 "product_type": "產品類型",
 "progress": "運送進度",
 "project_code": "專案代碼",
 "proof_of_delivery": "簽收單(POD)",
 "protocol": "協定",
 "public": "公共",
 "put_away": "入庫",
 "put_away_location": "入庫庫位",
 "put_away_pieces_is_less_than_hawb_pieces_continue_to_save": "入庫數量少於提單所列件數，請確認是否繼續並儲存?",
 "putaway_location": "入庫庫位",
 "qty": "數量*",
 "query": "查詢",
 "query_documents_success": "查詢檔案成功！",
 "query_fail_no_bill_of_lading_data": "失敗! 沒有送貨單(BOL)資料",
 "query_failed": "失敗!",
 "query_failed_due_to_error": "失敗! %{error}",
 "query_invoices_success": "Query invoices success",
 "query_report_download_success": "Query Report Download Success.",
 "query_save_view_info": "您可以使用此功能儲存搜尋的條件和欄位以供下次使用",
 "query_save_view_title": "建立新工作表",
 "query_user_success": "Query user success",
 "quit": "放棄",
 "quotation_level": "報價層級",
 "quotation_number": "報價號碼",
 "quotation_price": "報價",
 "ready": "就緒",
 "reason": "原因",
 "receipt_no": "收據號碼",
 "received": "已收貨",
 "received_serial_numbers": "已收序號",
 "receiver": "接收者",
 "receiving": "Receiving",
 "receiving_no": "Receiving No.",
 "recipient_email": "接收者信箱",
 "recipient_email_content": "訊息(選填)",
 "recipient_email_list": "接收者信箱清單",
 "records_not_found": "沒有資料或權限不足的%{type}",
 "ref": "參考號碼",
 "ref_no": "參考號碼",
 "reference": "參考值",
 "reference_type": "參考值類別",
 "reference_value": "參考值",
 "reference_files": "參考文件",
 "reference2": "Reference#2",
 "refresh_every_15_seconds": "每15秒重新整理",
 "refresh_every_5_minutes": "每5分鐘重新整理",
 "register": "註冊",
 "register_email": "註冊人電子信箱",
 "reject": "拒絕",
 "rejected": "已拒絕",
 "related": "相關的",
 "remark": "備註",
 "remove_all_error": "移除所有錯誤",
 "report": "報表",
 "report_download": "報表下載",
 "report_name": "報表名稱",
 "reprocess": "重跑流程",
 "requester": "申請人",
 "required": "必要的資訊",
 "resend_sms": "重發簡訊",
 "reset": "重設",
 "resubmit": "重新提交",
 "result": "結果",
 "revise": "修改",
 "risk": "風險",
 "role": "角色",
 "role_id": "角色ID",
 "role_name": "角色名稱",
 "role_type": "角色類別",
 "roll": "Roll",
 "row_no": "行數",
 "s3_key": "S3 Key",
 "sales_mgmt": "業務管理",
 "save": "儲存",
 "save_view_is_this_view_default": "預設報表模板",
 "save_view_is_this_view_default_when_no_default": "無預設報表模板時，則設定此為預設",
 "scac": "SCAC",
 "scan_qr_code": "掃描QR Code",
 "sea": "海運",
 "seal_no": "封條號碼",
 "search": "搜尋",
 "search_fail": "查找失敗",
 "search_key": "搜尋關鍵字",
 "second_bill": "第二張帳單",
 "security_level": "安全級別",
 "select_at_least_one_bol": "至少選擇一個送貨單(BOL)",
 "select_or_type_in": "選擇或輸入",
 "selected_bols": "已選中的送貨單(BOL)",
 "selected_shipments": "已選中的Shipment",
 "selling_rate": "賣價",
 "semi": "Semi",
 "send_email": "發送Email",
 "sender": "發送方",
 "sent_to_edi_platform": "發送至EDI平台",
 "seq": "序號",
 "sequence": "順序",
 "service_level": "服務等級",
 "service_type": "服務類型",
 "set_default_query_view_success": "設置預設報表模板成功!",
 "sharp_bols": "送貨單(BOL)總數",
 "sharp_deliveries": "Delivery數",
 "sharp_gross_weight": "總重",
 "sharp_gw": "總重",
 "sharp_packages": "包裹數",
 "sharp_pkgs": "包裹數",
 "sharp_shipments": "Shipment數",
 "sharp_shpts": "Shipment數",
 "ship_dateutc0": "起運日期",
 "ship_from": "起運",
 "ship_from_address1": "起運地址一",
 "ship_from_address2": "起運地址二",
 "ship_from_city": "起運城市",
 "ship_from_contact2": "起運聯絡人",
 "ship_from_ctry": "起運國家/地區",
 "ship_from_name": "起運方",
 "ship_from_state": "起運州/省",
 "ship_from_zip": "起運地郵遞區號",
 "ship_mode": "運輸方式",
 "ship_to": "收貨",
 "ship_to_address": "收貨地址",
 "ship_to_address_localized": "收貨地址(中文)",
 "ship_to_address_or_city": "收貨地址/城市",
 "ship_to_city": "收貨城市",
 "ship_to_city_localized": "收貨城市(中文)",
 "ship_to_country": "收貨國家/地區",
 "ship_to_name": "收貨方",
 "ship_to_state": "收貨州/省",
 "shipment": "貨件",
 "shipment_check": "指派承運人",
 "shipment_complete": "貨件已完成?",
 "shipment_creation_date": "建立時間",
 "shipment_customer": "客戶",
 "shipment_details": "貨件詳情",
 "shipment_dispatch_status": "派車狀態",
 "shipment_docs_ready": "文件就緒",
 "shipment_has_bol_please_go_to_tms_mgmt": "該貨件存在於送貨單 BOL %{bolNumbers}。請至 %{url} 更新 OFD 和 POD。",
 "shipment_leg": "送貨類型",
 "shipment_mgmt": "貨件管理",
 "shipment_mode": "運輸方式",
 "shipment_mode_all": "全部",
 "shipment_mode_air": "空運",
 "shipment_mode_comprehensive": "綜合",
 "shipment_mode_logistics": "物流",
 "shipment_mode_rail": "鐵路",
 "shipment_mode_sea": "海運",
 "shipment_mode_truck": "卡車",
 "shipment_mode_warehouse_truck": "倉儲",
 "shipment_no": "HAWB / HBL",
 "shipment_number_is_not_unique": "Shipment號碼重複",
 "shipment_number_not_unique": "Shipment號碼重複",
 "shipment_progress": "貨件狀態",
 "shipment_progress_cancel": "已取消",
 "shipment_reference_list_option_apple_dri": "APPLE DRI",
 "shipment_reference_list_option_apple_fdno": "APPLE FD",
 "shipment_reference_list_option_apple_fd": "APPLE FD",
 "shipment_reference_list_option_apple_prno": "APPLE PR",
 "shipment_reference_list_option_box_number": "BOX",
 "shipment_reference_list_option_box": "BOX",
 "shipment_reference_list_option_cost_cnt": "COST CENTER",
 "shipment_reference_list_option_cost_center": "COST CENTER",
 "shipment_reference_list_option_cra": "CRA",
 "shipment_reference_list_option_dc": "DC",
 "shipment_reference_list_option_di": "DI",
 "shipment_reference_list_option_gl_accunt": "GL ACCOUNT",
 "shipment_reference_list_option_gl_account": "GL ACCOUNT",
 "shipment_reference_list_option_in": "IN",
 "shipment_reference_list_option_it": "IT",
 "shipment_reference_list_option_lu": "LU",
 "shipment_reference_list_option_or": "OR",
 "shipment_reference_list_option_ot": "OTHER",
 "shipment_reference_list_option_other": "OTHER",
 "shipment_reference_list_option_pj": "PJ",
 "shipment_reference_list_option_pl": "PL",
 "shipment_reference_list_option_pn": "PN",
 "shipment_reference_list_option_po": "PO",
 "shipment_reference_list_option_rma": "RMA",
 "shipment_reference_list_option_sd": "SD",
 "shipment_reference_list_option_si": "SI",
 "shipment_reference_list_option_sn": "SN",
 "shipment_reference_list_option_s_n": "SN",
 "shipment_reference_list_option_so": "SO",
 "shipment_reference_list_option_s_o": "SO",
 "shipment_reference_list_option_sr": "SR",
 "shipment_reference_list_option_st": "ST",
 "shipment_reference_list_option_umc": "UMC",
 "shipment_reference_list_option_undefined": "未定義",
 "shipment_reference_list_option_whse": "Warehouse",
 "shipment_reference_list_option_cargo_loc": "Cargo Location",
 "shipment_reference_list_option_carrier": "Carrier",
 "shipment_reference_list_option_mawb": "MAWB / MBL",
 "shipment_reference_list_option_hawb": "HAWB / HBL",
 "shipment_reference_list_option_asn": "ASN",
 "shipment_reference_list_option_bol_ref": "BOL ref",
 "shipment_reference_list_option_vsl_name": "Vessel name",
 "shipment_reference_list_option_cntr_no": "Container No",
 "shipment_reference_list_option_cntr_size": "Container Size",
 "shipment_reference_list_tooltip_apple_dri": "APPLE DRI",
 "shipment_reference_list_tooltip_apple_fdno": "Apple FD No.",
 "shipment_reference_list_tooltip_apple_prno": "Apple PR No.",
 "shipment_reference_list_tooltip_box_number": "箱號",
 "shipment_reference_list_tooltip_cost_cnt": "Cost Center No.",
 "shipment_reference_list_tooltip_cra": "罐號",
 "shipment_reference_list_tooltip_dc": "進口及出口申報編號",
 "shipment_reference_list_tooltip_di": "Delivery ID",
 "shipment_reference_list_tooltip_from_prolink": "來自Prolink/CW1",
 "shipment_reference_list_tooltip_gl_accunt": "GL Account No.",
 "shipment_reference_list_tooltip_in": "商業發票號碼",
 "shipment_reference_list_tooltip_it": "項目編號",
 "shipment_reference_list_tooltip_lu": "LU編號",
 "shipment_reference_list_tooltip_or": "訂單編號",
 "shipment_reference_list_tooltip_ot": "其他",
 "shipment_reference_list_tooltip_pj": "專案名稱",
 "shipment_reference_list_tooltip_pl": "箱單號碼",
 "shipment_reference_list_tooltip_pn": "料件編號",
 "shipment_reference_list_tooltip_po": "採購訂單",
 "shipment_reference_list_tooltip_rma": "退料授權號碼",
 "shipment_reference_list_tooltip_sd": "SDAF號碼",
 "shipment_reference_list_tooltip_si": "SID編號",
 "shipment_reference_list_tooltip_sn": "序號",
 "shipment_reference_list_tooltip_so": "S/O No.",
 "shipment_reference_list_tooltip_sr": "Shipment Reference No. / Shipment Request No.",
 "shipment_reference_list_tooltip_st": "樣式編號",
 "shipment_reference_list_tooltip_umc": "UMC參考清單",
 "shipment_truck_status": "卡車貨態",
 "shipment_type": "貨件類型",
 "shipments": "貨件",
 "shipments_added": "已加入的貨件",
 "shipments_already_had_preassigned_carrier_make_sure_to_change": "%{shipments} 已指派承運人，需要重新指派嗎？",
 "shipper": "發貨方",
 "shipper_code": "發貨方代碼",
 "shipper_name": "發貨方",
 "shipping_address": "送貨地址",
 "shipping_key": "運輸密鑰",
 "shipping_qty": "出貨數量",
 "shp_mode": "運輸方式",
 "shpr": "發貨方",
 "sign_by": "簽收人",
 "sign_by_name": "簽收人名",
 "signed_by": "簽收人",
 "sku": "SKU",
 "sla": "SLA",
 "smaller_than_start_number": "小於起始值",
 "smaller_than_start_on": "小於起始值",
 "smaller_then_starton": "小於開始條件",
 "sn": "SN",
 "something_went_wrong": "發生錯誤，請重試或聯絡mec_onehelp@morrisonexpress.com",
 "something_wrong": "發生錯誤，請重試或聯絡mec_onehelp@morrisonexpress.com",
 "something_wrong_error": "發生錯誤! %{error}",
 "special_delivery_instruction": "特殊指示",
 "special_instruction": "特殊指示",
 "split": "拆分",
 "split_hawb": "拆分提單",
 "split_package_quantity_is_invalid": "分拆的包裹數量無效",
 "split_package_unit_is_invalid": "分拆的包裹類型無效",
 "sprinter": "Sprinter",
 "standard": "標準",
 "standard_carrier_alpha_code": "SCAC",
 "start_on": "開始於",
 "state": "州/省",
 "station": "站點",
 "station_approval": "站上批准",
 "status": "狀態",
 "step": "步驟",
 "step_step": "步驟%{step}",
 "step1_edit_data": "Step 1: Edit Data",
 "step2_check_result": "Step 2: Check Result",
 "submit": "送出",
 "submitted_to_customs": "提交至海關",
 "subscription": "訂閱",
 "subscription_name": "訂閱名稱",
 "success": "成功",
 "summary": "摘要",
 "support_doc": "證明文件",
 "svc_lv": "服務等級",
 "svc_lvl": "服務等級",
 "svc_type": "服務類型",
 "system": "系統",
 "target_delivery": "要求送達",
 "target_delivery_date": "要求送達日期",
 "target_delivery_date_utc": "要求送達時間 (UTC)",
 "target_delivery_from": "要求送達時間(起)",
 "target_delivery_to": "要求送達時間(訖)",
 "tea": "TEA #",
 "tel": "電話",
 "tel_no": "電話號碼",
 "terminal_to_import_cfs": "航站至進口倉",
 "terms_and_conditions": "條款與條件",
 "thank_you": "謝謝!",
 "the_account_already_exists": "帳號已存在",
 "the_file_is_being_uploaded": "檔案正在上傳...",
 "the_next_number_of_days": "下一個天數",
 "the_past_number_of_days": "上一個天數",
 "the_text_will_be_shown_on_all_of_the_labels": "這段文字會在所有的標籤上顯示",
 "third_party_bill": "第三方帳單",
 "this_hawb_has_not_yet_linked_to_an_amat_id_please_click_get_edi_to_link_one": "此HAWB尚未與一個AMAT ID關聯",
 "this_is_a_comment": "這是註釋...",
 "timezone": "時區",
 "title": "標題",
 "tms_fast_dispatch_create_step_1": "建立送貨單(BOL)",
 "tms_fast_dispatch_create_step_2": "建立派車單(Dispatch)",
 "tms_mgmt": "卡車管理",
 "to": "收貨",
 "to_address": "收貨地址",
 "to_address_localized": "收貨地址(中文)",
 "to_be_completed": "待完成",
 "to_city": "收貨城市",
 "to_city_localized": "收貨城市(中文)",
 "to_country": "收貨國家/地區",
 "to_country_localized": "收貨國家/地區(中文)",
 "to_name": "收貨方",
 "to_state": "收貨州/省",
 "to_zipcode": "收貨郵遞區號",
 "total_amount_selected": "總選取金額:",
 "total_bols": "送貨單(BOL)數",
 "total_cartons": "箱子總數",
 "total_charge_weight": "總收費重",
 "total_cw": "收費總重",
 "total_deliveries": "交貨數",
 "total_deliverys": "Delivery數",
 "total_gross_weight": "總毛重",
 "total_gw": "總毛重",
 "total_hawbs": "HAWB數",
 "total_loose_cartons": "散裝箱總數",
 "total_packages": "包裹數",
 "total_pallets": "棧板總數",
 "total_pieces": "總件數",
 "total_records": "所有記錄",
 "total_shipments": "Shipment總數",
 "total_volume": "總體積",
 "total_weight": "總重量",
 "tracking": "追踪",
 "tracking_daily_report": "Tracking Daily Report",
 "tracking_device_number": "追踪器號碼",
 "tracking_no": "追踪號碼",
 "tracking_result": "追踪結果",
 "trade": "Trade",
 "trade_type": "Trade Type",
 "transfer_direction": "Transfer Direction",
 "truck_no": "車牌號碼",
 "truck_size_10_tons": "10 Tons",
 "truck_size_point_five_tons": "0.5 Tons",
 "truck_size_1_tons": "1 Tons",
 "truck_size_2_tons": "2 Tons",
 "truck_size_3_tons": "3 Tons",
 "truck_size_40_hq": "40 HQ",
 "truck_size_45_hq": "45 HQ",
 "truck_size_5_tons": "5 Tons",
 "truck_size_53_hq": "53 HQ",
 "truck_size_8_tons": "8 Tons",
 "truck_size_24_foot": "24 Foot",
 "truck_size_26_foot": "26 Foot",
 "truck_size_53_foot": "53 Foot",
 "truck_type": "車型",
 "truck_type_semi": "半卦式卡車",
 "truck_type_sprinter": "Sprinter廂式貨車",
 "truck_type_van": "普通廂式貨車",
 "unsaved_changes_will_be_lost_are_you_sure_you_want_to_leave": "未儲存的資料將遺失，您確定要離開嗎?",
 "un_number": "UN Number",
 "unit": "單位*",
 "unit_value": "單位價值",
 "unknown_message_code": "未知訊息代碼",
 "unloading_in_progress_date": "Unloading in Progress Date",
 "unreceived": "未收貨",
 "uom": "單位",
 "update": "更新",
 "update_hawb_pkg_data": "更新提單的包裹資料",
 "update_mawb": "更新主單",
 "update_pod": "更新 POD",
 "update_success": "更新成功",
 "updated_by": "更新者",
 "updated_date": "更新時間",
 "updated_date_utc": "更新時間 (UTC)",
 "updated_utc_time": "更新時間",
 "upload": "上傳",
 "upload_by": "上傳者",
 "upload_date_time": "上傳時間",
 "upload_file": "上傳檔案",
 "upload_file_section": "上傳檔案區塊",
 "upload_file_to_s3_failure": "上傳檔案至伺服器失敗",
 "upload_invoice": "上傳發票",
 "upload_license": "上傳駕照",
 "upload_license_failed": "上傳駕照失敗",
 "upload_photo": "\b上傳圖片",
 "uploadS3Failure": "無法將檔案上傳到雲端(S3)",
 "uppercase_privacy_policy": "隱私政策",
 "uppercase_temrs_and_condition": "條款和條件",
 "uppercase_terms_and_conditions": "條款和條件",
 "urgent": "急件",
 "urgent_delivery": "急件",
 "urgent_use": "未收到EDI",
 "user": "用户",
 "user_account_request_form": "使用者申請表",
 "user_information": "用戶資訊",
 "user_mgmt": "使用者管理",
 "user_not_found": "找不到使用者: %{email}",
 "user_registration_form": "使用者註冊表單",
 "user_registration_query": "使用者申請查詢",
 "user_type": "用戶類型",
 "value": "值",
 "van": "箱型車",
 "variance": "差異金額",
 "variance_approved_by": "差異金額批准者",
 "vendor": "供應商",
 "vendor_billing_portal": "供應商帳單管理平台",
 "vessel_name": "船名",
 "view_name": "報表模板名稱",
 "view_no_data": "報表模板無資料",
 "view_pod": "查看 POD",
 "view_type": "報表模板類型",
 "vip": "VIP",
 "vip_name": "客戶",
 "vol": "體積",
 "volume": "體積",
 "volume_uom": "體積單位",
 "volume_weight": "體積重量",
 "voyage": "航程",
 "vw": "體積重量",
 "wait_for_approval": "等待批准",
 "waiting_for_approval": "等待批准",
 "warehouse_truck": "倉儲",
 "warehouse_trucking": "倉儲",
 "warning": "警告",
 "warning_row": "Warning Row",
 "was_existed_in_the_other_dispatch": "已存在於其他派車單(Dispatch)",
 "was_not_planning_or_assigned_to_carrier": "狀態非“計劃中”或“已指派承運人”",
 "we_cannot_access_your_camera": "無法存取相機",
 "weight_unit": "重量單位",
 "weight_uom": "重量單位",
 "welcome_to_morrisonone_platform": "歡迎來到 Morrison One. 請在上方清單中選擇一個功能",
 "what_is_cookies": "\"Cookies\" are small files that enable us to store information related to your device and you, the user, specifically, while you visit one of our websites. Cookies help us to determine how frequently our internet pages are accessed as well as the number of users. And they help us configure our offers so that they are as convenient and efficient as possible for you. This includes cookies from third party websites if you visit a page which contains embedded content from such websites. Such third party cookies may track your use of the MEC website.",
 "width": "寬",
 "wms_fast_dispatch_create_step_1": "建立送貨單(DN)",
 "wms_fast_dispatch_create_step_2": "建立派車單(Dispatch)",
 "y": "Y",
 "yard": "碼",
 "yes": "是",
 "you_donot_have_query_permission_below": "您沒有以下權限。",
 "you_have_finished_editing_invoice_no_number": "您已經完成編輯發票號碼: %{number}",
 "you_have_finished_uploading_invoice_no_number": "您已經完成上傳發票號碼: %{number}",
 "you_have_unsaved_change_that_will_be_lost": "您修改的資料尚未儲存",
 "you_have_unsaved_changes_are_you_want_to_leave": "您有未儲存的修改，確定要離開嗎？",
 "your_linked_ref_no": "Your Linked Ref No",
 "your_presearch_donot_have_query_permission_below": "您查詢的資料不會顯示以下條件的內容 :",
 "your_ticket_number_number": "Your Ticket Number = %{number}",
 "yyyy": "YYYY",
 "zip_code": "郵遞區號",
 "zipcode": "郵遞區號",
 "error_code_update_delivery_get_country_info": "Country Name does not exist, please check.",
 "filters": "Filters",
 "export_exceeds_rows_limit": "匯出資料筆數超過上限，請設定更多搜尋條件",
 "export_no_matched_data": "匯出資料筆數為0，請再確認！",
 "unhandled_export_action": "未處理的匯出操作",
 "info": "資訊",
 "empty": "空的",
 "are_you_sure_you_want_to_delete_this_file": "您確定要刪除此文件嗎？",
 "forwarder_shipment_mgmt": "承運商貨件管理",
 "upload_file_number_more": "更多 (%{fileNumber})",
 "save_view_delete_with_subscribers": "無法刪除被訂閱的報表模板",
 "issue": "事件",
 "query_more_options": "更多選項",
 "are_you_sure_you_want_to_delete_the_address_book": "您確定要刪除此地址? ID: %{id}",
 "select_address_book": "選擇地址",
 "create_address_book": "創建地址",
 "edit_address_book": "編輯地址",
 "addressbook": "地址簿",
 "addressbook_name": "名字",
 "address_and_city": "地址 & 城市",
 "floor": "樓層/單位",
 "contact_name": "聯絡人",
 "phone_number": "電話",
 "quick_guide": "快速指引",
 "quick_guide_empty": "尚無快速指引",
 "action_by": "Action By",
 "booking_request": "Booking Request",
 "is_truck_info_correct": "Are you sure the truck information is correct?",
 "combine": "合併",
 "updated_at_utc": "更新時間(UTC)",
 "sheet_status_not_established": "NOT ESTABLISHED",
 "sheet_status_established": "ESTABLISHED",
 "sheet_status_departed": "DEPARTED",
 "sheet_status_arrived": "ARRIVED",
 "save_and_next": "儲存並繼續",
 "user_type_customer": "Customer",
 "user_type_morrison": "Morrison",
 "user_type_agent": "Agent",
 "user_type_vendor": "Vendor",
 "user_type_api": "Api",
 "user_type_demo": "Demo",
 "user_type_forwarder": "Forwarder",
 "total": "總和",
 "actual_truck_pickup_at": "預定揀貨",
 "estimated_delivery_at": "計劃送貨日期",
 "clear": "清除",
 "revert": "復原",
 "you_cannot_cancel_the_shipment_without_a_comment": "沒有留言，將無法取消貨物",
 "are_you_sure_you_want_to_cancel_the_shipment": "確認刪除貨物?",
 "please_cancel_this_shipment_on_prolink_cw1": "請在Prolink/CW1取消貨物",
 "refresh": "Refresh",
 "last_update": "Last Update: %{time}",
 "type": "類型",
 "token_expired": "登入逾期，請重新登入",
 "error_code_token_expired": "登入逾期，請重新登入",
 "release_date": "Release Date",
 "security_level_standard": "STANDARD",
 "security_level_medium": "MEDIUM",
 "security_level_high": "HIGH",
 "view": "自訂報表模板",
 "dropzone_placeholder_default": "點擊此處或拖曳檔案至此",
 "dropzone_placeholder_on_drag": "請拖曳檔案至此",
 "dropzone_error_quantity_limit": "檔案超過數量上限",
 "dropzone_error_upload_failed": "上傳失敗",
 "error_only_english_and_numbers_allowed": "僅能輸入英文以及數字",
 "english_and_numbers_input_placeholder": "請輸入英文或數字",
 "department_ae": "AE",
 "department_ai": "AI",
 "department_se": "SE",
 "department_si": "SI",
 "department_lg": "LG",
 "department_dt": "DT",
 "service_type_dtd": "DTD",
 "service_type_ata": "ATA",
 "service_type_dta": "DTA",
 "service_type_atd": "ATD",
 "service_type_ptp": "PTP",
 "service_type_dtp": "DTP",
 "service_type_ptd": "PTD",
 "service_type_atp": "ATP",
 "service_type_pta": "PTA",
 "service_type_whtd": "WHTD",
 "service_type_whsp": "WHSP",
 "incoterm_exw": "EXW",
 "incoterm_fca": "FCA",
 "incoterm_fas": "FAS",
 "incoterm_fob": "FOB",
 "incoterm_cpt": "CPT",
 "incoterm_cfr": "CFR",
 "incoterm_cif": "CIF",
 "incoterm_cip": "CIP",
 "incoterm_dap": "DAP",
 "incoterm_ddp": "DDP",
 "incoterm_dat": "DAT",
 "incoterm_dpu": "DPU",
 "incoterm_daf": "DAF",
 "incoterm_des": "DES",
 "incoterm_deq": "DEQ",
 "incoterm_ddu": "DDU",
 "tao_test": " ",
 "shipment_created_at_utc": "Shipment Create (UTC)",
 "validation_wrong_format": "格式錯誤",
 "validation_max_date": "Maximum date %{date}",
 "validation_min_date": "Minimum date %{date}",
 "copy": "複製",
 "paste": "貼上",
 "delete_comment_null_id": "This Comment does not exist",
 "too_many_files": "檔案數量太多(最多%{max}個)",
 "pagination_first": "第一頁",
 "pagination_last": "最末頁",
 "login": "登入",
 "export_exceed_rows_limit": "Over %{limit} Record, please contact with morrison one help",
 "sql_field_error": "您輸入的查詢條件有誤，請更正後重新查詢。",
 "forget_password_success": "若您有註冊帳號重置密碼郵件將發送至%{email}"
}

export default zhHantDictionary