export const zhHant = {
  application: {
    all: '全部',
    back: '返回',
    cancel: '取消',
    create: '新增',
    dashboard: '儀表板',
    data: '資料',
    default: '預設',
    function: '功能',
    general: '共用',
    hello: '您好, %{name}!',
    language: '繁體中文',
    short_lang: '繁中',
    list: '清單',
    logout: '登出',
    name: '名稱',
    no: '流水號',
    query: '查詢',
    reset: '重設',
    result: '結果',
    role: '角色',
    save: '儲存',
    search: '搜尋',
    system: '系統',
    type: '類型',
    user: '用户',
    something_went_wrong: '發生錯誤',
    please_select_at_least_one_record: '請至少選一筆資料',
    refresh_every_15_seconds: '每15秒重新整理',
    buttons: {
      add: 'Add',
      delete: 'Delete',
      export: 'Export',
      previous: 'Previous',
      next: 'Next',
      draft: 'Save as draft',
      confirm: 'Confirm',
      continue: 'Continue',
      close: 'Close',
      ok: 'OK',
      getEDI: 'Get EDI',
      reprocess: '重跑流程',
      resubmit: '重新提交',
      search_fail: '查詢失敗',
    },
  },
  edi_status: {
    customers: '客戶',
    customer: '客戶',
    edi_master_status: '狀態',
    create_date_start: '新增時間開始',
    create_date_end: '新增時間結束',
    system: '系統',
    key: '鍵值',
    value: '值',
    create_date: '新增時間',
    creator: '新增人員',
    transfer_direction: '傳遞方向',
    edi_category: 'EDI 分類',
    client_code: '客戶代碼',
    edi_type: 'EDI 類型',
    edi_method: 'EDI 方法',
    edi_status: 'EDI 狀態',
    sender: '發送者',
    edi_sender: 'EDI 發送者',
    receiver: '接收者',
    details: '明細',
    inbound_file_path: '檔案路徑',
  },
}
