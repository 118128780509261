import React from 'react'
const LoadDispatch = React.lazy(() => import('./App/ePOD/LoadDispatch'))
const PrivacyPolicy = React.lazy(() => import('./App/ePOD/PrivacyPolicy'))
const DispathStatus = React.lazy(() => import('./App/ePOD/DispatchStatus'))
const UploadPod = React.lazy(() => import('./App/ePOD/UploadPod'))
const DispatchException = React.lazy(() => import('./App/ePOD/DispatchException'))
const BOLException = React.lazy(() => import('./App/ePOD/BOLException'))

const routes = [
  { path: '/load-dispatch', exact: true, name: 'Load Dispatch', component: LoadDispatch, breadcrumbName: 'Load Dispatch', parentCrumb: 'Load Dispatch' },
  { path: '/dispatch-privacy-policy', exact: true, name: 'Privacy Policy', component: PrivacyPolicy, breadcrumbName: 'Privacy Policy', parentCrumb: 'Privacy Policy' },
  { path: '/dispatch-status', exact: true, name: 'Dispath Status', component: DispathStatus, breadcrumbName: 'Dispath Status', parentCrumb: 'Dispath Status' },
  { path: '/upload-pod/:bol_num', exact: true, name: 'Upload BOL', component: UploadPod, breadcrumbName: 'Upload POD', parentCrumb: 'Upload POD' },
  { path: '/dispatch-exception', exact: true, name: 'Dispatch Exception', component: DispatchException, breadcrumbName: 'Dispatch Exception', parentCrumb: 'Dispatch Exception' },
  { path: '/bol-exception/:bol_num', exact: true, name: 'BOL Exception', component: BOLException, breadcrumbName: 'BOL Exception', parentCrumb: 'BOL Exception' },
]

export default routes
